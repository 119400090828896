/**
 * Created by petter on 3/09/18.
 */
'use strict';

angular.module('app')
	.factory('TrackingConfigModel', function ($q, Model, TrackingConfig) {
		function TrackingConfigModel() {
			Model.call(this, TrackingConfig);
		}

	    TrackingConfigModel.prototype = Object.create(Model.prototype);
	    TrackingConfigModel.prototype.constructor = TrackingConfigModel;

	    TrackingConfigModel.prototype.load = function(vessel_id, params) {
	        if (vessel_id) {
		        this.vessel_id = vessel_id;
		        if (!params) {
			        params = {vessel_id: vessel_id};
		        }
		        else {
			        params.vessel_id = vessel_id;
		        }
	        }

			return Model.prototype.load.call(this, params);
		};

	    TrackingConfigModel.prototype.saveItem = function(data) {
			if (this.vessel_id) {
				data.TrackingConfig_Vessel = this.vessel_id;
			}
			else {
            	throw new Error('Vessel not set');
			}
			return Model.prototype.saveItem.call(this, data);
		};

	    TrackingConfigModel.prototype.onLoadedItem = function(item) {
			item.id = item.Id;
			console.log('Loaded ' + item.SourceEmail);
		};

	    TrackingConfigModel.prototype.isValidItem = function(item) {
			this.isItemDirty = true;
			item.isItemDirty = true;
			if (!item.SourceEmail){
				return false;
			}
			return true;
		};

		return TrackingConfigModel;
	});

angular.module('app')
	.factory('TrackingConfig', function(ItemBase, Configuration) {
		function TrackingConfig() {
			ItemBase.call(this);
		}

	    TrackingConfig.prototype = Object.create(ItemBase.prototype);

	    TrackingConfig.dataColumns = ['SourceEmail', 'TrackingConfig_Vessel'];
	    TrackingConfig.templateUrl = 'components/tracking/trackingconfig_edit.html';
	    TrackingConfig.url = Configuration.API + '/trackingconfigs/:id';

		return TrackingConfig;
	});

