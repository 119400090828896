angular.module('app')
	.controller('HomeController', function ($rootScope, $scope, authService, qm, $location, common, $routeParams, Configuration) {
		$rootScope.bodyClass = "home";
		$scope.qm = qm;
		console.log('HomeController');

		var today = new Date();
		$scope.year = today.getFullYear();

		if ($rootScope.isAuthenticated) {
			$location.path(Configuration.LoggedInPath);
		}
		else {
			//Not signed in, check for password reset token
			$scope.resetpasswordtoken = $routeParams.resetpasswordtoken;
			if ($scope.resetpasswordtoken) {
				$scope.$parent.resetpassword($scope.resetpasswordtoken);
			}
		}
            
		common.hideWorking();

	});