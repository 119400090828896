/**
 * Created by petter on 3/24/16.
 */
'use strict';
angular.module('app')
	.controller('ModalLoginController', function ($scope, $uibModal, $uibModalInstance, common, authService, mode, resetpasswordtoken) {
		// $scope.modalObjectSource = modalObject;
		// if (modalObject.constructor && (modalObject.constructor.name == 'ItemBase')) {
		//     $scope.modalObject = jQuery.extend(true, {}, modalObject);  //Create a deep copy, so we can cancel without saving, but also inherit class properties
		// }
		// else {
		//     $scope.modalObject = angular.extend({}, modalObject); //Create a shallow copy, so we can cancel without saving
		// }
		// $scope.model = model;

		$scope.error_message = null;
		$scope.email_error = null;
		$scope.password_error = null;
		$scope.showChangePassword = false;
		$scope.showResetPasswordFromToken = false;

		$scope.model = {
			email: null,
			password: null
		};

	    var username = localStorage.getItem('username');

	    if (username) {
		    $scope.model.email = username;
		    //TODO: If we set email, can we set autofocus on password?
	    }

	    $scope.activeTab = 0;
		if (mode === 'signup') {
			$scope.activeTab = 1;
		}
	    else if (mode === 'resetpassword') {
	        $scope.activeTab = 2;
	        $scope.showResetPasswordFromToken = true;
	        $scope.showChangePassword = true;
	        $scope.resetpasswordtoken = resetpasswordtoken;
	        if ($scope.resetpasswordtoken) {
	        	try {
			        var user = authService.getUserFromToken($scope.resetpasswordtoken);
			        $scope.model.email = user.email;
			        if (authService.isTokenExpired($scope.resetpasswordtoken)) {
				        $scope.error_message = "Password reset link is expired. Please try again.";
				        $scope.activeTab = 0;
				        $scope.showChangePassword = false;
				        $scope.showResetPasswordFromToken = false;
			        }
		        }
		        catch (err) {
			        $scope.error_message = "Password reset request is expired or corrupt. Please try again.";
			        $scope.activeTab = 0;
			        $scope.showChangePassword = false;
			        $scope.showResetPasswordFromToken = false;
		        }
	        }
	    }

		$scope.isValidEmail = function () {
			// $scope.model.isItemDirty = true;
			if ($scope.model.email && $scope.model.email.length > 4) {
				$scope.email_error = null;
				return true;
			}
			$scope.email_error = "Must be a valid e-mail";
			return false;
		};

		$scope.isValidPassword = function () {
			// $scope.model.isItemDirty = true;
			if ($scope.model.password && $scope.model.password.length > 2) {
				$scope.password_error = null;
				return true;
			}
			$scope.password_error = "Must be a valid password";
			return false;
		};

		$scope.isValidNewPassword = function () {
			// $scope.model.isItemDirty = true;
			if ($scope.model.newpassword && $scope.model.newpassword.length > 2) {
				$scope.password_error = null;
				return true;
			}
			$scope.password_error = "Must be a valid new password";
			return false;
		};

		$scope.isValidNewPasswordConfirm = function () {
			// $scope.model.isItemDirty = true;
			if ($scope.model.newpassword === $scope.model.newpasswordconfirm) {
				$scope.password_error = null;
				return true;
			}
			$scope.password_error = "New password must be confirmed";
			return false;
		};

		$scope.checkResultIsSuccess = function (result) {
			if (result == null) {
				$scope.error_message = null;
				return true;
			}
			else if ((result.code === 'request_error') || (result.error === 'request_error')) {
				$scope.error_message = 'Unable to contact authentication server.  Please make sure your network connection is working.';
				return false;
			}
			else if (result.error_description) {
				$scope.error_message = result.error_description;
				return false;
			}
			else if (result.description) {
				$scope.error_message = result.description;
				return false;
			}
			else if (result.status === 500) {
				$scope.error_message = result.data;
				return false;
			}
			//Same as network error
			// else if (result.original && result.original.message) {
			//     $scope.error_message = result.original.message;
			//     return false;
			// }
			else {
				$scope.error_message = 'Error.  Please make sure your network connection is working.';
				return false;
			}
		};

		$scope.setChangePassword = function (value) {
			$scope.showChangePassword = value;

		};

		$scope.changePassword = function () {
			$scope.password_error = null;
			$scope.error_message = null;
			if ($scope.isValidEmail()) {
				common.showWorking();
				authService.changePassword($scope.model.email, function (result) {
					common.hideWorking();
					$scope.checkResultIsSuccess(result);
				});
			}
		};

		$scope.resetPassword = function () {
			$scope.password_error = null;
			$scope.error_message = null;
			if ($scope.isValidEmail()) {
				common.showWorking();
				authService.resetPassword($scope.model.email, function (result) {
					common.hideWorking();
					if ($scope.checkResultIsSuccess(result)) {
						$scope.showPasswordResetModal();
					}
				});
			}
		};

		$scope.ok = function () {
			//TODO: Validate e-mail and password
			$scope.error_message = null;

			if ($scope.isValidEmail()) {

	            if ($scope.activeTab === 0) {
					if ($scope.showChangePassword) {
						//Change password, then log in
						if (($scope.isValidNewPassword() & $scope.isValidNewPasswordConfirm()) == true) {
							common.showWorking();
							authService.changePassword($scope.model.email, $scope.model.password, $scope.model.newpassword, function (result) {
								common.hideWorking();
								if ($scope.checkResultIsSuccess(result)) {
									//Success, close modal
									$uibModalInstance.close('success');
								}
							});
						}
					}
					else {
						//Log In
	                    if ($scope.isValidPassword()) {
		                    common.showWorking();
		                    authService.login($scope.model.email, $scope.model.password, function (result) {
			                    common.hideWorking();
			                    if ($scope.checkResultIsSuccess(result)) {
				                    //Success, close modal
				                    $uibModalInstance.close('success');
			                    }
		                    });
	                    }
					}
				}
				else if ($scope.activeTab === 1) {
					//Sign Up
	                if ($scope.isValidPassword()) {
		                authService.signup($scope.model.email, $scope.model.password, function (result) {
			                common.hideWorking();
			                if ($scope.checkResultIsSuccess(result)) {
				                //Success, close modal
				                $uibModalInstance.close('success');
			                }
		                });
	                }
				}
				else if ($scope.activeTab === 2) {
	                //Reset password
	                if (($scope.isValidNewPassword() & $scope.isValidNewPasswordConfirm()) == true) {
		                authService.resetPasswordConfirmNew($scope.resetpasswordtoken, $scope.model.email, $scope.model.newpassword, $scope.model.newpasswordconfirm, function (result) {
			                common.hideWorking();
			                if ($scope.checkResultIsSuccess(result)) {
				                //Success, close modal
				                $uibModalInstance.close('success');
			                }
		                });
	                }
				}
			}

			// if (!$scope.model.isValidItem($scope.modalObject)){
			//     return;
			// }




			//Shallow copy back changes, before returning
			// angular.extend($scope.modalObjectSource, $scope.modalObject);
		};

		// $scope.cancel = function () {
		//     $uibModalInstance.dismiss('cancel');
		// };

		$scope.showPasswordResetModal = function () {
			// $uibModalInstance.close('success');
			if (!$scope.showingPasswordResetModal) {
				$scope.showingPasswordResetModal = true;
				var stubModel = {};
				stubModel.isValidItem = function () {
					return true;
				};
				var modalInstance = $uibModal.open({
					templateUrl: 'components/login/modal_passwordreset.html',
					controller: 'ModalController',
					// windowClass: 'vessel-photo-modal',
					size: 'crewphoto',
					resolve: {
						title: function () {
							return 'Your password has been reset';
						},
						modalObject: function () {
							return {};
						},
						model: function () {
							return stubModel;
						},
						deleteMethod: function () {
							return null;
						}
					}
				});

				modalInstance.result.then(function (result) {
					$scope.showingPasswordResetModal = false;
				}, function () {
					console.log('Modal dismissed at: ' + new Date());
					$scope.showingPasswordResetModal = false;
				});
			}
		};


	});