angular.module('app')
	.directive('infoNumber', function() {
		return {
			scope: {
				name: '=name',
				data: '=data',
				extra: '=extra',
				abbr: '=abbr'
			},
			template: '<div class="text-truncate"><i><abbr ng-if="(abbr != null)" title="{{abbr}}">{{name}}</abbr><span ng-if="(abbr == null)">{{name}}</span></i><span ng-if="name">: </span><b><span class="text-right">{{data}}</span></b><span ng-if="((data != null) && (extra != null))"> ({{extra}})</span></div>'
		};
	})
	.directive('infoText', function() {
		return {
			scope: {
				name: '=name',
				data: '=data',
				extra: '=extra'
			},
			template: '<div class="text-truncate"><i>{{name}}</i>: <b><span class="text-capitalize">{{data}}</span></b><span ng-if="(extra != null)"> ({{extra}})</span></div>'
		};
	})
	.directive('infoDate', function() {
		return {
			scope: {
				name: '=name',
				data: '=data'
			},
			template: '<div class="text-truncate"><i>{{name}}</i>: <b>{{data | date : \'mediumDate\'}}</b></div>'
		};
	})
	.directive('infoTime', function() {
		return {
			scope: {
				name: '=name',
				data: '=data'
			},
			template: '<div class="text-truncate"><i>{{name}}</i>: <b>{{data | date : \'short\'}}</b></div>'
		};
	})
	.directive('infoPage', function() {
		return {
			scope: {
				name: '=name',
				data: '=data'
				// pageurl: '=pageurl'
			},
			template: '<div class="text-truncate"><i>{{name}}</i>: <b><a ng-href="pageurl" href="{{pageurl}}" class="text-capitalize">{{data}}</a></b></div>'
		};
	})
	.directive('infoUrl', function() {
		return {
			scope: {
				name: '=name',
				data: '=data'
			},
			template: '<div class="text-truncate"><i>Web: </i><b><a title="Web" href="{{data.startsWith(\'http\') ? data : (\'http://\' + data)}}" target="_blank" rel="external">{{data.toLowerCase()}}</a></b></div>'
		};
	}).directive('gridHeading', function() {
		return {
			scope: {
				title: '=title',
				type: '=type',
				count: '=count',
				grid: '=grid',
	            onAdd: '=onAdd'
			},
			template:
                '<span class="heading-list"> {{title}} (<span ng-if="count === 0">no matches</span><span ng-if="count > 0">{{count | number : 0}} record</span><span ng-if="count > 1">s</span>) <a ng-if="grid" id="button-add" title="Add {{type}}" class="text-orange pull" href="" ng-click="clickAdd()"><i class="fa fa-plus-circle"></i></a></span>',
			controller: ['$scope', 'qm', function($scope, qm) {

            	if (!$scope.onAdd) {
		            $scope.clickAdd = function () {
			            console.log('Click Add');
			            qm.addItem($scope.grid.model, 'New ' + $scope.type, $scope.grid.load);
		            };
	            }
            	else {
            		$scope.clickAdd = $scope.onAdd;
	            }
			}]
		};
	});
