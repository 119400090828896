angular.module('app')
	.controller('VesselSchedulesController', function ($scope, VesselModel, SystemModel, SystemSchedule, SystemScheduleModel, $rootScope, $location , Exception, $q, uiGridConstants, qm, $routeParams, Constants, $controller) {
		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));
	            $rootScope.bodyClass = "schedule";
		$scope.qm = qm;
		$scope.model = new SystemScheduleModel();
		$scope.systemModel = new SystemModel();
		$scope.vesselModel = new VesselModel();
		$scope.grid = {};
		$scope.grid.appScope = $scope;
		$scope.vessel_id = $routeParams.id;
		$scope.show_hours_column = true;

		$scope.getGridColumns = function() {
			return [
				$scope.qm.getDefaultIdColumn(),
				{ name:'Interval (Days)', field: 'IntervalDays', width: '120', sort: { priority: 0, direction: 'asc' }, filter: { placeholder: 'search days...'} },
				{ name:'Hours', field: 'Hours', visible: $scope.show_hours_column, width: '120', filter: { placeholder: 'search hours...'} },
				{ name:'Description', field: 'Description', enableHiding:false, filter: { placeholder: 'search description...'} },
				{ name:'System', field: 'SystemDescription', enableHiding:false, filter: { placeholder: 'search system...'} },
	                { name:'Next Date', field: 'ScheduleDate', type: 'date', width: '100', visible: !$scope.qm.phone, cellFilter: qm.dateCellFilter, enableHiding:false, filter: { placeholder: 'search date...'} },
	                { name:'Next Hours', field: 'NextScheduleHours', visible: $scope.show_hours_column, width: '120', filter: { placeholder: 'search hours...'} },
	                { name:'Assign Task To', field: 'assignedResource.NameOrEmail', enableHiding:false, visible: !$scope.qm.phoneplus, filter: {
			                placeholder: 'select type...',
			                condition: uiGridConstants.filter.EXACT,
			                type: uiGridConstants.filter.SELECT,
			                selectOptions: $scope.model.crew
		                } },
				{ name:'Notes', field: 'Notes', visible: !$scope.qm.phoneplus, enableHiding:false, filter: { placeholder: 'search notes...'} },
				$scope.qm.getSystemModified(),$scope.qm.getSystemModifiedBy(),
				{ name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '70',
					cellTemplate: 'components/vesselschedules/vesselschedules_action_template.html'
				}
			];
		};

		$scope.setGridOptions = function() {
			$scope.gridOptions = {
	                enableGridMenu: true,
	                gridMenuShowHideColumns: true,
	                exporterMenuCsv: true,
	                exporterMenuAllData: false,
	                exporterMenuVisibleData: true,
	                exporterCsvFilename: 'Quartermaster-SystemSchedules.csv',
	                exporterMenuExcel: false,
	                exporterMenuPdf: false,
				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: true,
				enableColumnResizing: true,
	                enableColumnReordering: true,
	                enableColumnMoving: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				columnDefs: $scope.getGridColumns(),
				data: 'model.items',
				tabs: [],
	                exporterSuppressColumns: ['Id', 'Actions', 'Photo'],
	                importerNewObject: SystemSchedule,
	                importerShowMenu: false,
	                importerDataAddCallback: function (grid, newObjects) {
		                for (var newObjectCounter=0;newObjectCounter < newObjects.length; newObjectCounter++) {
			                var newObject = newObjects[newObjectCounter];
			                //Strip blank string, i.e. ""
			                for (var property in newObject) {
				                if ((typeof newObject[property] === 'string') && (newObject[property] === "")) {
					                // Skip blank strings (it's not a number and will cause ,, in SQL statements
					                delete newObject[property];
				                }
			                }
			                $scope.model.saveItem(newObject).then(function (newItem) {
				                $scope.model.items.unshift(newItem);
			                }, function(err) {
				                console.log(err);
			                });
		                }
		                // $scope.model.items = $scope.model.items.concat(newObjects);
	                },
	                onRegisterApi: function( gridApi ) {
					$scope.gridApi = gridApi;
				}
			};
		};
		$scope.setGridOptions();

		$scope.$on('screen-changed', function(event, args) {
			$scope.gridOptions.columnDefs = $scope.getGridColumns();
			$scope.gridApi.core.refresh();
		});

		$scope.load = function() {
	            var deferred = $q.defer();
			$scope.gridOptions.loading = true;
			$scope.vesselModel.getItem($scope.vessel_id).then(function(result) {
				$scope.vessel = result;
				// $scope.show_hours_column = $scope.system.TrackHours;
				// $scope.gridOptions.columnDefs = $scope.getGridColumns();
	                $scope.model.loadAll($scope.vessel_id).then(function(result) {
		                // var tagKeys = [];
		                // $scope.gridOptions.tabs = [{name:'All'}];
		                // for (var j = 0; j < result.length; j++) {
			            //     var offer = result[j];
			            //     if (offer.status) {
				        //         if (tagKeys.indexOf(offer.status) < 0) {
					    //             tagKeys.push(offer.status);
					    //             $scope.gridOptions.tabs.push({name:offer.status, field:'status', filter:offer.status});
				        //         }
			            //     }
		                // }
		                $scope.gridOptions.loading = false;
		                deferred.resolve(result);
	                }, function (error) {
		                Exception.errorFn(error);
	                });
			}, function (error) {
				Exception.errorFn(error);
			});

	            return deferred.promise;
		};
		$scope.load();

		$scope.getFilesUrl = function(row) {
			return '/systems/'+row.entity.Id+'/schedules/files/'+Constants.FileComponent_SystemSchedule;
		};
	});
