angular.module('app')
	.controller('DashboardsController', function ($scope, $controller, VesselModel, DashboardsModel, $rootScope, $location , Exception, $q, uiGridConstants, qm, profilePhotoModel, $uibModal, Constants, $routeParams) {

		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));
		angular.extend(this, $controller('GridBaseController', {$scope: $scope}));

		$rootScope.bodyClass = "dashboards";
		$scope.qm = qm;
		$scope.model = new DashboardsModel();
		$scope.vesselModel = new VesselModel();
		$scope.profilePhotoModel = profilePhotoModel;
		$scope.vessel_id = $routeParams.id;

		$scope.grid = $scope.createGrid($scope.model, 'dashboards', 'Dashboards', null, null, 82);

		$scope.load = function() {
	            var deferred = $q.defer();
	            $scope.vesselModel.getItem($scope.vessel_id).then(function(result) {
				$scope.vessel = result;
				$scope.model.load($scope.vessel).then(function(result) {
					console.log('Loaded dashboards items:' + result.length);
	                    deferred.resolve(result);
				}, function (error) {
	                    Exception.handleError(error, deferred);
				});
			}, function (error) {
	                Exception.handleError(error, deferred);
			});
			return deferred.promise;
		};
		$scope.load();

		// $scope.getCrewUrl = function(row) {
		//     return '#/vessels/'+row.entity.Id+'/crew';
		// };
		//
		// $scope.getSubscriptionUrl = function(row) {
		//     return '#/vessels/'+row.entity.Id+'/subscription';
		// };

		$scope.showDashboardTemplateModal = function(dashboard) {
			var title = dashboard.Name + '\'s template file';
			$scope.showTemplateModal(dashboard, title, Constants.FileComponent_DashboardTemplate, dashboard.id, {
				width: 1024,
				height:512,
				nothumbnail: true
			});
		};

		$scope.showTemplateModal = function (entity, title, component_id, entity_id, crop_settings) {

			var modalInstance = $uibModal.open({
				templateUrl: 'core/photo-cropper/modal_dashboards.html',
				controller: 'PhotoCropperModalCtrl',
				windowClass: 'dashboard-template-modal',
				size: 'template',
				resolve: {
					title: function () {
						return title;
					},
					vessel_id: function () {
						return $scope.vessel_id;
					},
					component_id: function () {
						return component_id;
					},
					entity: function () {
						return entity;
					},
					entity_id: function () {
						return entity_id;
					},
					model: function () {
						return profilePhotoModel;
					},
					crop_settings: function () {
						return crop_settings;
					}
				}
			});

			modalInstance.result.then(function (entity) {
	                var oldFileId = entity.Dashboards_TemplateFile;
				entity.Dashboards_TemplateFile = entity.FileId;
				$scope.model.saveItem(entity).then(function (result) {
	                    if (oldFileId) {
		                    profilePhotoModel.markFileDeleted(oldFileId);
	                    }
					$scope.load();
				});
			}, function () {
				console.log('Modal dismissed at: ' + new Date());
			});
		};

		$scope.listenForChanges('Dashboards');

	});
