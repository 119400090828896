angular.module('app')
	.controller('TrackingController', function ($scope, TrackingConfigModel, $rootScope, $location , Exception, $q, uiGridConstants, qm, $routeParams, Constants, $window, $controller) {
		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));

		$rootScope.bodyClass = "expense";
		$scope.qm = qm;
		$scope.model = new TrackingConfigModel();
		$scope.grid = {};
		$scope.grid.appScope = $scope;

		$scope.getGridColumns = function() {
			return [
				$scope.qm.getDefaultIdColumn(),
				{ name:'Satellite Email', field: 'SourceEmail', enableHiding:false, filter: { placeholder: 'search email...'} },
				$scope.qm.getSystemModified(),$scope.qm.getSystemModifiedBy(),
				{ name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '100',
					cellTemplate: 'components/tracking/trackingconfig_action_template.html'
				}
			];
		};

		$scope.setGridOptions = function() {
			$scope.gridOptions = {
	                enableGridMenu: false,
	                gridMenuShowHideColumns: false,
	                exporterSuppressColumns: ['Id', 'Actions'],
	                exporterMenuCsv: true,
	                exporterCsvFilename: 'Quartermaster-Services-Log.csv',
	                exporterMenuExcel: false,
	                exporterMenuPdf: false,

				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: true,
				enableColumnResizing: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				// paginationPageSizes: [3, 5, 10, 25, 50, 75, 100],
				// paginationPageSize: 5,
				columnDefs: $scope.getGridColumns(),
				data: 'model.items',
				tabs: [],
	                importerShowMenu: false,
	                importerDataAddCallback: function (grid, newObjects) {},
	                onRegisterApi: function( gridApi ) {
					$scope.gridApi = gridApi;
				}
			};            };
		$scope.setGridOptions();

		$scope.$on('screen-changed', function(event, args) {
			$scope.gridOptions.columnDefs = $scope.getGridColumns();
			$scope.gridApi.core.refresh();
		});

		$scope.load = function() {
	            var deferred = $q.defer();
	            if (qm.selectedVessel.Id) {
		            $scope.model.load(qm.selectedVessel.Id).then(function (result) {
			            console.log('Loaded tracking config items:' + $scope.model.items.length);
		            }, function (error) {
			            Exception.errorFn(error);
			            deferred.resolve(result);
		            }, function (error) {
			            Exception.errorFn(error);
			            deferred.resolve(null);
		            });
	            }
	            else {
		            deferred.resolve(null);
	            }
	            return deferred.promise;
		};

		$scope.$watch('qm.selectedVessel', function () {
			if (qm.selectedVessel) {
				$scope.load();
			}
		});

	        $scope.getEmbeddedMapUrl = function() {
	        	if (qm.selectedVessel && qm.selectedVessel.MapEmbeddedViewKey) {
			        return $window.location.origin + "/map/?key=" + qm.selectedVessel.MapEmbeddedViewKey;
		        }
		        else {
	        		return null;
		        }
	        };

	        $scope.getGPXDownloadUrl = function() {
		        if (qm.selectedVessel && qm.selectedVessel.MapEmbeddedViewKey) {
			        return $window.location.origin + "/public/downloadgpxfile/" + qm.selectedVessel.MapEmbeddedViewKey;
		        }
		        else {
			        return null;
		        }
	        };

	        $scope.getClipboardText = function() {
		        return "<iframe src='"+$scope.getEmbeddedMapUrl()+"' width='100%' height='400px' frameborder='0' scrolling='no'></iframe>";
	        };

	});
