angular.module('app')
	.controller('StorageController', function ($scope, $controller, StorageModel, $rootScope, $location , Exception, $q, uiGridConstants, qm, Constants) {
		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));
		angular.extend(this, $controller('GridBaseController', {$scope: $scope}));

		$rootScope.bodyClass = "storage";
		$scope.qm = qm;
		$scope.model = new StorageModel();

		$scope.grid = $scope.createGrid($scope.model, 'storage', 'Storage', null, null, null);

		$scope.load = function() {
	            var deferred = $q.defer();
	            if (qm.selectedVessel.Id) {
				$scope.model.load(qm.selectedVessel.Id).then(function(result) {
					console.log('Loaded storage items:' + $scope.model.items.length);
	                    deferred.resolve(result);
				}, function (error) {
					Exception.handleError(error, deferred);
				});
			}
			else {
	                deferred.resolve(null);
			}
	            return deferred.promise;
		};

		$scope.$watch('qm.selectedVessel', function () {
			if (qm.selectedVessel) {
	                $scope.load();
			}
		});

		$scope.getStorageItemUrl = function(row) {
			return '/storage/'+row.entity.Id+'/items';
		};

		$scope.getFilesUrl = function(row) {
			return '/storage/'+row.entity.Id+'/files/'+Constants.FileComponent_Storage;
		};

		$scope.listenForChanges('Systems');

	});
