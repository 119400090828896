/**
 * Created by petter on 2/18/16.
 */
'use strict';

angular.module('app')
	.factory('TaskModel', function ($q, Model, Task, CrewModel, common, uiGridConstants) {
		function TaskModel() {
			Model.call(this, Task);
			this.types = [
				{
					name:'Engine',
					id:1
				},
				{
					name:'Watermaker',
					id:2
				},
				{
					name:'Tank',
					id:3
				}
			];
			this.crewModel = new CrewModel();
			this.crew = this.crewModel.items;

			//Kanban
			this.state1 = { name: 'To Do', state:1 };
			this.state2 = { name: 'In progress', state: 2, areNewItemButtonsHidden: true };
			this.state3 = { name: 'Done', state: 3, isCollapsedByDefaultForGroups: true, areNewItemButtonsHidden: true };
			this.states = [this.state1, this.state2, this.state3];

			this.groupUnassigned = { name: 'Unassigned', state: null, isItemReadOnly: true, assignedResource: null };
			// this.group2 = { name: 'Story 2', state: null, assignedResource: null };
			this.groups = [this.groupUnassigned];

		}

		TaskModel.prototype = Object.create(Model.prototype);
		TaskModel.prototype.constructor = TaskModel;

		TaskModel.prototype.load = function(vessel_id, params) {
			var deferred = $q.defer();
			var _this = this;

			if (vessel_id) {
				this.vessel_id = vessel_id;
				if (!params) {
					params = {vessel_id: vessel_id};
				}
				else {
					params.vessel_id = vessel_id;
				}
			}

			this.crewModel.load({vessel_id:this.vessel_id}).then(function(result) {
				// console.log('Loaded crew items:' + _this.crewModel.items.length);
				_this.groups.length = 1;

				for (var i=0; i<_this.crewModel.items.length;i++) {
					var crew = _this.crewModel.items[i];
					var group = { name: crew.Name, state: null, isItemReadOnly: true, assignedResource: crew, crew_id: crew.Id };
					_this.groups.push(group);
				}
				Model.prototype.load.call(_this, params).then(function(result) {
					// console.log('Loaded crew items:' + result.length);
					deferred.resolve(result);
				}, function (error) {
					// Exception.errorFn(error);
					deferred.reject(error);
				});
			}, function (error) {
				// Exception.errorFn(error);
				deferred.reject(error);
			});

			// return Model.prototype.load.call(this);
			return deferred.promise;
		};

	    TaskModel.prototype.loadReport = function(vessel_id, from_date, to_date) {
		    return this.load(vessel_id,{
			    vessel_id:vessel_id,
			    from_date:from_date,
			    to_date:to_date
		    });
	    };

	    TaskModel.prototype.saveItem = function(data) {
			data.Task_Vessel = this.vessel_id;
			return Model.prototype.saveItem.call(this, data);
		};

		TaskModel.prototype.onItemChanged = function(item, property) {
			if ((property == 'name') || (property == 'assignedResource') || (property == 'DueDate')) {
				if (this.taskUpdated) {
					this.taskUpdated(item);
				}
			}
		};

		TaskModel.prototype.onLoadedItem = function(item) {
			item.id = item.Id;
			// console.log('Loaded ' + item.Description);
			//item.profileUrl = item.getProfileUrl();
			//Kanban
			// item.name = item.Description;
			item.group = this.groupUnassigned;
			if (item.State === 1)
				item.state = this.state1;
			else if (item.State === 2)
				item.state = this.state2;
			else if (item.State === 3)
				item.state = this.state3;
			else
				item.state = 0;

			item.assignedResource = null;
			// item.isReadOnly = false;

			//Assign crew
			for (var i=0; i<this.crewModel.items.length;i++) {
				var crew = this.crewModel.items[i];
				if (item.Task_VesselUserRoles === crew.Id) {
					item.assignedResource = crew;
					for (var j=1; j<this.groups.length;j++) {
						var group = this.groups[j];
						if (group.crew_id === crew.Id) {
							item.group = group;
						}
					}
				}
			}

			this.isItemDirty = false;
		};

		// TaskModel.prototype.loadCrew = function() {
		//     var deferred = $q.defer();
		//     if (!this.vessel_id) {
		//         deferred.reject(new Error('Missing vessel id'));
		//     }
		//     // var _this = this;
		//     this.crewModel.load({vessel_id:this.vessel_id}).then(function(result) {
		//         console.log('Loaded crew items:' + result.length);
		//         deferred.resolve(result);
		//     }, function (error) {
		//         // Exception.errorFn(error);
		//         deferred.reject(error);
		//     });
		//     return deferred.promise;
		// };

		TaskModel.prototype.isValidItem = function(item) {
			this.isItemDirty = true;
			item.isItemDirty = true;
			if (!item.Description){
				return false;
			}
			// if (item.Url && ((!item.Url.startsWith('http://')) || (!item.Url.startsWith('https://'))) ){
			//     return false;
			// }
			return true;
		};

	    TaskModel.prototype.getGridColumns = function() {
		    return [
			    common.getDefaultIdColumn(),
			    { name:'Description', field: 'Description', enableHiding:false, sort: { priority: 1, direction: 'asc' }, filter: { placeholder: 'search description...'},
				    cellTemplate: 'components/tasks/tasks_description_template.html'},
			    { name:'State', field: 'state.name', width: '140', enableHiding:false, filter: {
					    placeholder: 'select type...',
					    condition: uiGridConstants.filter.EXACT,
					    type: uiGridConstants.filter.SELECT,
					    selectOptions: [
						    {value:'To Do', label:'To Do'},
						    {value:'In progress', label:'In progress'},
						    {value:'Done', label:'Done'}
					    ]
				    } },
			    { name:'Due Date', field: 'DueDate', type: 'date', width: '140', visible: !common.phoneplus, cellFilter: common.dateCellFilter, enableHiding:false, sort: { priority: 0, direction: 'asc' }, filter: { placeholder: 'search due date...'},
				    cellTemplate: 'components/tasks/tasks_expires_template.html'
			    },
			    { name:'Assigned To', field: 'assignedResource.name', enableHiding:false, visible: !common.phoneplus, filter: {
					    placeholder: 'select type...',
					    condition: uiGridConstants.filter.EXACT,
					    type: uiGridConstants.filter.SELECT,
					    selectOptions: this.crew
				    } },
			    { name:'System', field: 'Systems_Description', enableHiding:true, visible:false, filter: { placeholder: 'search system...'},
				    cellTemplate: 'components/tasks/tasks_system_template.html'},
			    common.getSystemModified(),common.getSystemModifiedBy(),
			    { name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '80',
				    cellTemplate: 'components/tasks/tasks_action_template.html'
			    }
		    ];
	    };

		return TaskModel;
	});

angular.module('app')
	.factory('Task', function(ItemBase, Configuration) {
		function Task() {
			ItemBase.call(this);
			this.indexUrl = 'https://quartermaster.me/index.html';
		}

		Task.prototype = Object.create(ItemBase.prototype);

		Task.dataColumns = ['Description', 'Notes', 'State', 'Url', 'DueDate', 'Task_TaskType', 'Task_Vessel','TaskMaker_Task', 'Task_VesselUserRoles', 'Task_SystemSchedules'];
		Task.templateUrl = 'components/tasks/tasks_edit.html';
		Task.url = Configuration.API + '/tasks/:id';

		Object.defineProperty(Task.prototype, 'Type', {
			get: function () {
				return this.Task_TaskType;
			},
			set: function (value) {
				// this.isItemDirty = true;
				return this.Task_TaskType = value;
			}
		});

		Object.defineProperty(Task.prototype, 'Vessel', {
			get: function () {
				return this.Task_Vessel;
			},
			set: function (value) {
				// this.isItemDirty = true;
				return this.Task_Vessel = value;
			}
		});

		Object.defineProperty(Task.prototype, 'TaskMaker', {
			get: function () {
				return this.TaskMaker_Task;
			},
			set: function (value) {
				// this.isItemDirty = true;
				return this.TaskMaker_Task = value;
			}
		});

		Object.defineProperty(Task.prototype, 'name', {
			get: function () {
				return this.Description;
			},
			set: function (value) {
				// console.log('name set = ' + value);
				this.Description = value;
				this.notifyItemChanged('name');
				return this.Description = value;
			}
		});

		Object.defineProperty(Task.prototype, 'assignedResource', {
			get: function () {
				return this._assignedResource;
			},
			set: function (value) {
				// console.log('name set = ' + value);
				this._assignedResource = value;
				if (value) {
					this.Task_VesselUserRoles = value.Id;
				}
				this.notifyItemChanged('assignedResource');
				return this._assignedResource = value;
			}
		});

		Object.defineProperty(Task.prototype, 'DueDate', {
			get: function () {
				return this._DueDate;
			},
			set: function (value) {
				// console.log('name set = ' + value);
				this._DueDate = value;
				this.notifyItemChanged('DueDate');
				return this._DueDate = value;
			}
		});

		Object.defineProperty(Task.prototype, 'urlHref', {
			get: function () {
				if (this.Url.startsWith(this.indexUrl)) {
					return this.Url.substring(this.indexUrl.length);
				}
				return this.Url;
			}
		});

		Object.defineProperty(Task.prototype, 'urlTarget', {
			get: function () {
				if (this.Url.startsWith(this.indexUrl)) {
					return '_self';
				}
				return '_blank';
			}
		});

		//Kanban board color
		Object.defineProperty(Task.prototype, 'color', {
			get: function () {
				if (this.State == 1)
					return 'red';
				if (this.State == 2)
					return 'orange';
				if (this.State == 3)
					return 'green';
				return 'red';
			}
		});

	    Task.prototype.isExpired = function() {
		    if (!this.DueDate) {
			    return false;
		    }
		    var today = new Date();
		    if (this.DueDate < today) {
			    return true;
		    }
		    else {
			    return false;
		    }
	    };

		return Task;
	});

