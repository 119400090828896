angular.module('app')
	.controller('VesselsController', function ($scope, $controller, VesselModel, VesselCopyModel, VesselCopy, $rootScope, $location , Exception, $q, uiGridConstants, qm, profilePhotoModel, $uibModal, Constants, $interval, common) {

		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));
		angular.extend(this, $controller('GridBaseController', {$scope: $scope}));

		$rootScope.bodyClass = "vessels";
		$scope.qm = qm;
		$scope.userProfile = $rootScope.authService.userProfile;
		$scope.model = new VesselModel();
		$scope.copyVesselModel = new VesselCopyModel();
		$scope.profilePhotoModel = profilePhotoModel;

		$scope.grid = $scope.createGrid($scope.model, 'yachts', 'Yachts', null, null, 82);

		$scope.load = function() {
			var deferred = $q.defer();
			$scope.model.load().then(function(result) {
				console.log('Loaded vessel items:' + result.length);
				deferred.resolve(result);
			}, function (error) {
				Exception.errorFn(error);
				deferred.resolve(null);
			});
			return deferred.promise;
		};

		$scope.load();

		$scope.reloadVessels = function() {
			$scope.load();
			return $scope.qm.reloadVessels();
		};

		$scope.deleteItem = function(model, list, item) {
			var promise = $scope.qm.deleteItem(model, list, item);
			if (promise) {
				promise.then(function() {
					$scope.qm.reloadVessels();
				});
			}
		};

		$scope.getDashboardUrl = function(row) {
			return '/vessels/'+row.entity.Id+'/dashboards';
		};

		$scope.getCrewUrl = function(row) {
			return '/vessels/'+row.entity.Id+'/crew';
		};

		$scope.getReportUrl = function(row) {
			return '/vessels/'+row.entity.Id+'/report';
		};

		$scope.getServiceSchedulesUrl = function(row) {
			return '/vessels/'+row.entity.Id+'/schedules';
		};

		// $scope.getSubscriptionUrl = function(row) {
		//     return '#/vessels/'+row.entity.Id+'/subscription';
		// };

		$scope.showVesselProfileModal = function(vessel) {
			var title = vessel.Name + '\'s profile photo';
			$scope.showProfileModal(vessel, title, Constants.FileComponent_VesselProfile, vessel.id, {
				width: 256,
				height:144
			});
		};

		$scope.showProfileModal = function (entity, title, component_id, entity_id, crop_settings) {

			var modalInstance = $uibModal.open({
				templateUrl: 'core/photo-cropper/modal_vessels.html',
				controller: 'PhotoCropperModalCtrl',
				windowClass: 'vessel-photo-modal',
				size: 'vesselphoto',
				resolve: {
					title: function () {
						return title;
					},
					vessel_id: function () {
						return entity.id;
					},
					component_id: function () {
						return component_id;
					},
					entity: function () {
						return entity;
					},
					entity_id: function () {
						return entity_id;
					},
					model: function () {
						return profilePhotoModel;
					},
					crop_settings: function () {
						return crop_settings;
					}
				}
			});

			modalInstance.result.then(function (entity) {
				var oldFileId = entity.Vessel_File;
				entity.Vessel_File = entity.FileId;
				$scope.model.saveItem(entity)
					.then(function (result) {
						if (oldFileId) {
							profilePhotoModel.markFileDeleted(oldFileId);
						}
						$scope.reloadVessels();
					});
			}, function () {
				console.log('Modal dismissed at: ' + new Date());
			});
		};

		$scope.copyVessel = function (title, item) {
			var vesselCopy = new VesselCopy();
			var copyVesselModalInstance = $uibModal.open({
				templateUrl: 'components/vessels/vessels_copy.html',
				controller: 'VesselsCopyModalController',
				resolve: {
					title: function () {
						return title;
					},
					sourceVessel: function () {
						return item;
					},
					newVessel: function () {
						return vesselCopy;
					},
					model: function () {
						return $scope.copyVesselModel;
					}
				}
			});

			copyVesselModalInstance.result.then(function (newYacht) {
				common.showWorking();
				var savePromise = $scope.copyVesselModel.saveItem(newYacht).then(function(responseData) {
					console.log(responseData);
					$scope.reloadVessels()
						.then(function () {
							common.hideWorking();
						})
						.catch(function (error) {
							Exception.errorFn(error);
							common.hideWorking();
						});
				}, function (error) {
					common.hideWorking();
					Exception.errorFn(error);
				});
				if (!savePromise) {
					common.hideWorking();
				}
			}, function () {
				console.log('Modal dismissed at: ' + new Date());
			});
		};

		$scope.transferVesselOwnership = function (title, item) {
			var transferVesselOwnershipModalInstance = $uibModal.open({
				templateUrl: 'components/vessels/vessels_transferownership.html',
				controller: 'VesselsTransferOwnershipModalController',
				resolve: {
					title: function () {
						return title;
					},
					modalObject: function () {
						return item;
					},
					model: function () {
						return $scope.model;
					}
				}
			});

			transferVesselOwnershipModalInstance.result.then(function (vessel) {
				common.showWorking();
				var savePromise = $scope.model.saveItem(vessel).then(function(responseData) {
					console.log(responseData);
					$scope.reloadVessels()
						.then(function () {
							common.hideWorking();
						})
						.catch(function (error) {
							Exception.errorFn(error);
							common.hideWorking();
						});
				}, function (error) {
					common.hideWorking();
					Exception.errorFn(error);
				});
				if (!savePromise) {
					common.hideWorking();
				}
			}, function () {
				console.log('Modal dismissed at: ' + new Date());
			});
		};

		$scope.isYachtOwner = function (item) {
			if ($scope.userProfile.Id === item.Vessel_User) {
				return true;
			}
			else {
				return false;
			}
		};

		$scope.listenForChanges('Vessels');

	});
