/**
 * Created by petter on 01/12/21.
 */
'use strict';
angular.module('app')
	.controller('VesselsCopyModalController', function ($scope, $uibModalInstance, title, sourceVessel, newVessel, model) {
		$scope.modalObjectSource = newVessel;
	    if (sourceVessel.constructor && (sourceVessel.constructor.name === 'ItemBase')) {
		    $scope.newYacht = Object.assign({}, sourceVessel);  //Create a deep copy, so we can cancel without saving, but also inherit class properties
	    }
	    else {
		    $scope.newYacht = angular.extend({}, sourceVessel); //Create a shallow copy, so we can cancel without saving
	    }
	    $scope.model = model;
	    $scope.title = title;
	    // $scope.newYacht.Name = $scope.newYacht.Name + ' (copy)';
	    delete $scope.newYacht.Name;

		$scope.newYacht.sourceYachtId = sourceVessel.Id;
		$scope.newYacht.Vessel_VesselType = sourceVessel.Vessel_VesselType;
		$scope.newYacht.Vessel_File = sourceVessel.Vessel_File;
		$scope.newYacht.Vessel_TemplateFile = sourceVessel.Vessel_TemplateFile;
		$scope.newYacht.Manufacturer = sourceVessel.Manufacturer;
		$scope.newYacht.IncludeTasks = true;
		$scope.newYacht.IncludeServiceLogs = true;
		$scope.newYacht.Model = sourceVessel.Model;

		$scope.ok = function () {
			if (!$scope.model.isValidItem($scope.newYacht)){
				return;
			}

			//Shallow copy back changes, before returning
			angular.extend($scope.modalObjectSource, $scope.newYacht);
			$uibModalInstance.close($scope.modalObjectSource);
		};

		$scope.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};
	});
