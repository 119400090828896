/**
 * Created by petter on 2/22/19.
 */
// Common should ideally not have any dependencies as to not create a circular reference and only have a set of independent common methods
angular.module('app')
	.service('common', function ($rootScope, Configuration, screenSize, $http) {
		// console.log('created common');
		$rootScope.common = this;

		screenSize.rules = {
			mobile: '(min-width: 415px) and (max-width: 768px)',
			phoneplus: '(min-width: 321px) and (max-width: 414px)',
			phone: '(max-width: 320px)'
		};

		this.mobile = screenSize.is('phone, phoneplus, mobile');
		console.log('mobile:'+this.mobile);
		screenSize.on('phone, phoneplus, mobile', function(match){
			// console.log('screenSize:' + match);
			if ($rootScope.common.mobile === !match) {
				console.log('mobile:' + match);
				$rootScope.common.mobile = match;
				$rootScope.$broadcast('screen-changed');
			}
		});
		this.phoneplus = screenSize.is('phone, phoneplus');
		console.log('phoneplus:'+this.phoneplus);
		screenSize.on('phone, phoneplus', function(match){
			// console.log('screenSize:' + match);
			if ($rootScope.common.phoneplus === !match) {
				console.log('phoneplus:' + match);
				$rootScope.common.phoneplus = match;
				$rootScope.$broadcast('screen-changed');
			}
		});
		this.phone = screenSize.is('phone');
		console.log('phone:'+this.phone);
		screenSize.on('phone', function(match){
			// console.log('screenSize:' + match);
			if ($rootScope.common.phone === !match) {
				console.log('phone:' + match);
				$rootScope.common.phone = match;
				$rootScope.$broadcast('screen-changed');
			}
		});

		this.getDefaultIdColumn = function() {
			return {
				name:'Id', field: 'Id',
				// cellClass: 'hidden-sm hidden-xs', headerCellClass: 'hidden-sm hidden-xs',
				visible: !this.mobile && DEBUG,
				enableHiding:true, maxWidth: '70', filter: { placeholder: '...'}
			};
		};

		this.getSystemModified = function() {
			return {
				name:'Modified', field: 'Modified', width: '140',
				visible: !this.mobile && DEBUG,
				cellFilter: this.dateTimeCellFilter, enableHiding:true, filter: { placeholder: 'search modified...'}
			};
		};

		this.getSystemModifiedBy = function() {
			return {
				name:'ModifiedBy', field: 'ModifiedBy',
				visible: !this.mobile && DEBUG,
				enableHiding:true, filter: { placeholder: 'search modified by...'}
			};
		};

		//Cell filter for use in grids to format data: https://docs.angularjs.org/api/ng/filter/date
		this.dateCellFilter = 'date:\'shortDate\'';
		this.dateTimeCellFilter = 'date:\'short\'';

		this.showWorking = function () {
			//Show spinner
			// console.log('Showing spinner');
			this.working = true;
		};

		this.hideWorking = function () {
			//Hide spinner
			// console.log('Hiding spinner');
			this.working = false;
		};

		this.postAnalytics = function(endpoint, telemetry) {
			$http({
				method: 'POST',
				url: Configuration.PUBLIC_API + '/analytics/' + endpoint,
				data: {
					telemetry: telemetry
				}
			})
				.then(function (value) {
					// console.log('Successfully posted analytics');
				})
				.catch(function (reason) {
					// console.warn('Failed to post analytics:' + reason.message);
				});
		};

		this.trackView = function (page) {
			if (window.ga && (location.hostname === Configuration.PrimaryDomin)) {
				// if (ga) {
				ga('set', 'page', page);
				ga('send', 'pageview');
			}
			if (window.gtag) {
				gtag('event', 'screen_view', {
					'app_name': 'ShipsIO Web',
					'screen_name' : page
				});
			}
			if (window.appInsights) {
				appInsights.trackPageView(page);
			}
			// this.postAnalytics('trackView', page);
		};

		this.trackEvent = function (event, property, argument) {
			if (window.ga && (location.hostname === Configuration.PrimaryDomin)) {
				ga('send', 'event', event, property, argument);
			}
			if (window.gtag && (location.hostname === Configuration.PrimaryDomin)) {
				gtag('event', event, {
					'event_category': 'engagement',
					'event_label': property,
					'value': argument
				});
			}
			if (window.appInsights) {
				appInsights.trackEvent(event + '_' + property);
			}
			if (argument) {
				if (property) {
					this.postAnalytics('trackEvent', {name: event + '_' + property, value: argument});
				}
				else {
					this.postAnalytics('trackEvent', {name: event, value: argument});
				}
			}
			else {
				this.postAnalytics('trackEvent', {name: event, value: property});
			}
		};

		this.trackUser = function (userId) {
			if (this.trackedUser)
				//Only track user once per session
				return;
			this.trackedUser = true;

			if (window.ga && (location.hostname === Configuration.PrimaryDomin)) {
				ga('set', 'UserId',userId);
				ga('send', 'event', 'authenticated', userId);
			}
			if (window.gtag) {
				gtag('config', 'GA_MEASUREMENT_ID', {
					'user_id': userId
				});
			}
			if (window.appInsights) {
				appInsights.setAuthenticatedUserContext(userId);
			}
			// this.postAnalytics('trackUser', userId);
		};

		this.trackException = function (err) {
			console.error(err);
			try {
				// if (window.ga && (location.hostname === Configuration.PrimaryDomin)) {
				//     // if (ga) {
				//     ga('send', 'event', event, property, argument);
				// }
				$http({
					method: 'POST',
					url: Configuration.PUBLIC_API + '/analytics/error',
					data: {
						error: {
							message: err.message,
							stack: err.stack,
							platform: 'web',
							appName: 'app',
							appVersion: VERSION
						}
					}
				})
					.then(() => {
						console.log('Successfully posted error');
					})
					.catch(reason => {
						console.warn('Failed to post error:' + reason.message);
					});
				if (window.gtag) {
					gtag('event', 'exception', {
						'description': err,
						'fatal': false   // set to true if the error is fatal
					});
				}
				if (window.appInsights) {
					appInsights.trackException(err);
				}
				if (window.Sentry) {
					if (err.status && (err.status === 401)) {
						console.warn("Unauthroized user error ignored and not reported to Sentry");
					}
					else {
						window.Sentry.captureException(err);
					}
				}
			}
			catch (trackError) {
				console.error(trackError);
			}
		};

	});
