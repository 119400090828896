/**
 * Created by petter on 3/31/16.
 */
'use strict';

angular.module('app')
	.factory('FileTypeModel', function ($q, Model, FileType) {
		function FileTypeModel() {
			Model.call(this, FileType);
		}

		FileTypeModel.prototype = Object.create(Model.prototype);
		FileTypeModel.prototype.constructor = FileTypeModel;

		FileTypeModel.prototype.load = function(params) {
			return Model.prototype.load.call(this, params);
		};

		FileTypeModel.prototype.saveItem = function(data) {
			throw new Error("Can't save a file type, it's just a lookup type");
		};

		FileTypeModel.prototype.onLoadedItem = function(item) {
			//Strip model property, otherwise typeahead will cause stack overflow when using $viewValue filter
			item.model = null;
		};

		FileTypeModel.prototype.isValidItem = function(item) {
			return false;
		};

		return FileTypeModel;
	});

angular.module('app')
	.factory('FileType', function(ItemBase, Configuration) {
		function FileType() {
			ItemBase.call(this);
		}

		FileType.prototype = Object.create(ItemBase.prototype);

		FileType.dataColumns = [];
		// FileType.templateUrl = 'components/crew/crew_edit.html';
		FileType.url = Configuration.API + '/filetypes';

		FileType.prototype.onNewItem = function() {
			// this.VesselUserRole_Vessel = this.modelClass.type_id;
		};

		Object.defineProperty(FileType.prototype, 'value', {
			get: function () {
				return this.Type;
			}
		});

		Object.defineProperty(FileType.prototype, 'label', {
			get: function () {
				return this.Type;
			}
		});

		return FileType;
	});

