'use strict';

var IS_IONIC=false;

// Declare app level module which depends on views, and components
angular.module('app', [
	'ngRoute',
	'ngResource',
	'ngAnimate',
	'LocalStorageModule',
	'ui.bootstrap',
	'ngTouch',
	'ui.grid',
	// 'ui.grid.pagination',
	'ui.grid.resizeColumns',
	'ui.grid.exporter',
	'ui.grid.importer',
	'ui.grid.autoResize',
	'ui.grid.moveColumns',
	'angular-storage',
	'angular-jwt',
	'matchMedia',
	'angular-img-cropper',
	'ngFileUpload',
	'rt.debounce',
	'ui.thumbnail',
	'ngclipboard'

	//]).
	//config(['$routeProvider', function($routeProvider) {
	//    $routeProvider.otherwise({redirectTo: '/view1'});
	//}]);

])
// .config( function($routeProvider, angularAuth0Provider, $httpProvider, $locationProvider,
//                   jwtOptionsProvider, jwtInterceptorProvider, localStorageServiceProvider) {
	.config( function($routeProvider, $httpProvider, $locationProvider,
		jwtOptionsProvider, jwtInterceptorProvider, localStorageServiceProvider) {
		$locationProvider.html5Mode({
			enabled: true,
			requireBase: false
		});
		$locationProvider.hashPrefix('');
		localStorageServiceProvider
			.setPrefix('quartermaster')
			.setStorageType('localStorage')
			.setNotify(true, true); // Not sure what this setting does
		$routeProvider
		// .when( '/', {
		//     templateUrl: 'home.html',
		//     pageTitle: 'Home',
		//     requiresLogin: false
		// })
			.when( '/test', {
				controller: 'TestController',
				templateUrl: 'components/test/test_view.html',
				pageTitle: 'Test',
				requiresLogin: false
			})
	        // .when( '/login', {
		    //     controller: 'LoginCtrl',
		    //     // templateUrl: 'components/dashboard/dashboard_view.html',
		    //     requiresLogin: false
	        // })
			.when( '/dashboard', {
				controller: 'DashboardController',
	            templateUrl: 'components/dashboard/dashboard_view.html',
				// controller: 'DashboardV2Controller',
				// templateUrl: 'components/dashboardv2/dashboard_view.html',
				pageTitle: 'Dashboard',
				requiresLogin: true
			})
	        .when( '/storage', {
		        controller: 'StorageController',
		        templateUrl: 'components/storage/storage_view.html',
		        pageTitle: 'Storage',
		        requiresLogin: true
	        })
	        .when( '/storage/:entity_id/files/:component_id', {
		        controller: 'FilesController',
		        templateUrl: 'components/files/files_view.html',
		        pageTitle: 'Files',
		        requiresLogin: true
	        })
			.when( '/systems', {
				controller: 'SystemController',
				templateUrl: 'components/system/system_view.html',
				pageTitle: 'Systems',
				requiresLogin: true
			})
			.when( '/systems/:entity_id/files/:component_id', {
				controller: 'FilesController',
				templateUrl: 'components/files/files_view.html',
				pageTitle: 'Files',
				requiresLogin: true
			})
			.when( '/tasks', {
				controller: 'TaskController',
				templateUrl: 'components/tasks/tasks_view.html',
				pageTitle: 'Tasks',
				requiresLogin: true
			})
			.when( '/tasks/:entity_id/files/:component_id', {
				controller: 'FilesController',
				templateUrl: 'components/files/files_view.html',
				pageTitle: 'Files',
				requiresLogin: true
			})
	        .when( '/expenses', {
		        controller: 'SystemExpensesController',
		        templateUrl: 'components/systemexpenses/systemexpenses_view.html',
		        pageTitle: 'Expenses',
		        requiresLogin: true
	        })
	        .when( '/tracking', {
		        controller: 'TrackingController',
		        templateUrl: 'components/tracking/tracking_view.html',
		        pageTitle: 'Tracking',
		        requiresLogin: true
	        })
			.when( '/kanban', {
				controller: 'KanbanController',
				templateUrl: 'components/kanban/kanban_view.html',
				pageTitle: 'Kanban',
				requiresLogin: true
			})
			.when( '/vessels/:entity_id/files/:component_id', {
				controller: 'FilesController',
				templateUrl: 'components/files/files_view.html',
				pageTitle: 'Files',
				requiresLogin: true
			})
			.when( '/vessels', {
				controller: 'VesselsController',
				templateUrl: 'components/vessels/vessels_view.html',
				pageTitle: 'Vessels',
				requiresLogin: true
			})
			.when( '/vessels/:id/dashboards', {
				controller: 'DashboardsController',
				templateUrl: 'components/dashboards/dashboards_view.html',
				pageTitle: 'Dashboards',
				requiresLogin: true
			})
			.when( '/vessels/:id/crew', {
				controller: 'CrewController',
				templateUrl: 'components/crew/crew_view.html',
				pageTitle: 'Crew',
				requiresLogin: true
			})
	        .when( '/vessels/:id/report', {
		        controller: 'ReportController',
		        templateUrl: 'components/report/report_view.html',
		        pageTitle: 'Report',
		        requiresLogin: true
	        })
	        .when( '/vessels/:id/schedules', {
		        controller: 'VesselSchedulesController',
		        templateUrl: 'components/vesselschedules/vesselschedules_view.html',
		        pageTitle: 'Report',
		        requiresLogin: true
	        })
			.when( '/systems/:id/services', {
				controller: 'SystemServicesController',
				templateUrl: 'components/systemservices/systemservices_view.html',
				pageTitle: 'Maintenance',
				requiresLogin: true
			})
			.when( '/systems/:entity_id/services/files/:component_id', {
				controller: 'FilesController',
				templateUrl: 'components/files/files_view.html',
				pageTitle: 'Files',
				requiresLogin: true
			})
			.when( '/systems/:id/schedules', {
				controller: 'SystemSchedulesController',
				templateUrl: 'components/systemschedules/systemschedules_view.html',
				pageTitle: 'Service Schedules',
				requiresLogin: true
			})
			.when( '/systems/:entity_id/schedules/files/:component_id', {
				controller: 'FilesController',
				templateUrl: 'components/files/files_view.html',
				pageTitle: 'Files',
				requiresLogin: true
			})
	        .when( '/systems/:id/summary', {
		        controller: 'SystemSummaryController',
		        templateUrl: 'components/systemsummary/systemsummary_view.html',
		        pageTitle: 'System summary',
		        requiresLogin: true
	        })
	        .when( '/storage/:id/items', {
		        controller: 'StorageItemsController',
		        templateUrl: 'components/storageitems/storageitems_view.html',
		        pageTitle: 'Maintenance',
		        requiresLogin: true
	        })
	        .when( '/fleet', {
		        controller: 'FleetController',
		        templateUrl: 'components/fleet/fleet_view.html',
		        pageTitle: 'Fleet',
		        requiresLogin: true
	        })
	        .when( '/account', {
				controller: 'AccountController',
				templateUrl: 'components/account/account_view.html',
				pageTitle: 'Account',
				requiresLogin: true
			})
			.when( '/callback', {
				controller: 'CallbackController',
				templateUrl: 'core/auth/callback.html',
				pageTitle: 'Login',
				requiresLogin: false
			})
			.when( '/', {
				controller: 'HomeController',
				// templateUrl: 'home/home_view.html',
	            template: ' ',
				pageTitle: 'Quartermaster',
				requiresLogin: false
			})
			.when( '/promo/:coupon', {
				controller: 'CouponController',
				templateUrl: 'components/coupon/coupon_view.html',
				pageTitle: 'Coupon',
				requiresLogin: false
			})
			.otherwise({
				redirectTo: '/'
			})
		;

		// var redirectUrl = location.origin + '/#/dashboard';
		// redirectUrl = 'http://localhost:63342/QuarterMasterWeb/app/index.html#/dashboard';

		// Initialization for the Lock widget
		// angularAuth0Provider.init({
		//     domain: AUTH0_DOMAIN,
		//     clientID: AUTH0_CLIENT_ID_WEB,
	    //     // configurationBaseUrl: 'https://cdn.auth0.com',
		//     //loginUrl: '/dashboard',
		//     redirectUri: location.origin + '/callback',
		//     responseType: 'token id_token',
		//     scope: 'openid profile email'
		//     // options: {
		//     //     _idTokenVerification: false,
		//     //     theme: {
		//     //         logo: 'img/logo-login.svg',
		//     //         primaryColor: "#b81b1c"
		//     //     },
		//     //     languageDictionary: {
		//     //         title: "Log In"
		//     //     },
		//     //     auth: {
		//     //         redirect: true,
		//     //         sso: false,
		//     //         params: {
		//     //             scope: 'openid offline_access name email user_metadata app_metadata',
		//     //             device: 'web'
		//     //         },
		//     //         redirectUrl: redirectUrl,
		//     //         responseType: 'token'
		//     //     }
		//     // }
		// });

		// Configuration for angular-jwt
		jwtOptionsProvider.config({
			tokenGetter: ['options', function(options) {
				// Skip authentication for any requests ending in .html
				if (options && options.url.substr(options.url.length - 5) == '.html') {
					return null;
				}
				var token = localStorage.getItem('id_token');
				return token;
			}],
			whiteListedDomains: [
	            'quartermaster.me',
	            'quartermaster-preprod.azurewebsites.net',
	            'qa.quartermaster.me',
	            's3-us-west-1.amazonaws.com',
            	'localhost'
			],
			unauthenticatedRedirectPath: '/'
		});

		// jwtInterceptorProvider.tokenGetter = function(store) {
		//     return store.get('token');
		//     // var token = store.get('token');
		//     // var refreshToken = store.get('refreshToken');
		//     // if (token) {
		//     //     if (!jwtHelper.isTokenExpired(token)) {
		//     //         return store.get('token');
		//     //     } else {
		//     //         if (refreshingToken === null) {
		//     //             refreshingToken =  auth.refreshIdToken(refreshToken).then(function(idToken) {
		//     //                 store.set('token', idToken);
		//     //                 return idToken;
		//     //             }).finally(function() {
		//     //                 refreshingToken = null;
		//     //             });
		//     //         }
		//     //         return refreshingToken;
		//     //     }
		//     // }
		// };

		// Add a simple interceptor that will fetch all requests and add the jwt token to its authorization header.
		// NOTE: in case you are calling APIs which expect a token signed with a different secret, you might
		// want to check the delegation-token example
		$httpProvider.interceptors.push('jwtInterceptor');
	})
	.run(function($rootScope, authService, authManager, store, $location, common) {

		// Put the authService on $rootScope so its methods
		// can be accessed from the nav bar
		$rootScope.authService = authService;

		if (localStorage.getItem('id_token')) {
	        authService.checkAuthOnRefresh();
			// if ($location.$$hash) {
			//     console.log('Already logging in: ' + $location.$$hash);
			// }
			// else {
			//     // authService.renewTokens();
			//     authService.checkAuthOnRefresh();
			// }
			// } else {
			//     // Handle the authentication
			//     // result in the hash
			//     authService.handleAuthentication();
		}

		// Register the authentication listener that is
		// set up in auth.service.js
		// authService.registerAuthenticationListener();

		// Intercept the hash that comes back from authentication
		// to ensure the `authenticated` event fires
		// lock.interceptHash();

		// Use the authManager from angular-jwt to check for
		// the user's authentication state when the page is
		// refreshed and maintain authentication
		// authManager.checkAuthOnRefresh();

		// Listen for 401 unauthorized requests and redirect
		// the user to the login page
		authManager.redirectWhenUnauthenticated();

		//This event gets triggered on URL change
		// $rootScope.$on('$locationChangeStart', authService.checkAuthOnRefresh);

		// $rootScope.$on('$locationChangeStart', function(param) {
		//     // if (!auth.isAuthenticated) {
		//     //     //Attempt to re-authenticate
		//     //     var token = store.get('token');
		//     //     if (token) {
		//     //         if (!jwtHelper.isTokenExpired(token)) {
		//     //             auth.authenticate(store.get('profile'), token);
		//     //             if (($location.$$path === '') || ($location.$$path === '/')) {
		//     //                 $location.path('/dashboard');
		//     //             }
		//     //             // if ($scope.qm) {
		//     //             //     $scope.qm.loadVessels();
		//     //             // }
		//     //         } else {
		//     //             $location.path('/');
		//     //         }
		//     //     }
		//     // }
		//
		// });

		$rootScope.$on('tokenHasExpired', function() {
			console.log('Your session has expired!');
		});

		$rootScope.$on('$locationChangeSuccess', function(param) {
			var hash = location.pathname;
			console.log('route:' + hash);
			if (hash) {
				var components = hash.split('/');
				hash = '';
				for (var i=1; i<components.length; i++) {
					//Only add non-number components
					if (isNaN(components[i])) {
						hash += '/' + components[i];
					}
				}
				common.trackView(hash);
			}
		});
	})
	.controller( 'AppCtrl', function AppCtrl ( $scope, $location, auth ) {
		$scope.auth = auth;
		$scope.$on('$routeChangeSuccess', function(e, nextRoute){
			if ( nextRoute.$$route && angular.isDefined( nextRoute.$$route.pageTitle ) ) {
				// $scope.pageTitle = nextRoute.$$route.pageTitle + ' | Auth0 Sample' ;
				$scope.pageTitle = nextRoute.$$route.pageTitle;
			}
		});
	});

// var onThumbnailError = function(img) {
// 	console.log('onThumbnailError');
// 	try {
// 		// var img = this;
// 		var isRet = true;
// 		var r = 3;
//
// 		// if (img.hasAttribute('dt-retry')) {
// 		// 	r = parseInt(img.getAttribute('dt-retry'));
// 		// 	r = r - 1;
// 		// 	img.setAttribute('dt-retry', r);
// 		// 	if (r <= 0) {
// 		// 		isRet = false;
// 		// 	}
// 		// }
//
// 		var temp = new Image();
// 		// temp.setAttribute('dt-retry', r);
// 		temp.onerror = onThumbnailError(temp);
// 		// img.src = null;
// 		temp.src = img.src;
//
// 		// if (isRet) {
// 		// 	setTimeout(function() {
// 		// 		var temp = new Image();
// 		// 		// temp.setAttribute('dt-retry', r);
// 		// 		temp.onerror = onThumbnailError(temp);
// 		// 		// img.src = null;
// 		// 		temp.src = img.src;
// 		// 		// img.src = temp.src;
// 		// 	}, 1000);
// 		// }
// 	} catch (e) {
// 		console.error(e);
// 	}
// };

