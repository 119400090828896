/**
 * Created by petter on 6/20/19.
 */
'use strict';

// StorageModel is a proxy for System in which case the type of system is storage (i.e. SystemType = 1)
angular.module('app')
	.factory('DashboardSystemModel', function ($q, Model, SystemModel, DashboardSystem) {
		function DashboardSystemModel() {
	        Model.call(this, DashboardSystem);
		}

		DashboardSystemModel.prototype = Object.create(SystemModel.prototype);
		DashboardSystemModel.prototype.constructor = DashboardSystemModel;

	    DashboardSystemModel.prototype.saveItem = function(data) {
		    if (data.isStorage) {
			    data.System_SystemType = 1;
		    }
		    return SystemModel.prototype.saveItem.call(this, data);
	    };

	    return DashboardSystemModel;
	});

angular.module('app')
	.factory('DashboardSystem', function(ItemBase, System, Configuration) {
		function DashboardSystem() {
	        ItemBase.call(this);
			this.System_SystemType = 0; //Default to regular system
		}

		DashboardSystem.prototype = Object.create(System.prototype);

		DashboardSystem.dataColumns = System.dataColumns;
		DashboardSystem.templateUrl = 'components/dashboard/dashboard_edit.html';
		DashboardSystem.url = System.url;

	    // Object.defineProperty(DashboardSystem.prototype, 'isStorage', {
		//     get: function () {
		// 	    return (this.System_SystemType === 1);
		//     },
		//     set: function (value) {
		// 	    if (value === true) {
		// 		    return this.System_SystemType = 1;
		// 	    }
		// 	    else {
		// 		    return this.System_SystemType = 0;
		// 	    }
		//     }
	    // });

		return DashboardSystem;
	});

