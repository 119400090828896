/**
 * Created by petter on 2/18/16.
 */
'use strict';

angular.module('app')
	.factory('DashboardModel', function ($q, Model, Dashboard) {
		function DashboardModel() {
			Model.call(this, Dashboard);
		}

		DashboardModel.prototype = Object.create(Model.prototype);
		DashboardModel.prototype.constructor = DashboardModel;

		DashboardModel.prototype.load = function(vessel_id, dashboard_id) {
			if (vessel_id) {
				this.vessel_id = vessel_id;
			}
			if (dashboard_id) {
				this.dashboard_id = dashboard_id;
			}

			return Model.prototype.load.call(this, {vessel_id: this.vessel_id, dashboard_id: this.dashboard_id});
		};

		DashboardModel.prototype.saveItem = function(data) {
			data.System_Vessel = this.vessel_id;
			data.System_Dashboards = this.dashboard_id;
			return Model.prototype.saveItem.call(this, data);
		};

		DashboardModel.prototype.removeItem = function(data) {
			// data.System_Vessel = this.vessel_id;
			data.System_Dashboards = null;
			data.x = null;
			data.y = null;
			return Model.prototype.saveItem.call(this, data);
		};

		DashboardModel.prototype.onLoadedItem = function(item) {
			item.id = item.Id;
			// console.log('Loaded ' + item.Description);
		};

		//Create a no-op refreshItem method to account for missing GET in dashboard API that gets called after save,
	    //when configuring dashboard
	    DashboardModel.prototype.refreshItem = function(responseData) {
		    var deferred = $q.defer();
		    deferred.resolve(responseData);
		    return deferred.promise;
	    };

		DashboardModel.prototype.isValidItem = function(item) {
			return true;
		};

		return DashboardModel;
	});

angular.module('app')
	.factory('Dashboard', function(ItemBase, Configuration) {
		function Dashboard() {
			ItemBase.call(this);
		}

		Dashboard.prototype = Object.create(ItemBase.prototype);

		Dashboard.dataColumns = ['x', 'y', 'System_Dashboards', 'System_Vessel'];    //We only needs to basic field for moving/placing the system on a dashboard
		Dashboard.templateUrl = null;
		Dashboard.url = Configuration.API + '/dashboard/:id';

		return Dashboard;
	});

