/**
 * Created by petter on 2/18/16.
 */
'use strict';

angular.module('app')
	.factory('SystemModel', function ($q, Model, System) {
		function SystemModel() {
			Model.call(this, System);
			this.types = [
	            {
		            name:'Storage',
		            id:1
	            },
				{
					name:'Engine',
					id:2
				},
				{
					name:'Watermaker',
					id:3
				},
				{
					name:'Tank',
					id:4
				}
			];
		}

		SystemModel.prototype = Object.create(Model.prototype);
		SystemModel.prototype.constructor = SystemModel;

		SystemModel.prototype.load = function(vessel_id, params) {
			if (vessel_id) {
				this.vessel_id = vessel_id;
				if (!params) {
					params = {vessel_id: vessel_id};
				}
				else {
					params.vessel_id = vessel_id;
				}
			}

			return Model.prototype.load.call(this, params);
		};

		SystemModel.prototype.saveItem = function(data) {
			if (this.vessel_id) {
				data.System_Vessel = this.vessel_id;
			}
			return Model.prototype.saveItem.call(this, data);
		};

		SystemModel.prototype.onLoadedItem = function(item) {
			item.id = item.Id;
			console.log('Loaded ' + item.Description);
		};

		SystemModel.prototype.isValidItem = function(item) {
			this.isItemDirty = true;
			item.isItemDirty = true;
			if (!item.Description){
				return false;
			}
			return true;
		};

		return SystemModel;
	});

angular.module('app')
	.factory('System', function(ItemBase, Configuration) {
		function System() {
			ItemBase.call(this);
		}

		System.prototype = Object.create(ItemBase.prototype);

		System.dataColumns = ['Description', 'Notes', 'x', 'y', 'TrackHours', 'TrackExpenses', 'ShowPhotos', 'System_SystemType', 'System_Vessel','Manufacturer', 'Model'];
		System.templateUrl = 'components/system/system_edit.html';
		System.url = Configuration.API + '/systems/:id';
		//System.url = 'http://quartermasterweb.azurewebsites.net/api/systems/:id';

		Object.defineProperty(System.prototype, 'Type', {
			get: function () {
				return this.System_SystemType;
			},
			set: function (value) {
				// this.isItemDirty = true;
				return this.System_SystemType = value;
			}
		});

		Object.defineProperty(System.prototype, 'Vessel', {
			get: function () {
				return this.System_Vessel;
			},
			set: function (value) {
				// this.isItemDirty = true;
				return this.System_Vessel = value;
			}
		});

		return System;
	});

