/**
 * Created by petter on 2/14/19.
 */
'use strict';

angular.module('app')
	.factory('FleetTaskModel', function ($q, Model, FleetTask) {
		function FleetTaskModel() {
	        Model.call(this, FleetTask);
		}

		FleetTaskModel.prototype = Object.create(Model.prototype);
		FleetTaskModel.prototype.constructor = FleetTask;

		FleetTaskModel.prototype.load = function(vessel_id_list) {
	        return Model.prototype.load.call(this, {vessel_id_list: vessel_id_list});
		};

		FleetTaskModel.prototype.saveItem = function() {
        	throw new Error('Not supported');
		};

		FleetTaskModel.prototype.isValidItem = function(item) {
	        return false;
		};

		return FleetTaskModel;
	});

angular.module('app')
	.factory('FleetTask', function(Task, Configuration) {
		function FleetTask() {
	        Task.call(this);
		}

		FleetTask.prototype = Object.create(Task.prototype);

		FleetTask.dataColumns = [];
		FleetTask.templateUrl = '';
		FleetTask.url = Configuration.API + '/fleettasks/:id';

	    return FleetTask;
	});

