/**
 * Created by petter on 2/18/16.
 */
'use strict';

angular.module('app')
	.factory('FileModel', function ($q, Model, File, FileTypeModel, Constants, common, uiGridConstants) {
		function FileModel() {
			Model.call(this, File);
			this.fileTypeModel = new FileTypeModel();
			this.types = this.fileTypeModel.items;
	        this.parentIsVisible = false;
		}

		FileModel.prototype = Object.create(Model.prototype);
		FileModel.prototype.constructor = FileModel;

		FileModel.prototype.load = function(component_id, entity_id, params) {
			if (component_id) {
				this.component_id = component_id;
			}
			if (entity_id) {
				this.entity_id = entity_id;
			}

			if (!params) {
				params = {component_id: this.component_id, entity_id: this.entity_id};
			}
			else {
				params.component_id = this.component_id;
				params.entity_id = this.entity_id;
			}

			this.fileTypeModel.load({component_id: this.component_id, entity_id: this.entity_id}).then(function(result) {
				console.log('Loaded file types');
			});

	        this.parentIsVisible = (this.component_id === Constants.FileComponent_Files.toString());

	        return Model.prototype.load.call(this, params);
		};

		FileModel.prototype.saveItem = function(data) {
			data.component_id = this.component_id;
			data.entity_id = this.entity_id;
			return Model.prototype.saveItem.call(this, data);
		};

		FileModel.prototype.onLoadedItem = function(item) {
			item.id = item.Id;
			if (item.componentId === Constants.FileComponent_Files)
				item.Parent = 'None';
			else if (item.componentId === Constants.FileComponent_System)
				item.Parent = 'System';
			else if (item.componentId === Constants.FileComponent_SystemService)
				item.Parent = 'Service';
			else if (item.componentId === Constants.FileComponent_SystemSchedule)
				item.Parent = 'Schedule';
			else if (item.componentId === Constants.FileComponent_Task)
				item.Parent = 'Task';
			else
				item.Parent = 'Unknown';
			console.log('Loaded ' + item.FileName);
		};

		FileModel.prototype.isValidItem = function(item) {
			return true;
		};

		FileModel.prototype.getFileSizeText = function(filesize) {
			var filesizeKB = Math.round(filesize / 1024 * 100)/100;
			var filesizeMB = Math.round(filesize / 1024 / 1024 * 100)/100;
			var filesizeGB = Math.round(filesize / 1024 / 1024 / 1024 * 100)/100;
			if (filesizeGB >= 1) {
				return filesizeGB + ' GB';
			}
			else if (filesizeMB >= 1) {
				return filesizeMB + ' MB';
			}
			return filesizeKB + ' KB';
		};

	    FileModel.prototype.getGridColumns = function() {
		    return [
			    common.getDefaultIdColumn(),
			    // { name:'Preview', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '82',
			    //    cellTemplate: 'components/files/files_thumbnail_template.html'
			    // },
			    { name:'FileName', field: 'FileName', enableHiding:false, filter: { placeholder: 'search name...'},
				    cellTemplate: 'components/files/files_name_template.html'
			    },
			    { name:'FileSize', field: 'FileSizeDescription', width: '80', visible: !common.phoneplus, enableHiding:false, filter: { placeholder: 'search ...'} },
			    { name:'Parent', field: 'Parent', width: '80', enableHiding:false, visible: !common.mobile && this.parentIsVisible, filter: {
					    placeholder: 'select type...',
					    type: uiGridConstants.filter.SELECT,
					    selectOptions: [
						    {value:'None', label:'None'},
						    {value:'System', label:'System'},
						    {value:'Service', label:'Service'}
					    ]
				    },
				    cellTemplate: 'components/files/files_parent_template.html'
			    },
			    { name:'Type', field: 'Type', width: '20%', enableHiding:false, filter: {
					    placeholder: 'select type...',
					    type: uiGridConstants.filter.SELECT,
					    selectOptions: this.types
				    } },
			    { name:'Expires', field: 'ExpireDate', type: 'date', width: '80', visible: !common.phoneplus, cellFilter: common.dateCellFilter, enableHiding:false, sort: { priority: 0, direction: 'asc' }, filter: { placeholder: 'search...'},
				    cellTemplate: 'components/files/files_expires_template.html'
			    },
			    { name:'Uploaded', maxWidth: '80', field: 'Created', visible: !common.phoneplus, cellFilter: common.dateCellFilter, enableHiding:false, filter: { placeholder: 'search...'} },
			    common.getSystemModified(),common.getSystemModifiedBy(),
			    { name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '100',
				    cellTemplate: 'components/files/files_action_template.html'
			    }
		    ];
	    };

	    return FileModel;
	});

angular.module('app')
	.factory('File', function(ItemBase, Configuration) {
		function File() {
			ItemBase.call(this);
		}

		File.prototype = Object.create(ItemBase.prototype);

		File.dataColumns = ['FileName', 'Description', 'ExpireDate', 'Type', 'Files_Vessel'];
		File.templateUrl = 'components/files/files_edit.html';
		File.url = Configuration.API + '/files/:id';

		Object.defineProperty(File.prototype, 'FileSizeDescription', {
			get: function () {
				if (!this._filesize) {
					this._filesize = this.model.getFileSizeText(this.filesize);
				}
				return this._filesize;
			}
		});

	    Object.defineProperty(File.prototype, 'icon', {
		    get: function () {
			    var fileName = this.FileName.toLowerCase();
			    if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png') || fileName.endsWith('.webp')) {
				    return 'file-icon-image';
			    }
			    else if (fileName.endsWith('.pdf')) {
				    return 'file-icon-pdf';
			    }
			    return 'file-icon-binary';
		    }
	    });

	    Object.defineProperty(File.prototype, 'thumbnail', {
		    get: function () {
			    // 'https://s3-us-west-1.amazonaws.com/files.qa.quartermaster.com/thumbnails/1185/1/f12b6239-2e53-4bde-829d-426551970784/Vetus+Air+Vent.jpg';
			    // var s3 = 'https://s3-us-west-1.amazonaws.com/'+S3_SETTINGS+'/thumbnails/';
			    var s3 = S3_HOST+S3_SETTINGS+'/thumbnails/';
			    var thumbnail = s3 + this.Files_Vessel + '/' + this.componentId + '/' + this.uuid + '/' + this.FileName.replace(/\.\w+$/, ".jpg");
			    return thumbnail;
		    }
	    });

	    File.prototype.isExpired = function() {
	    	if (!this.ExpireDate) {
	    		return false;
		    }
		    var today = new Date();
		    if (this.ExpireDate < today) {
		    	// console.log('Expired: ' + this.ExpireDate);
			    return true;
		    }
		    else {
			    return false;
		    }
	    };

		return File;
	});

