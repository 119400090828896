/**
 * Created by petter on 2/18/16.
 */
'use strict';

angular.module('app')
	.factory('DashboardsModel', function ($q, Model, Dashboards, s3factory, Constants, common) {
		function DashboardsModel() {
			Model.call(this, Dashboards);
		}

		DashboardsModel.prototype = Object.create(Model.prototype);
		DashboardsModel.prototype.constructor = DashboardsModel;

		DashboardsModel.prototype.load = function(vessel) {
			if (vessel) {
				this.vessel = vessel;
			}

			return Model.prototype.load.call(this, {vessel_id: this.vessel.Id});
		};

		DashboardsModel.prototype.saveItem = function(data) {
			data.Dashboards_Vessel = this.vessel.Id;
			return Model.prototype.saveItem.call(this, data);
		};

		DashboardsModel.prototype.onLoadedItem = function(item) {
			item.templateUrl = s3factory.getProfilePhotoUrl(item.Files_Vessel, Constants.FileComponent_DashboardTemplate, item.Template_uuid, item.Template_FileName);
			if (!item.templateUrl) {
				if (this.vessel.Type) {
					item.templateUrl = './img/layout-'+this.vessel.Type.toLowerCase()+'.svg';
				}
				else {
					item.templateUrl = './img/layout-power.svg';
				}
			}
			item.id = item.Id;
			// console.log('Loaded ' + item.Name);
	        try {
		        if (item.Config) {
			        item.DashboardsConfig = JSON.parse(item.Config);
			        if (!item.DashboardsConfig.Scale) {
				        item.DashboardsConfig.Scale = 100;
			        }
			        else if (item.DashboardsConfig.Scale > 200) {
				        item.DashboardsConfig.Scale = 200;
			        }
			        else if (item.DashboardsConfig.Scale < 50) {
				        item.DashboardsConfig.Scale = 50;
			        }
		        }
		        else {
		        	item.DashboardsConfig = {Scale: 100};
		        }
	        }
	        catch (jsonError) {
		        console.error(jsonError);
	        }
		};

		DashboardsModel.prototype.isValidItem = function(item) {
			this.isItemDirty = true;
			item.isItemDirty = true;
			if (!item.Name){
				return false;
			}
			return true;
		};

	    DashboardsModel.prototype.getFileSizeText = function(filesize) {
		    if (!filesize) {
			    return '';
		    }
		    var filesizeKB = Math.round(filesize / 1024 * 100)/100;
		    var filesizeMB = Math.round(filesize / 1024 / 1024 * 100)/100;
		    var filesizeGB = Math.round(filesize / 1024 / 1024 / 1024 * 100)/100;
		    if (filesizeGB >= 1) {
			    return filesizeGB + ' GB';
		    }
		    else if (filesizeMB >= 1) {
			    return filesizeMB + ' MB';
		    }
		    return filesizeKB + ' KB';
	    };

	    DashboardsModel.prototype.getGridColumns = function() {
		    return [
			    common.getDefaultIdColumn(),
			    { name:'Template', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '106',
				    cellTemplate: 'components/dashboards/dashboards_template_template.html'
			    },
			    { name:'Name', field: 'Name', enableHiding:false, sort: { priority: 1, direction: 'asc' }, filter: { placeholder: 'search name...'} },
			    { name:'Sort', field: 'Sort', enableHiding:true, sort: { priority: 0, direction: 'asc' }, visible:false, width:80 },
			    common.getSystemModified(),common.getSystemModifiedBy(),
			    { name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '80',
				    cellTemplate: 'components/dashboards/dashboards_action_template.html'
			    }
		    ];
	    };

		return DashboardsModel;
	});

angular.module('app')
	.factory('Dashboards', function(ItemBase, Configuration) {
		function Dashboards() {
			ItemBase.call(this);
			// this.profileUrl = './img/default-avatar.png';
		}

		Dashboards.prototype = Object.create(ItemBase.prototype);

		Dashboards.dataColumns = ['Name', 'Dashboards_TemplateFile', 'Dashboards_Vessel'];
		Dashboards.templateUrl = 'components/dashboards/dashboards_edit.html';
		Dashboards.url = Configuration.API + '/dashboards/:id';

		// Object.defineProperty(Vessel.prototype, 'Type', {
		//     get: function () {
		//         return this.Vessel_VesselType;
		//     },
		//     set: function (value) {
		//         // this.isItemDirty = true;
		//         return this.Vessel_VesselType = value;
		//     }
		// });

	    Object.defineProperty(Dashboards.prototype, 'FileSizeDescription', {
		    get: function () {
			    if (!this._Template_FileSize) {
				    this._Template_FileSize = this.model.getFileSizeText(this.Template_FileSize);
			    }
			    return this._Template_FileSize;
		    }
	    });

		return Dashboards;
	});

