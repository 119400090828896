/**
 * Created by petter on 4/05/16.
 */
'use strict';
angular.module('app')
	.factory('s3factory', function ($http, filesAPI, Configuration, Exception) {

		this.credentials = {};

		this.dateForRequest = function (date) {
			if (date) {
				// return moment(new Date(date)).format('YYYY-MM-DD');
				return date;
			}
			return null;
		};


		// this.S3_SETTINGS = 'files.quartermaster.com';
		// this.API_URL = 'http://localhost:3000';

		// Public API here
		return {
			getProfilePhotoUrl: function (vesselId, componentId, uuid, filename) {
				if (uuid && filename) {
					var s3_bucket = S3_SETTINGS;
					var s3_host = S3_HOST;
					// return 'https://s3.amazonaws.com/' + s3_bucket + '/' + componentId + '/' + url.substr(0, 8) + '-' + url.substr(8, 4) + '-' + url.substr(12, 4) + '-' + url.substr(16, 4) + '-' + url.substr(20, 12) + '/' + url.substr(32);
					// return 'https://s3-us-west-1.amazonaws.com/' + s3_bucket + '/' + vesselId + '/' + componentId + '/' + uuid + '/' + filename;
					return s3_host + s3_bucket + '/' + vesselId + '/' + componentId + '/' + uuid + '/' + filename;
				}
				// return './img/default-avatar.png';
				return null;
			},
			uploadFile: function (file, vesselId, componentId, entityId, tag, expiration_date, security, success, error, progresscallback, donecallback) {
				//TODO: This method should perhaps take an object, instead of an array of parameters
				if (!vesselId) {
					throw new Error('Missing vesselId');
				}
				if (!componentId) {
					throw new Error('Missing componentId');
				}

				var filename = file.filename;
				if (!filename) {
					filename = file.name;
				}
				if (!security) {
					security = 'e';
				}
				var filesize = file.size;
	            //Check max filesize
	            if (filesize > (100 * 1024 * 1024)) {
		            if (donecallback) {
			            donecallback(file, new Error('File is too large (100 MB is max)'));
		            }
		            return;
	            }

	            //var ext = filename.split('.').pop();
				return $http.get(Configuration.API + '/files/s3-credentials/' + vesselId).then(function (response) {
					var creds = response.data;
	                var s3obj = null;
	                var options = null;
	                if (S3_HOST === 'http://localhost:9444/') {
		                AWS.config.update({
			                accessKeyId: creds.AccessKeyId,
			                secretAccessKey: creds.SecretAccessKey,
			                // sessionToken: creds.SessionToken,
		                });
		                s3obj = new AWS.S3({
			                params: {
				                Bucket: S3_SETTINGS + '/' + vesselId + '/' + componentId + '/' + creds.uuid,
			                },
			                endpoint: S3_HOST
			                // computeChecksums: true
		                });
		                options = {
			                Key: filename,
			                Body: file
		                };
		                // s3obj.setS3ClientOptions(S3ClientOptions.builder().setPathStyleAccess(true).disableChunkedEncoding().build());
	                }
					else {
		                //$log.log(creds.AccessKeyId+' '+creds.SecretAccessKey+' '+creds.SessionToken);
		                AWS.config.update({
			                accessKeyId: creds.AccessKeyId,
			                secretAccessKey: creds.SecretAccessKey,
			                sessionToken: creds.SessionToken,
			                region: 'us-west-1'
		                });
		                var acl = 'private';
		                var cache_control = 'private, max-age=86400';   //Cache for 1 day (but don't let CDNs cache (private)
		                if (tag == 'profilephoto') {
			                acl = 'public-read';
			                cache_control = 'max-age=31536000'; //Cache for 1 year
		                }
		                //$log.info('S3_SETTINGS=' + S3_SETTINGS[ENVIRONMENT]);
		                s3obj = new AWS.S3({
			                params: {
				                Bucket: S3_SETTINGS + '/' + vesselId + '/' + componentId + '/' + creds.uuid,
				                region: 'us-west-1'
			                },
			                endpoint: S3_HOST
		                });

		                options = {
			                Key: filename,
			                Body: file,
			                ServerSideEncryption: 'AES256',
			                ACL: acl,
			                CacheControl: cache_control,   //One year
		                };

		                if (filename.toLowerCase().endsWith('svg')) {
			                //Fix for SVG: content type must be explicitly set
			                options.ContentType = 'image/svg+xml';
			                options.Metadata = {};
		                }
	                }

					var request = s3obj.putObject(options);
					// var request = s3obj.putObject({ Key: filename, Body: file, ACL: acl });
					request.on('httpUploadProgress', function (progress) {
						//In order to show this it needs to be in the controller, in order to do this we need to returned abstracted out getCredentials and putObject into different methods,
						var loaded = progress.loaded;
						var total = progress.total;
						var percent = ((loaded / total) * 100);
						file.loaded = progress.loaded;
						file.total = progress.total;
						file.percent = Math.round(percent * 100) / 100;
						//console.log(file.name + ' upload progress:' + file.percent);
						//$scope.$apply();
						if (progresscallback) {
							progresscallback(file);
						}
					});
					request.send(function (err, data) {
						if (err) {
							console.error(err);
							if (donecallback) {
								donecallback(file, err);
							}
						} else {
							console.log('s3 no error');
							//if (donecallback) {
							//    donecallback(file, null);
							//}
							//$log.info(data);

							// var data = {
							//     componentId: componentId,
							//     entityId: entityId,
							//     uuid: creds.uuid,
							//     filename: filename,
							//     filesize: filesize,
							//     Container: tag,
							//     expiration_date: expiration_date,
							//     security: security
							// };

							filesAPI.save(
								{
									Files_Vessel: vesselId,
									componentId: componentId,
									entityId: entityId,
									uuid: creds.uuid,
									FileName: filename,
									filesize: filesize,
									Container: tag,
									expiration_date: expiration_date,
									security: security
								},
								function (data, status, headers, config) {
									//Success
									file.Id = data.Id;
									console.log('Saved file info: ' + filename);
									if (success) {
										success();
									}
									if (donecallback) {
										donecallback(file, null);
									}
								},
								function (data, status, headers, config) {
									//Error
									console.error('FAILED to save file info: ' + filename);
									if (error) {
										error();
									}
									else if (data) {
										error = new Error(data.data);
									}
									else {
										error = new Error("Failed save file");
									}
									if (donecallback) {
										donecallback(null, error);
									}
								});
						}
					});
					//return creds.data;
				}).catch(function (error) {
	                Exception.logError(error);
	                if (donecallback) {
		                donecallback(file, error);
	                }
	                throw error;
				});
			},
			deleteObject: function (componentId, entityId, fileId, success, err) {
				console.log('deleteObject:' + entityId);
				return filesAPI.delete({id: fileId, componentId: componentId, entityId: entityId}, success, err);
			},
			getSignedUrl: function (componentId, fileId) {
				return $http.get(Configuration.API + '/files/fileurl/' + fileId).then(function (response) {
					return response.data;
				});
			},
			listObjects: function (componentId) {
				return $http.get(Configuration.API + '/files/s3-credentials/' + componentId).then(function (response) {
					var creds = response.data;
					//$log.log(creds.AccessKeyId+' '+creds.SecretAccessKey+' '+creds.SessionToken);
					AWS.config.update({
						accessKeyId: creds.AccessKeyId,
						secretAccessKey: creds.SecretAccessKey,
						sessionToken: creds.SessionToken
					});
					var s3obj = new AWS.S3({params: {Bucket: 'app-uploads/test1', region: 'us-west-1'}});
					s3obj.listObjects({Prefix: creds.uuid}, function (err, data) {
						if (err) {
							console.error('Error: ', err);
						} else {
							console.log('no error');
							console.log(JSON.stringify(data.Contents));
						}
					});
					return creds.data;
				});
			},
			getUploads: function (componentId, entityId, result) {
				return filesAPI.query({componentId: componentId, entityId: entityId}, result);
			}
		};
	});

angular.module('app')
	.factory('filesAPI', function ($resource, Configuration) {
		return $resource(Configuration.API + '/files/:id');
		// return $resource('http://localhost:1337/api/files/:id');
	});

