/**
 * Created by petter on 2/18/16.
 */
'use strict';

angular.module('app')
	.factory('VesselModel', function ($q, Model, Vessel, s3factory, Constants, common) {
		function VesselModel() {
			Model.call(this, Vessel);
			this.types = [
				{
					Type:'Sail',
					Id:1
				},
				{
					Type:'Power',
					Id:2
				},
				{
					Type:'Tender',
					Id:3
				},
	            {
		            Type:'Catamaran',
		            Id:4
	            }
			];
		}

		VesselModel.prototype = Object.create(Model.prototype);
		VesselModel.prototype.constructor = VesselModel;

		VesselModel.prototype.load = function(id, params) {
			return Model.prototype.load.call(this, params);
		};

		VesselModel.prototype.onLoadedItem = function(item) {
			item.profileUrl = s3factory.getProfilePhotoUrl(item.Files_Vessel, Constants.FileComponent_VesselProfile, item.uuid, item.FileName);
			if (!item.profileUrl) {
				if (item.Type) {
					item.profileUrl = './img/default-yacht-'+item.Type.toLowerCase()+'.svg';
				}
				else {
					item.profileUrl = './img/default-yacht-power.svg';
				}
			}
			// item.templateUrl = s3factory.getProfilePhotoUrl(Constants.FileComponent_VesselTemplate, item.Template_uuid, item.Template_FileName);
			item.id = item.Id;
			console.log('Loaded ' + item.Name);
		};

		VesselModel.prototype.isValidItem = function(item) {
			this.isItemDirty = true;
			item.isItemDirty = true;
			if (!item.Name || !item.Vessel_VesselType){
				return false;
			}
			return true;
		};

	    VesselModel.prototype.getGridColumns = function() {
		    return [
			    common.getDefaultIdColumn(),
			    { name:'Photo', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '138',
				    cellTemplate: 'components/vessels/vessels_photo_template.html'
			    },
			    { name:'Name', field: 'Name', enableHiding:false, sort: { priority: 0, direction: 'asc' },
				    visible: !common.phone,
				    filter: { placeholder: 'search name...'} },
			    { name:'Type', field: 'Type', visible: !common.phoneplus, enableHiding:true, filter: { placeholder: 'search type...'} },
			    { name:'Manufacturer', field: 'Manufacturer', visible: !common.phoneplus, enableHiding:true, filter: { placeholder: 'search manufacturer...'} },
			    { name:'Model', field: 'Model', visible: !common.mobile, enableHiding:true, filter: { placeholder: 'search model...'} },
			    common.getSystemModified(),common.getSystemModifiedBy(),
			    { name:'Actions', width: '220', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false,
				    cellTemplate: 'components/vessels/vessels_action_template.html'
			    }
		    ];
	    };

		return VesselModel;
	});

angular.module('app')
	.factory('Vessel', function(ItemBase, Configuration) {
		function Vessel() {
			ItemBase.call(this);
			// this.profileUrl = './img/default-avatar.png';
		}

		Vessel.prototype = Object.create(ItemBase.prototype);

		Vessel.dataColumns = ['Name', 'Vessel_User', 'Vessel_VesselType', 'Vessel_File', 'Vessel_TemplateFile','Manufacturer', 'Model'];
		Vessel.templateUrl = 'components/vessels/vessels_edit.html';
		Vessel.url = Configuration.API + '/vessels/:id';

		// Object.defineProperty(Vessel.prototype, 'Type', {
		//     get: function () {
		//         return this.Vessel_VesselType;
		//     },
		//     set: function (value) {
		//         // this.isItemDirty = true;
		//         return this.Vessel_VesselType = value;
		//     }
		// });

		return Vessel;
	});

