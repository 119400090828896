/**
 * Created by petter on 2/18/16.
 */
'use strict';
angular.module('app')
	.factory('$exceptionHandler', function() {
		return function myExceptionHandler(exception, cause) {
			console.error(exception);
			try {
				if (window.appInsights) {
					window.appInsights.trackException(exception);
				}
				if (window.Sentry) {
					window.Sentry.captureException(exception);
				}
			}
			catch (trackError) {
				console.error(new Error("Failed to capture error"));
			}
		};
	})
	.provider('Exception', function () {

		var Exception = function ($uibModal, $location, common) {
			var exception = this;
			this.common = common;

			this.handleError = function (err, deferred, generalMessage) {
				if (deferred) {
					deferred.reject(err);
				}
				if (err) {
					if (typeof err === 'string') {
						err = new Error(err);
					}
					if (err.handled) {
						return;
					}
					// err.handled = true;
					err.generalMessage = generalMessage;

					this.errorFn(err);
				}
				else {
					err = new Error('handleError called, but no error was found to process.');
					console.error(err.message);
					this.errorFn(err);
				}
			};

			this.logError = function (err) {
				console.error(err);
				this.common.trackException(err);
			};

			this.errorFn = function (err) {
				try {
					this.common.hideWorking();
					if (typeof err === 'string') {
						err = new Error(err);
					}
					if (err.handled) {
						return;
					}
					err.handled = true;
					this.logError(err);

					if (!err.statusText) {
						err.statusText = 'Error';
					}
					if (!err.message) {
						if (!err.data && (err.status === -1)) {
							err.message = err.data;
						}
						{
							err.message = 'Network error';
						}
					}
					// if (!err.message) {
					//     err.message = 'Unknown error.  Please refresh your browser.';
					// }
					if (err.status && (err.status === 401)) {
						console.error(err.message);
						// $location.path('/home');
					}
					else if (err.message === 'An error occurred with our connection to Stripe') {
						//We have a problem connecting to Stripe.  Just write it to console, do not show error dialog.
						console.error(err.message);
					}
					else if (err.message) {
						// Log the error information to the console.
						console.error(err.message);
						switch(err.message) {
						case "The Internet connection appears to be offline." :
						case "The network connection was lost." :
						case "The request timed out." :
							err.generalMessage = "Network connection lost";
							break;
						}

						if (err.generalMessage) {
							// if (location.hostname === 'localhost') {
							if (location.hostname === 'quartermaster.me') {
								err.message = err.generalMessage;
							}
							else {
								err.message = err.generalMessage + ':' + err.message;
							}
						}

						var modalInstance = $uibModal.open({
							templateUrl: "core/errorModal.html",
							controller: "ErrorModalController",
							resolve: {
								err: function () {
									return err;
								}
							}
						});
					}
					else {
						// if (window.Sentry) {
						//     window.Sentry.showReportDialog();
						// }
						// else {
						//     alert('An unknown error has occurred, please refresh your browser.  If the problem persists, please contact the Quartermaster Team.');
						// }
						alert('An unknown error has occurred, please refresh your browser.  If the problem persists, please contact the Quartermaster Team.');
					}
				}
				catch (intError) {
					console.error(err);
					console.warn('Failed to capture exception: ' + intError.message + '. If problem persists, please contact team@quartermaster.me');
					//TODO: We need a solid backup failover for the case where we're offline or some other critical failure occurs.  Raven fails to show message in offline mode.
					alert('An unknown error has occurred, please refresh your browser.  If the problem persists, please contact the Quartermaster Team.');
				}
			};
		};

		this.$get = function ($uibModal, $location, common) {
			return new Exception($uibModal, $location, common);
		};

	});
