angular.module('app')
	.controller('SystemExpensesController', function ($scope, SystemModel, SystemExpenseModel, $rootScope, $location , Exception, $q, uiGridConstants, qm, $routeParams, Constants) {
		$rootScope.bodyClass = "expense";
		$scope.qm = qm;
		$scope.model = new SystemExpenseModel();
		$scope.systemModel = new SystemModel();
		$scope.grid = {};
		$scope.grid.appScope = $scope;

		$scope.setGridOptions = function() {
			$scope.gridOptions = {
	                enableGridMenu: true,
	                gridMenuShowHideColumns: false,
	                exporterSuppressColumns: ['Id', 'Actions'],
	                exporterMenuCsv: true,
	                exporterCsvFilename: 'Quartermaster-Services-Log.csv',
	                exporterMenuExcel: false,
	                exporterMenuPdf: false,
	                // exporterPdfFilename: 'Quartermaster-Logs.pdf',
	                // exporterPdfDefaultStyle: {fontSize: 9},
	                // exporterPdfTableStyle: {margin: [30, 30, 30, 30]},
	                // exporterPdfTableHeaderStyle: {fontSize: 10, bold: true, italics: true, color: 'blue'},
	                // exporterPdfHeader: { text: "Ships", style: 'headerStyle' },
	                // exporterPdfFooter: function ( currentPage, pageCount ) {
		             //    return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
	                // },
	                // exporterPdfCustomFormatter: function ( docDefinition ) {
		             //    docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
		             //    docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
		             //    return docDefinition;
	                // },
	                // exporterPdfOrientation: 'portrait',
	                // exporterPdfPageSize: 'LETTER',
	                // exporterPdfMaxGridWidth: 500,

				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: true,
				enableColumnResizing: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				// paginationPageSizes: [3, 5, 10, 25, 50, 75, 100],
				// paginationPageSize: 5,
				columnDefs: $scope.model.getGridColumns(),
				data: 'model.items',
				tabs: [],
	                importerShowMenu: false,
	                importerDataAddCallback: function (grid, newObjects) {},
	                onRegisterApi: function( gridApi ) {
					$scope.gridApi = gridApi;
				}
			};            };
		$scope.setGridOptions();

		$scope.$on('screen-changed', function(event, args) {
			$scope.gridOptions.columnDefs = $scope.model.getGridColumns();
			$scope.gridApi.core.refresh();
		});

		$scope.load = function() {
	            var deferred = $q.defer();
	            if (qm.selectedVessel.Id) {
		            $scope.model.load(qm.selectedVessel.Id).then(function (result) {
			            console.log('Loaded expense items:' + $scope.model.items.length);
			            deferred.resolve(result);
		            }, function (error) {
			            Exception.errorFn(error);
		            });
	            }
	            else {
		            deferred.resolve(null);
	            }
	            return deferred.promise;
		};

		$scope.$watch('qm.selectedVessel', function () {
			if (qm.selectedVessel) {
				$scope.load();
			}
		});

		$scope.getFilesUrl = function(row) {
			return '/systems/'+row.entity.Id+'/services/files/'+Constants.FileComponent_SystemService;
		};
	});
