/**
 * Created by petter on 5/22/19.
 */
// gridutil helps create grid objects for use in the grid
angular.module('app')
	.controller('GridBaseController', function ($rootScope, $scope, $window, $timeout, $location, common, localStorageService, Exception) {
		// console.log('created gridutil');

		$scope.loadWhenLoggedIn = function(callback) {
			common.showWorking();
			$scope.loadWhenLoggedInDone = false;
			var loadWhenLoggedInCallbackMethod = function () {
				$scope.loadWhenLoggedInDone = true;
				callback();
				common.hideWorking();
			};
			if ($rootScope.isAuthenticated) {
				//If authenticated, load
				if (!$scope.loadWhenLoggedInDone) {
					loadWhenLoggedInCallbackMethod();
				}
			}
			else {
				//Otherwise, wait for userProfileSet broadcast before calling load
				$scope.$on('userProfileSet', function(event, args) {
					if (!$scope.loadWhenLoggedInDone) {
						loadWhenLoggedInCallbackMethod();
					}
				});
			}
		};

		$scope.grid = {};
		$scope.grid.appScope = $scope;

		$scope.createGrid = function (model, id, detailRoute, prefilter, presort, rowHeight) {

			var gridContainer = {};

			gridContainer.model = model;
			gridContainer.prefilter = prefilter;
			gridContainer.count = 0;
			gridContainer.filter = null;
			gridContainer.sort = presort;
			gridContainer.isRestoringState = false;

			var base_path = $location.$$path.split('/');
			base_path = base_path[1];

			if (!rowHeight) {
				rowHeight = 30;
			}
			if (!id) {
				throw new Error('Missing id');
			}
			gridContainer.gridStateName = 'gridState_' + base_path + '_' + id;

			gridContainer.paginationOptions = {
				pageNumber: 1,
				sort: null
			};

			gridContainer.gridOptions = {
				saveScroll: true,
				saveFilter: true,
				saveFocus: true,
				saveWidths: false,
				saveOrder: false,
				savePagination: true,
				saveSelection: false,
				enableGridMenu: true,
				// enableSelectAll: true,
				exporterCsvFilename: 'Quartermaster-'+detailRoute+'.csv',
				exporterMenuAllData : false,
				exporterMenuVisibleData : true,
				exporterMenuPdf: false,
				exporterMenuExcel: false,
				exporterPdfDefaultStyle: {fontSize: 9},
				exporterPdfTableStyle: {margin: [30, 30, 30, 30]},
				exporterPdfTableHeaderStyle: {fontSize: 10, bold: true, italics: true, color: 'blue'},
				exporterPdfHeader: { text: detailRoute, style: 'headerStyle' },
				exporterPdfFooter: function ( currentPage, pageCount ) {
					return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
				},
				exporterPdfCustomFormatter: function ( docDefinition ) {
					docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
					docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
					return docDefinition;
				},
				exporterPdfOrientation: 'portrait',
				exporterPdfPageSize: 'LETTER',
				exporterPdfMaxGridWidth: 500,
				exporterCsvLinkElement: angular.element(document.querySelectorAll(".custom-csv-link-location")),

	            importerShowMenu: false,
	            importerDataAddCallback: function (grid, newObjects) {},
				showColumnFooter: false,
				enableRowSelection: false,
				enableRowHeaderSelection: false,
				multiSelect: false,
				enableSorting: true,
				enableFiltering: true,
	            enableColumnMenus: false,
				enableColumnResizing: true,
				enableColumnReordering: true,
				enableColumnMoving: true,
				enablePaginationControls: false,
				useExternalPagination: false,
				useExternalSorting: false,
				useExternalFiltering: false,
				rowHeight: rowHeight,
				columnDefs: gridContainer.model.getGridColumns(),
				// gridMenuCustomItems: [   //Only reset sort, but don't know how to reset order or column size yet (tell user to refresh browser page to really reset)
				//     {
				//         title: 'Reset columns',
				//         action: function ($event) {
				//             // this.grid.element.toggleClass('rotated');
				//             localStorageService.set(gridContainer.gridStateName, null);
				//
				//             gridContainer.gridOptions.columnDefs = gridContainer.model.getGridColumns();
				//             gridContainer.gridApi.grid.resetColumnSorting();
				//             // gridContainer.gridApi.grid.buildColumns();
				//             $timeout(function() {
				//                 gridContainer.gridApi.core.refresh();
				//                 // gridContainer.saveState();
				//             }, 1);
				//         },
				//         order: 110
				//     }
				// ],
				exporterSuppressColumns: ['Id', 'Actions', 'Photo', 'Template'],
				exporterFieldCallback: function ( grid, row, col, value ) {
					if (gridContainer.tracked_export != true) {
						gridContainer.tracked_export = true;
						common.trackEvent('Interaction', 'ExportVisibleData');
					}
					return value;
				},
				onRegisterApi: function( gridApi ) {
					gridContainer.gridApi = gridApi;
					// $timeout(function() {
					//     gridContainer.isRestoringState = true;
					//     gridContainer.calculateFitGridHeight();
					//     gridContainer.restoreState();
					//
					//     $timeout(function() {
					//         gridContainer.calculateFitGridHeight();
					//         gridContainer.isRestoringState = false;
					//         if (gridContainer.model.items.length === 0) {
					//             gridContainer.load();
					//         }
					//     }, 1);
					//
					// }, 1);
				}
			};

			$scope.$on('screen-changed', function(event, args) {
				$timeout(function() {
					if (gridContainer.gridApi) {
						gridContainer.gridOptions.columnDefs = gridContainer.model.getGridColumns();
	                    gridContainer.gridApi.core.refresh();
					}
				}, 1);
			});

			return gridContainer;
		};

	});
