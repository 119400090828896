/**
 * Created by petter on 2/18/16.
 */
angular.module('app')
	.directive('grid', function (screenSize) {
		return {
			restrict: 'E',
			scope: {
				gridOptions: '=options',
				id: '=id',
				items: '=items',
				tabs: '=tabs',
				filterObject: '=filterObject',
				orderBy: '=orderBy'
			},
			templateUrl: 'core/grid/tabs.html',
			controller: ['$scope', 'screenSize', function($scope, screenSize) {
				$scope.grid = {};
				$scope.grid.appScope = $scope.$parent;
				$scope.uiGridOptions = $scope.gridOptions;
				$scope.uiGridOptions.data = 'items | filter:getFilterExpression(tab)';
				$scope.uiGridOptions.appScopeProvider = $scope.$parent;
				angular.forEach($scope.uiGridOptions.columnDefs, function(columnDef) {
					if (columnDef.hintTemplate) {
						columnDef.headerCellTemplate = 'app/core/grid/cellheader_template.html';
					}
				});
				$scope.loading = true;

				//Default grid options override
				$scope.uiGridOptions.enableHorizontalScrollbar = 0; /* NEVER */
				$scope.uiGridOptions.enableVerticalScrollbar = 0; /* NEVER */


				//if (!$scope.gridCounter) {
				//    $scope.gridCounter = 0;
				//}
				//else {
				//    $scope.gridCounter = $scope.gridCounter + 1;
				//}
				//Set id for this grid instance, so we can identify the grid separately
				if ($scope.id) {
					$scope.gridId = '_' + $scope.id.toString();
				}
				else {
					$scope.gridId = '_na';
				}

				// Using dynamic method `on`, which will set the variables initially and then update the variable on window resize
				$scope.desktop = screenSize.on('md, lg', function(match){
					$scope.desktop = match;
				});
				$scope.mobile = screenSize.on('xs, sm', function(match){
					$scope.mobile = match;
				});

				$scope.getId = function(tab) {
					var _tab = tab;
					if (tab) {
						var tabName = $scope.gridId + tab.name;
						return tabName;
					}
					else {
						var tabName = $scope.gridId;
						return tabName;
					}
				};

				$scope.getFilterExpression = function(tab) {
					var _tab = tab;
					if (tab) {
						return function(item) {
							if (_tab.field && _tab.filter) {
								return (item[_tab.field] === _tab.filter);
							}
							else {
								return true;
							}
							//return (item.status === _tab.filter);
						};
					} else if($scope.filterObject){
						return $scope.filterObject;
					}
					else {
						return function(item) {
							return true;
						};
					}
				};

				$scope.getOrderExpression = function() {
					if ($scope.gridOptions.orderBy) {
						return $scope.gridOptions.orderBy;
					} else {
						return function(item) {
							return true;
						};
					}
				};

				$scope.getTabItems = function() {
					if ($scope.gridOptions.tabData) {
						var dataSource = $scope.gridOptions.tabData;
						if (dataSource.indexOf(".") > -1) {
							//return 'two part';
							var fieldParts = dataSource.split('.', 2);
							var part1 = $scope.grid.appScope[fieldParts[0]];
							if (part1) {
								$scope.tabItems = part1[fieldParts[1]];
							}
							else {
								console.log('Failed to get second part of tabData');
							}
						}
						else {
							$scope.tabItems =  $scope.grid.appScope[dataSource];
						}
					}
					else {
						return 'no fieldName';
					}
				};
				$scope.getTabItems();

				$scope.getColumnValue = function(item, columnDef) {
					if (!item.entity) {
						item.entity = _.copy(item);
					}
					var fieldName = columnDef.field;
					if (fieldName) {
						var length = fieldName.length;
						if (fieldName.indexOf(".") > -1) {
							//return 'two part';
							var fieldParts = fieldName.split('.', 2);
							var part1 = item[fieldParts[0]];
							if (part1) {
								return part1[fieldParts[1]];
							}
							else {
								return '';
							}
						}
						return item[columnDef.field];
					}
					else {
						return 'no fieldName';
					}
				};

				$scope.getTableHeight = function() {
					var rowHeight = $scope.uiGridOptions.rowHeight; // your row height
					var headerHeight = $scope.uiGridOptions.headerRowHeight; // your header height
					headerHeight += 37;
					return {
						height: ($scope.items.length * rowHeight + headerHeight) + "px"
					};
				};
			}],
		};
	});
