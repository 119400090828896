/**
 * Created by petterbergman on 2/27/17.
 */
angular.module('app')
	.controller('AccountController', function ($scope, $rootScope, $q, qm, common, AccountSubscriptionModel, StripePaymentModel, StripeChargesModel, StripeCouponsModel, Model, Configuration, authService, Exception) {
		$rootScope.bodyClass = "service";
		$scope.qm = qm;
		$scope.model = new StripePaymentModel();
		$scope.grid = {};
		$scope.grid.appScope = $scope;
		$scope.charges = [];
		$scope.stripeChargesModel = new StripeChargesModel();
		$scope.stripeCancelSubscriptionModel = new Model(null, Configuration.API + '/stripe_cancel_subscription/:id');
		$scope.stripeCancelSubscriptionModel.refreshItem = function(responseData) {
			//Create a no-op refreshItem method to account for missing GET in API
			//TODO: Fix this.  Perhaps we shouldn't allow new Model's to get created with just a URL
			var deferred = $q.defer();
			deferred.resolve(responseData);
			return deferred.promise;
		};
		$scope.stripeUpdateCardModel = new Model(null, Configuration.API + '/stripe_update_card/:id');
		$scope.stripeUpdateCardModel.refreshItem = function(responseData) {
			//Create a no-op refreshItem method to account for missing GET in API
			//TODO: Fix this.  Perhaps we shouldn't allow new Model's to get created with just a URL
			var deferred = $q.defer();
			deferred.resolve(responseData);
			return deferred.promise;
		};
		$scope.stripeCouponModel = new StripeCouponsModel();
		$scope.accountSubscription = new AccountSubscriptionModel();

		$scope.userProfile = $rootScope.authService.userProfile;
		$scope.coupon = localStorage.getItem('coupon');

		$scope.getGridColumns = function() {
			return [
				$scope.qm.getDefaultIdColumn(),
				{ name:'created', field: 'created', enableHiding:false, cellTemplate: 'components/account/account_charges_created_template.html' },
				{ name:'amount', field: 'amount', enableHiding:false, cellTemplate: 'components/account/account_charges_amount_template.html' },
				{ name:'last 4 card#', field: 'source.last4', enableHiding:false }
			];
		};

		$scope.setGridOptions = function() {
			$scope.gridOptions = {
				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: true,
				enableColumnResizing: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				// paginationPageSizes: [3, 5, 10, 25, 50, 75, 100],
				// paginationPageSize: 5,
				columnDefs: $scope.getGridColumns(),
				data: '$scope.charges',
				tabs: [],
				importerShowMenu: false,
				importerDataAddCallback: function (grid, newObjects) {},
				onRegisterApi: function( gridApi ) {
					$scope.gridApi = gridApi;
				}
			};            };
		$scope.setGridOptions();

		// $scope.$on('screen-changed', function(event, args) {
		//     $scope.gridOptions.columnDefs = $scope.getGridColumns();
		//     $scope.gridApi.core.refresh();
		// });

		if (window.StripeCheckout) {
			var handler = StripeCheckout.configure({
				key: STRIPE_CLIENT_KEY,
				// image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
				locale: 'auto',
				token: function(token) {
					// You can access the token ID with `token.id`.
					// Get the token ID to your server-side code for use.
					console.log('token: ' + token);
					$scope.model.saveItem({token: token, subscription: $scope.subscription, coupon: $scope.coupon}).then(function (result) {
						common.trackEvent('Funnel','SubscriptionSaved', $scope.coupon);
						$scope.load();
					});
				}
			});
		}

		var addSubscriptionHandler = function(elementId, planId, subscriptionName, subscriptionDescription, amount) {
			var promoAmount = amount;
			if ($scope.stripeCoupon) {
				if ($scope.stripeCoupon.amount_off) {
					promoAmount = amount - $scope.stripeCoupon.amount_off;
				}
				else if ($scope.stripeCoupon.percent_off) {
					promoAmount = amount - (amount * $scope.stripeCoupon.percent_off / 100);
				}
				if (promoAmount < 0) {
					promoAmount = 0;
				}
			}
			document.getElementById(elementId).addEventListener('click', function(e) {
				// Open Checkout with further options:
				$scope.subscription = {
					name:subscriptionName,
					planId: planId,
					amount: amount
				};
				handler.open({
					name: subscriptionName,
					description: subscriptionDescription,
					amount: promoAmount,
					email: authService.userProfile.email,
					image: 'img/logo-email.png',
					allowRememberMe:false,
					zipCode:true,
					planId: planId
					// label: 'Subscribe {{amount}}'
				});
				e.preventDefault();
				common.trackEvent('ClickSubscribe', null, planId);
			});
		};

		if (window.StripeCheckout) {
			var updateCardHandler = StripeCheckout.configure({
				key: STRIPE_CLIENT_KEY,
				// image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
				// locale: 'auto',
				token: function (token) {
					// You can access the token ID with `token.id`.
					// Get the token ID to your server-side code for use.
					console.log('token: ' + token);
					$scope.stripeUpdateCardModel.saveItem({
						token: token,
						subscription: null
					}).then(function (result) {
						$scope.load();
					});
				}
			});
		}

		var addUpdateCardHandler = function(elementId) {
			document.getElementById(elementId).addEventListener('click', function(e) {
				// Open Checkout with update card options:
				updateCardHandler.open({
					name: 'Quartermaster',
					description: 'New Card Details',
					email: authService.userProfile.email,
					image: 'img/logo-email.png',
					allowRememberMe:false,
					zipCode:true,
					panelLabel: 'Update Card Details'
				});
				e.preventDefault();
			});
		};
		addUpdateCardHandler('stripeUpdateCardButton');

		// Close Checkout on page navigation:
		window.addEventListener('popstate', function() {
			if (handler) {
				handler.close();
			}
		});

		$scope.loadCoupon = function() {
			$scope.stripeCouponModel.load({coupon:$scope.coupon}).then(function(result) {
				if (result.length > 0) {
					//We have a coupon
					if (result[0].valid) {
						//If valid coupon
						$scope.stripeCoupon = result[0];
						var couponDescription = null;
						if ($scope.stripeCoupon.percent_off) {
							couponDescription = $scope.stripeCoupon.percent_off + '% off';
							if ($scope.stripeCoupon.duration_in_months) {
								couponDescription = couponDescription + ' for ' + $scope.stripeCoupon.duration_in_months + ' months';
							}
						}
						$scope.stripeCoupon.couponDescription = couponDescription;
					}
					else {
						//Invalid coupon (probably expired)
						$scope.stripeCoupon = null;
						localStorage.removeItem('coupon');
					}
				}
				else {
					//No coupon
					$scope.stripeCoupon = null;
					localStorage.removeItem('coupon');
				}
				addSubscriptionHandler('stripeOwnerMontlySubscriptionButton', '2017FebOwnerMonthly','Owner', '$1.99 / month', 199);
				addSubscriptionHandler('stripeOwnerYearlySubscriptionButton', '2017FebOwnerYearly', 'Owner', '$14.99 / year', 1499);

				addSubscriptionHandler('stripeCaptainMontlySubscriptionButton', '2017FebCaptainMonthly', 'Captain', '$3.99 / month', 399);
				addSubscriptionHandler('stripeCaptainYearlySubscriptionButton', '2017FebCaptainYearly', 'Captain', '$29.99 / year', 2999);

				addSubscriptionHandler('stripeFleetMontlySubscriptionButton', '2017FebFleetMonthly', 'Fleet', '$19.99 / month', 1999);
				addSubscriptionHandler('stripeFleetYearlySubscriptionButton', '2017FebFleetYearly', 'Fleet', '$149.99 / year', 14999);

			}, function (error) {
				console.error(error);
				// Exception.errorFn(error);
			});
		};

		$scope.load = function() {
			var deferred = $q.defer();
			$scope.accountSubscription.getItem(0).then(function(accountStorage) {
				$scope.accountStorage = accountStorage;
				if (accountStorage.subscription && accountStorage.subscription.plan) {
					$scope.model.load().then(function(result) {
						if (result.length > 0) {
							//We have a subscription
							$scope.hasIAP = false;
							$scope.hasSubscription = true;
							$scope.showSignup = false;
							$scope.subscription = result[0];
							//Load charges
							$scope.stripeChargesModel.load({stripe_customer_id:$scope.subscription.customer}).then(function(charges) {
								console.log(charges);
								$scope.charges = charges;
							}, function (error) {
								// Exception.errorFn(error);
							});
						}
						else {
							//No subscription
							$scope.hasIAP = false;
							$scope.hasSubscription = false;
							$scope.showSignup = true;
							$scope.loadCoupon();
						}
						if ($scope.subscription.plan === null) {
							//Special case for Fleet with add-ons. $scope.subscription is set to first result of charges, but plan there is null.
							$scope.subscription.plan = accountStorage.subscription.plan;
						}
						deferred.resolve();
					}, function (error) {
						// Exception.errorFn(error);
						common.hideWorking();
						deferred.resolve(null);
					});
				}
				else if (accountStorage.iap) {
					$scope.hasIAP = true;
					$scope.hasSubscription = false;
					$scope.showSignup = false;
					var subscriptionText = 'Unknown plan';
					var isMonthly = false;
					if (accountStorage.iap.productId.indexOf('Monthly') > 0) {
						isMonthly = true;
					}
					if (accountStorage.iap.productId.indexOf('Owner') > 0) {
						subscriptionText = 'Owner';
					}
					else if (accountStorage.iap.productId.indexOf('Captain') > 0) {
						subscriptionText = 'Captain';
					}
					else if (accountStorage.iap.productId.indexOf('Fleet') > 0) {
						subscriptionText = 'Fleet';
					}
					if (isMonthly) {
						$scope.subscriptionText = subscriptionText + ' (monthly)';
					}
					else {
						$scope.subscriptionText = subscriptionText + ' (yearly)';
					}
					$scope.subscriptionStatus = 'Active';
					common.hideWorking();
					deferred.resolve();
				}
				else if (accountStorage.subscription && (accountStorage.subscription.status === 'active')) {
					$scope.hasIAP = true;
					$scope.hasSubscription = false;
					$scope.showSignup = true;
					$scope.subscriptionText = accountStorage.account.productId;
					$scope.subscriptionStatus = 'Active';
					common.hideWorking();
					deferred.resolve();


				}
				else {
					//No subscription
					$scope.hasIAP = false;
					$scope.hasSubscription = false;
					$scope.showSignup = true;
					$scope.loadCoupon();
					common.hideWorking();
					deferred.resolve();
				}
			}, function (error) {
				Exception.errorFn(error);
				deferred.resolve(null);
			});
			return deferred.promise;
		};

		$scope.load();

		$scope.cancelSubsription = function () {
			// var data = angular.extend({}, $scope.subscription);
			// data.model = null;
			$scope.stripeCancelSubscriptionModel.saveItem({subscription: {id: $scope.subscription.id}}).then(function() {
				$scope.load();
			});
		};

	});
