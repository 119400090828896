/**
 * Created by petter on 2/18/16.
 */
'use strict';
angular.module('app')
	.factory('ItemBase', function() {
		function ItemBase() {
			this.index = -1;
			this.columns = [];
			this.isItemDirty = false;
		}

		ItemBase.isValidItem = function() {
			this.isItemDirty = true;
			return true;
		};

		ItemBase.prototype.notifyItemChanged = function(property) {
			// console.log('notifyItemChanged');
			if (this.model && this.model.onItemChanged) {
				if ((this.model.isLoading === false) && (this.model.isLoaded === true)) {
					this.model.onItemChanged(this, property);
				}
			}
		};

		return ItemBase;
	});
