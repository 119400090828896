angular.module('app')
	.controller('FilesController', function ($scope, $controller, Model, FileModel, SystemModel, SystemServiceModel, $rootScope, $location , Exception, $q, uiGridConstants, qm, s3factory, $routeParams, $window, $timeout, Constants, Configuration) {
		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));
		angular.extend(this, $controller('GridBaseController', {$scope: $scope}));

		$rootScope.bodyClass = "files";
		$scope.qm = qm;
		$scope.model = new FileModel();
		$scope.systemModel = new SystemModel();
		$scope.systemServiceModel = new SystemServiceModel();
		$scope.uploads = [];
		$scope.uploaderrors = [];
		// $scope.componentId = 1;
		$scope.componentId = $routeParams.component_id;
		$scope.entityId = $routeParams.entity_id;
		$scope.accountStorageModel = new Model(function AccountStorageModel() {}, Configuration.API + '/account_storage/:id');
		$scope.accountStorage = {
			value: 0,
			max: 1 * 1024 * 1024 * 1024,
			percent: 0,
			overLimit: true
		};

		$scope.grid = $scope.createGrid($scope.model, 'files', 'Files', null, null, null);

		$scope.load = function() {
	            var deferred = $q.defer();
	            $scope.accountStorageModel.getItem(qm.selectedVessel.Id).then(function(accountStorage) {
				// console.log(accountStorage);
				if (accountStorage.storage) {
					$scope.accountStorage.value = accountStorage.storage.bytes_used;
				}
				if (accountStorage.account) {
	                    var max_storage_gb = 1;
	                    if (accountStorage.account.productId) {
		                    switch (accountStorage.account.productId) {
			                    case 'Apple2020JulyOwnerMonthly':
				                    max_storage_gb = 1;
				                    break;
			                    case 'Apple2020JulyCaptainMonthly':
				                    max_storage_gb = 5;
				                    break;
			                    case 'Apple2020JulyFleetMonthly':
				                    max_storage_gb = 20;
				                    break;
			                    case 'Stripe2017FebOwnerMonthly':
			                    case 'Stripe2017FebOwnerYearly':
				                    max_storage_gb = 1;
				                    break;
			                    case 'Stripe2017FebCaptainMonthly':
			                    case 'Stripe2017FebCaptainYearly':
				                    max_storage_gb = 5;
				                    break;
			                    case 'Stripe2017FebFleetMonthly':
			                    case 'Stripe2017FebFleetYearly':
				                    max_storage_gb = 20;
				                    break;
		                    }
	                    }
	                    else if (accountStorage.account.StripeProductId) {
		                    switch (accountStorage.account.StripeProductId) {
			                    case '2017FebOwnerMonthly':
			                    case '2017FebOwnerYearly':
				                    max_storage_gb = 1;
				                    break;
			                    case '2017FebCaptainMonthly':
			                    case '2017FebCaptainYearly':
				                    max_storage_gb = 5;
				                    break;
			                    case '2017FebFleetMonthly':
			                    case '2017FebFleetYearly':
				                    max_storage_gb = 20;
				                    break;
		                    }
	                    }
	                    else if (accountStorage.subscription && accountStorage.subscription.plan.metadata.max_storage_gb) {
		                    max_storage_gb = parseInt(accountStorage.subscription.plan.metadata.max_storage_gb, 10);
		                    $scope.accountStorage.max = max_storage_gb * 1024 * 1024 * 1024;
	                    }
	                    $scope.accountStorage.max = max_storage_gb * 1024 * 1024 * 1024;
				}
				$scope.accountStorage.usedText = $scope.model.getFileSizeText($scope.accountStorage.value);
				$scope.accountStorage.availableText = $scope.model.getFileSizeText($scope.accountStorage.max);
				if ($scope.accountStorage.value < $scope.accountStorage.max) {
					$scope.accountStorage.overLimit = false;
				}

				var percent = $scope.accountStorage.value * 100 / $scope.accountStorage.max;
				$scope.accountStorage.percent = Math.round(percent * 100) / 100;
				$scope.accountStorage.loaded = true;
			}, function (error) {
				// Exception.errorFn(error);
			});

			var entity_id = $scope.entityId;
			if (!entity_id) {
				entity_id = qm.selectedVessel.Id;
			}
			if ($scope.componentId === Constants.FileComponent_System.toString()) {
				$scope.systemModel.getItem($scope.entityId).then(function(result) {
					$scope.system = result;
				}, function (error) {
					Exception.errorFn(error);
				});
			}
			else if ($scope.componentId === Constants.FileComponent_SystemService.toString()) {
				$scope.systemServiceModel.getItem($scope.entityId).then(function(result) {
					$scope.systemService = result;
				}, function (error) {
					Exception.errorFn(error);
				});
			}
			$scope.model.load($scope.componentId, entity_id).then(function(result) {
	                deferred.resolve(result);
			}, function (error) {
	                deferred.reject(error);
	                Exception.errorFn(error);
			});
	            return deferred.promise;
		};

		$scope.$watch('qm.selectedVessel', function () {
			if (qm.selectedVessel) {
				$scope.load();
			}
		});

		$scope.uploadDragDrop = function (files) {
			if (files && files.length) {
				for(var j = 0; j<files.length; j++) {
					files[j].filename = files[j].name;
				}

				for(var i = 0; i<files.length; i++) {
					var file = files[i];
					var ext = file.name.split('.').pop();
					var tag = file.tag;
					var expiration_date = file.expiration_date;
					var security = file.security;

					file.progress = 0;
					file.total = 100;
					file.loaded = 0;
					$scope.uploads.push(file);

					$scope.uploadFileToUrl(file, tag, expiration_date, security, ext,
						function(progress) {
							//Progress
							console.log('upload progress:' + file.percent);
							$timeout(function() {
								$scope.$apply();
							}, 1);
						},
						function(result, err) {
							//Done
							// $log.info(result.name + ' upload complete');
							if (err) {
								result.err = err;
								if (err.data) {
	                                    $scope.uploaderrors.push({err:err.data});
								}
								else {
	                                    $scope.uploaderrors.push({err: err});
								}
								file.err = err;
								$timeout(function() {
									$scope.$apply();
								}, 1);
								$timeout(function() {
									$scope.uploaderrors.length = 0;
									$scope.$apply();
								}, 15000);
							}
							var index = $scope.uploads.indexOf(result);
							if (index > -1) {
								$timeout(function() {
									console.log(result.name + ' removed');
									//Re-calc index, because we're time shifting
									var index = $scope.uploads.indexOf(result);
									if (index > -1) {
										$scope.uploads.splice(index, 1);
										$timeout(function() {
											$scope.$apply();
										}, 1);
									}
								}, 5000);
							}
						});
				}
			}
		};

		$scope.uploadFileToUrl = function (file, tag, expiration_date, security, ext, progresscallback, donecallback) {
			// var user_id = me.getUserId();
			// var user_id = null;
			//var typename = fileContainer.type;
			var fd = new FormData();
			fd.append('file', file);
			//var tag = fileContainer.type;

			try {
				s3factory.uploadFile(file, $scope.qm.selectedVessel.Id, $scope.componentId, $scope.entityId, tag, expiration_date, security,
					function (data, status, headers, config) {
						//Success
						$scope.load();
						console.log('s3 upload done');
						//_this.loadUploads();
					},
					function (data, status, headers, config) {
						//Error
						console.log('s3 upload failed');
					},
					progresscallback,
					donecallback
				).then(function (result) {
					// $log.info(result);
					return result;
				}).then(function (data) {
					console.log('s3 upload done');
				}).catch(function (error) {
	                    Exception.logError(error);
				});
			}
			catch (err) {
	                Exception.logError(err);
				throw err;
			}
		};

		$scope.getFileLink = function(item) {
			s3factory.getSignedUrl($scope.componentId, item.id).then(function(response){
				$window.location.href = response;
			});
		};

		$scope.getParentLink = function(item) {
			if (item.componentId === Constants.FileComponent_System) {
	                $rootScope.navigateTo("/systems/"+item.entityId+'/files/5');
			}
			else if (item.componentId === Constants.FileComponent_SystemService) {
	                $rootScope.navigateTo("/systems/"+item.entityId+'/services/files/6');
			}
		};

		$scope.clickUpload = function(){
			// var dragdropElement = angular.element('#dragdrop');
	            var dragdropElement = document.getElementById('dragdrop');
	            // dragdropElement.trigger('click');
	            dragdropElement.click();
		};

		//TODO: Experimental: Reload thumbnail (in cache) and technically the browser should load it from cache
		$scope.loadThumbnail = function(source, entity) {
            	console.log('loadThumbnail');
	            var temp = new Image();
	            temp.src = entity.thumbnail;
	            // $scope.$apply();
	            // source.src = entity.thumbnail;
	            //Reload image works, but browser doesn't show it
		};

		$scope.listenForChanges('Files');

	});
