angular.module('app')
	.controller('StorageItemsController', function ($scope, SystemModel, StorageItems, StorageItemsModel, StorageModel, $rootScope, $location, Exception, $q, uiGridConstants, qm, $routeParams, profilePhotoModel, $uibModal, Constants,
	                                                $interval, common, $controller) {
		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));
		$rootScope.bodyClass = "storageitem";
		$scope.qm = qm;
		$scope.model = new StorageItemsModel();
		$scope.systemModel = new SystemModel();
		$scope.storageModel = new StorageModel();
		$scope.grid = {};
		$scope.grid.appScope = $scope;
		$scope.system_id = $routeParams.id;
		$scope.show_hours_column = false;
		$scope.show_expenses_column = false;
		$scope.show_photos_column = false;

		$scope.getGridColumns = function () {
			return [
				$scope.qm.getDefaultIdColumn(),
				{
					name: 'Photo',
					enableHiding: $scope.show_photos_column,
					visible: $scope.show_photos_column,
					enableSorting: false,
					enableFiltering: false,
					enableColumnMenu: false,
					width: '82',
					cellTemplate: 'components/storageitems/storageitems_photo_template.html'
				},
				{
					name: 'Description',
					field: 'Description',
					enableHiding: false,
					filter: {placeholder: 'search description...'}
				},
				{
					name: 'Purchased',
					field: 'PurchaseDate',
					visible: !$scope.qm.mobile,
					type: 'date',
					maxWidth: 90,
					cellFilter: qm.dateCellFilter,
					enableHiding: true,
					sort: {priority: 0, direction: 'desc'},
					filter: {placeholder: 'search...'}
				},
				{
					name: 'Expires',
					field: 'ExpireDate',
					visible: !$scope.qm.mobile,
					type: 'date',
					maxWidth: 90,
					cellFilter: qm.dateCellFilter,
					enableHiding: true,
					sort: {priority: 1, direction: 'desc'},
					filter: {placeholder: 'search...'},
					cellTemplate: 'components/storageitems/storageitems_expires_template.html'
				},
				{
					name: 'Count',
					field: 'Quantity',
					visible: !$scope.qm.phone,
					maxWidth: 90,
					type: 'number',
					enableHiding: true,
					filter: {placeholder: 'search...'},
					cellTemplate: 'components/storageitems/storageitems_count_template.html'
				},
				{
					name: 'Category',
					field: 'Category',
					visible: !$scope.qm.phoneplus,
					enableHiding: true,
					filter: {placeholder: 'search category...'}
				},
				{
					name: 'Cost',
					field: 'Expenses',
					enableHiding: $scope.show_expenses_column,
					visible: $scope.show_expenses_column && !$scope.qm.phoneplus,
					width: '120',
					filter: {placeholder: 'search cost...'}
				},
				// {
				// 	name: 'Brand',
				// 	field: 'Brand',
				// 	enableHiding: true,
				// 	visible: false,
				// 	filter: {placeholder: 'search description...'}
				// },
				// { name:'Color', field: 'Color', enableHiding:false, filter: { placeholder: 'search description...'} },
				{
					name: 'Notes',
					field: 'Notes',
					visible: !$scope.qm.phoneplus,
					enableHiding: true,
					filter: {placeholder: 'search notes...'}
				},
				// { name:'Created', field: 'Created', cellFilter: qm.dateTimeCellFilter, enableHiding:false, filter: { placeholder: 'search first...'} },
				// { name:'CreatedBy', field: 'CreatedBy', enableHiding:false, filter: { placeholder: 'search first...'} },
				$scope.qm.getSystemModified(), $scope.qm.getSystemModifiedBy(),
				{
					name: 'Actions',
					enableHiding: false,
					enableSorting: false,
					enableFiltering: false,
					enableColumnMenu: false,
					width: '80',
					cellTemplate: 'components/storageitems/storageitems_action_template.html'
				}
			];
		};

		$scope.setGridOptions = function () {
			$scope.gridOptions = {
				enableGridMenu: true,
				gridMenuShowHideColumns: true,
				exporterMenuCsv: true,
				exporterMenuAllData: false,
				exporterMenuVisibleData: true,
				exporterCsvFilename: 'Quartermaster-Inventory.csv',
				exporterMenuExcel: false,
				exporterMenuPdf: false,
				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: true,
				enableColumnResizing: true,
				enableColumnReordering: true,
				enableColumnMoving: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				columnDefs: $scope.getGridColumns(),
				data: 'model.items',
				tabs: [],
				rowTemplate:
					'<div ng-class="{ \'grid-row-zero-quantity\': grid.appScope.rowFormatter( row ) }">' +
					'  <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }"  ui-grid-cell></div>' +
					'</div>',
				exporterSuppressColumns: ['Id', 'Actions', 'Photo'],
				importerNewObject: StorageItems,
				importerShowMenu: true,
				importerDataAddCallback: function (grid, newObjects) {
					for (var newObjectCounter=0;newObjectCounter < newObjects.length; newObjectCounter++) {
						var newObject = newObjects[newObjectCounter];
						//Strip blank string, i.e. ""
						for (var property in newObject) {
							if ((typeof newObject[property] === 'string') && (newObject[property] === "")) {
								// Skip blank strings (it's not a number and will cause ,, in SQL statements
								delete newObject[property];
							}
						}
						$scope.model.saveItem(newObject).then(function (newStorageItem) {
							$scope.model.items.unshift(newStorageItem);
						}, function(err) {
							console.log(err);
						});
					}
					// $scope.model.items = $scope.model.items.concat(newObjects);
				},
				onRegisterApi: function (gridApi) {
					$scope.gridApi = gridApi;
				}
			};
		};
		$scope.setGridOptions();

		// Use to determine if we show row in Red
		$scope.rowFormatter = function (row) {
			return row.entity.Quantity === 0;
		};

		$scope.$on('screen-changed', function (event, args) {
			$scope.gridOptions.columnDefs = $scope.getGridColumns();
			$scope.gridApi.core.refresh();
		});

		$scope.load = function () {
			var deferred = $q.defer();
			$scope.gridOptions.loading = true;
			$scope.systemModel.getItem($scope.system_id).then(function (result) {
				$scope.system = result;
				$scope.vessel_id = $scope.system.System_Vessel;
				$scope.show_hours_column = false;
				$scope.show_expenses_column = $scope.system.TrackExpenses;
				$scope.show_photos_column = $scope.system.ShowPhotos;
				if ($scope.show_photos_column) {
					$scope.gridOptions.rowHeight = 82;
				}
				$scope.gridOptions.columnDefs = $scope.getGridColumns();
				$scope.model.load($scope.system).then(function (result) {
					// var tagKeys = [];
					// $scope.gridOptions.tabs = [{name: 'All'}];
					// for (var j = 0; j < result.length; j++) {
					// 	var offer = result[j];
					// 	if (offer.status) {
					// 		if (tagKeys.indexOf(offer.status) < 0) {
					// 			tagKeys.push(offer.status);
					// 			$scope.gridOptions.tabs.push({
					// 				name: offer.status,
					// 				field: 'status',
					// 				filter: offer.status
					// 			});
					// 		}
					// 	}
					// }
					$scope.gridOptions.loading = false;
					deferred.resolve(result);
				}, function (error) {
					Exception.errorFn(error);
				});
			}, function (error) {
				Exception.errorFn(error);
			});
			return deferred.promise;
		};
		$scope.load();

		// $scope.getFilesUrl = function(row) {
		//     return '#/systems/'+row.entity.Id+'/services/files/'+Constants.FileComponent_SystemService;
		// };

		$scope.showStorageItemProfileModal = function (storageItem) {
			var title = storageItem.Description + '\'s profile photo';
			$scope.showProfileModal(storageItem, title, Constants.FileComponent_StorageItemProfile, storageItem.id, {
				width: 144,
				height: 144
			});
		};

		$scope.showProfileModal = function (entity, title, component_id, entity_id, crop_settings) {

			var modalInstance = $uibModal.open({
				templateUrl: 'core/photo-cropper/modal_storageitem.html',
				controller: 'PhotoCropperModalCtrl',
				windowClass: 'vessel-photo-modal',
				size: 'storageitemphoto',
				resolve: {
					title: function () {
						return title;
					},
					vessel_id: function () {
						return $scope.vessel_id;
					},
					component_id: function () {
						return component_id;
					},
					entity: function () {
						return entity;
					},
					entity_id: function () {
						return entity_id;
					},
					model: function () {
						return profilePhotoModel;
					},
					crop_settings: function () {
						return crop_settings;
					}
				}
			});

			modalInstance.result.then(function (entity) {
				var oldFileId = entity.StorageItem_File;
				entity.StorageItem_File = entity.FileId;
				$scope.model.saveItem(entity).then(function (result) {
					if (oldFileId) {
						profilePhotoModel.markFileDeleted(oldFileId);
					}
					$scope.load();
				});
			}, function () {
				console.log('Modal dismissed at: ' + new Date());
			});
		};

		$scope.loadStorageLookup = function() {
			var deferred = $q.defer();
			if (qm.selectedVessel.Id) {
				$scope.storageModel.load(qm.selectedVessel.Id).then(function(result) {
					console.log('Loaded storage items:' + $scope.storageModel.items.length);
					deferred.resolve(result);
				}, function (error) {
					Exception.handleError(error, deferred);
				});
			}
			else {
				deferred.resolve(null);
			}
			return deferred.promise;
		};

		$scope.showMoveModal = function (entity, title) {

			$scope.loadStorageLookup();

			var modalInstance = $uibModal.open({
				templateUrl: 'components/storageitems/storageitems_move.html',
				controller: 'ModalController',
				// windowClass: 'vessel-photo-modal',
				// size: 'storageitemphoto',
				resolve: {
					title: function () {
						return title;
					},
					modalObject: function () {
						return entity;
					},
					model: function () {
						return $scope.storageModel;
					}
				}
			});

			modalInstance.result.then(function (entity) {
				if (entity.NewStorageLocker.Id != entity.StorageItem_System) {
					//New locker selected, let's move item
					common.showWorking();
					entity.StorageItem_System = entity.NewStorageLocker.Id;
					$scope.model.saveItem(entity).then(function (result) {
						$scope.load();
						common.hideWorking();
					}, function (error) {
						common.hideWorking();
						Exception.errorFn(error);
					});
				}
			}, function () {
				console.log('Move Modal dismissed at: ' + new Date());
			});
		};

		$scope.increaseCount = function (modalObject, item) {
			console.log(modalObject);
			// storageItem.Quantity++;
			// $scope.model.saveItem(storageItem);
		};

		$scope.increaseCount = function (storageItem) {
			storageItem.Quantity++;
			common.showWorking();
			$scope.model.saveItem(storageItem).then(function (result) {
				common.hideWorking();
			}, function (error) {
				common.hideWorking();
				Exception.errorFn(error);
			});
		};

		$scope.decreaseCount = function (storageItem) {
			if (storageItem.Quantity > 0) {
				storageItem.Quantity--;
				common.showWorking();
				$scope.model.saveItem(storageItem).then(function (result) {
					common.hideWorking();
				}, function (error) {
					common.hideWorking();
					Exception.errorFn(error);
				});
			}
		};

		$scope.listenForChanges('StorageItems');

	});
