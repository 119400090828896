angular.module('app')
	.controller('SystemController', function ($scope, System, SystemModel, $rootScope, $location , Exception, $q, uiGridConstants, qm, Constants, $controller) {
		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));

		$rootScope.bodyClass = "system";
		$scope.qm = qm;
		$scope.model = new SystemModel();
		$scope.grid = {};
		$scope.grid.appScope = $scope;

		//TODO: We should have common gridoptions values set in a common location (shared)

		$scope.getGridColumns = function() {
			return [
				$scope.qm.getDefaultIdColumn(),
				{ name:'Description', field: 'Description', enableHiding:false, sort: { priority: 1, direction: 'asc' }, filter: { placeholder: 'search description...'},
					cellTemplate: 'components/system/system_description_template.html'
				},
				{ name:'Manufacturer', field: 'Manufacturer', visible: !$scope.qm.phoneplus, enableHiding:true, filter: { placeholder: 'search manufacturer...'} },
				{ name:'Model', field: 'Model', visible: !$scope.qm.mobile, enableHiding:true, filter: { placeholder: 'search model...'} },
				{ name:'Notes', field: 'Notes', visible: !$scope.qm.phoneplus, enableHiding:false, filter: { placeholder: 'search notes...'} },
				// { name:'Notes', field: 'Notes', enableHiding:true, filter: { placeholder: 'search notes...'} },
				{ name:'Track Hours', field: 'TrackHours', maxWidth: '100', type: 'boolean', visible: false, enableHiding:true, enableFiltering: true},
				$scope.qm.getSystemModified(),$scope.qm.getSystemModifiedBy(),
				{ name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '140',
					cellTemplate: 'components/system/system_action_template.html'
				}
			];
		};

		$scope.setGridOptions = function() {
			$scope.gridOptions = {
				enableGridMenu: true,
				gridMenuShowHideColumns: true,
				exporterMenuCsv: true,
				exporterMenuAllData: false,
				exporterMenuVisibleData: true,
				exporterCsvFilename: 'Quartermaster-Systems.csv',
				exporterMenuExcel: false,
				exporterMenuPdf: false,
				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: true,
				enableColumnResizing: true,
				enableColumnReordering: true,
				enableColumnMoving: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				columnDefs: $scope.getGridColumns(),
				data: 'model.items',
				tabs: [],
				exporterSuppressColumns: ['Id', 'Actions', 'Photo'],
				importerNewObject: System,
				importerShowMenu: true,
				importerDataAddCallback: function (grid, newObjects) {
					for (var newObjectCounter=0;newObjectCounter < newObjects.length; newObjectCounter++) {
						var newObject = newObjects[newObjectCounter];
						//Strip blank string, i.e. ""
						for (var property in newObject) {
							if ((typeof newObject[property] === 'string') && (newObject[property] === "")) {
								// Skip blank strings (it's not a number and will cause ,, in SQL statements
								delete newObject[property];
							}
						}
						$scope.model.saveItem(newObject).then(function (newItem) {
							$scope.model.items.unshift(newItem);
						}, function(err) {
							console.log(err);
						});
					}
					// $scope.model.items = $scope.model.items.concat(newObjects);
				},
				onRegisterApi: function( gridApi ) {
					$scope.gridApi = gridApi;
				}
			};
		};
		$scope.setGridOptions();

		$scope.$on('screen-changed', function(event, args) {
			$scope.gridOptions.columnDefs = $scope.getGridColumns();
			$scope.gridApi.core.refresh();
		});

		$scope.load = function() {
			var deferred = $q.defer();
			if (qm.selectedVessel.Id) {
				$scope.model.load(qm.selectedVessel.Id).then(function(result) {
					// var tagKeys = [];
					// $scope.gridOptions.tabs = [{name:'All'}];
					// for (var j = 0; j < result.length; j++) {
					//     var offer = result[j];
					//     if (offer.status) {
					//         if (tagKeys.indexOf(offer.status) < 0) {
					//             tagKeys.push(offer.status);
					//             $scope.gridOptions.tabs.push({name:offer.status, field:'status', filter:offer.status});
					//         }
					//     }
					// }
					// console.log('Loaded system items:' + $scope.model.items.length);
					deferred.resolve(result);
				}, function (error) {
					Exception.handleError(error, deferred);
				});
			}
			else {
				deferred.resolve(null);
			}
			return deferred.promise;
		};

		$scope.$watch('qm.selectedVessel', function () {
			if (qm.selectedVessel) {
				$scope.load();
			}
		});

		$scope.getServiceUrl = function(row) {
			return '/systems/'+row.entity.Id+'/services';
		};

		$scope.getScheduleUrl = function(row) {
			return '/systems/'+row.entity.Id+'/schedules';
		};

		$scope.getFilesUrl = function(row) {
			return '/systems/'+row.entity.Id+'/files/'+Constants.FileComponent_System;
		};

		$scope.getSummaryUrl = function(row) {
			if (!IS_IONIC) {
				//Regular system
				return '/systems/'+row.entity.Id+'/summary';
			}
			else {
				return '#/tab/systems/'+row.entity.Id;
			}
		};

		$scope.listenForChanges('Systems');

	});
