/**
 * Created by petter on 2/18/16.
 */
'use strict';

angular.module('app')
	.factory('StorageItemsModel', function ($q, Model, StorageItems, s3factory, Constants) {
		function StorageItemsModel() {
			Model.call(this, StorageItems);
			// this.categories = [
			// 	'clothing',
	        //     'fastener',
	        //     'part',
	        //     'pump',
	        //     'screw',
	        //     'shirt',
	        //     'tool'
			// ];
	        this.categories = [
	        ];
		}

		StorageItemsModel.prototype = Object.create(Model.prototype);
		StorageItemsModel.prototype.constructor = StorageItemsModel;

		StorageItemsModel.prototype.load = function(system) {
			if (system) {
				this.system = system;
			}

			return Model.prototype.load.call(this, {system_id: this.system.Id});
		};

		StorageItemsModel.prototype.saveItem = function(data) {
			if (this.system && (!data.StorageItem_System)) {
				data.StorageItem_System = this.system.Id;
			}
			return Model.prototype.saveItem.call(this, data);
		};

		StorageItemsModel.prototype.onLoadedItem = function(item) {
	        item.profileUrl = s3factory.getProfilePhotoUrl(item.Files_Vessel, Constants.FileComponent_StorageItemProfile, item.uuid, item.FileName);
	        // if (!item.profileUrl) {
		    //     if (item.Category) {
			//         item.profileUrl = './img/storage/'+item.Category.toLowerCase()+'.svg';
		    //     }
		    //     else {
			//         item.profileUrl = './img/storage/shirt.svg';
		    //     }
	        // }
			item.id = item.Id;
			// item.ServiceDate = new Date();
			// console.log('Loaded ' + item.Description);
			if (item.Category) {
            	if (this.categories.indexOf(item.Category) === -1) {
		            this.categories.push(item.Category);
	            }
			}
		};

		StorageItemsModel.prototype.isValidItem = function(item) {
			this.isItemDirty = true;
			item.isItemDirty = true;
			// if (!item.Description || !item.PurchaseDate){
			if (!item.Description){
				return false;
			}
			return true;
		};

		return StorageItemsModel;
	});

angular.module('app')
	.factory('StorageItems', function(ItemBase, Configuration) {
		function StorageItems() {
			ItemBase.call(this);
			// this.PurchaseDate = new Date();
	        this.Quantity = 1;
		}

		StorageItems.prototype = Object.create(ItemBase.prototype);

		StorageItems.dataColumns = ['Description', 'Notes', 'StorageItem_System', 'StorageItem_File', 'PurchaseDate', 'ExpireDate', 'Category', 'Quantity', 'Expenses'];
		StorageItems.templateUrl = 'components/storageitems/storageitems_edit.html';
		StorageItems.url = Configuration.API + '/storageitems/:id';

	    StorageItems.prototype.isExpired = function() {
		    if (!this.ExpireDate) {
			    return false;
		    }
		    var today = new Date();
		    if (this.ExpireDate < today) {
			    // console.log('Expired: ' + this.ExpireDate);
			    return true;
		    }
		    else {
			    return false;
		    }
	    };

		return StorageItems;
	});

