/**
 * Created by petter on 3/31/16.
 */
'use strict';

angular.module('app')
	.factory('CrewModel', function ($q, Model, Crew, s3factory, Constants, common) {
		function CrewModel() {
			Model.call(this, Crew);
			this.roles = [
				{
					name:'Captain',
					id:0
				},
				{
					name:'Crew',
					id:1
				},
	            {    //Keep a placeholder for Custom or something else. It's easier, since canEdit in base_controller uses <2, thus can be <3
		            name: 'Custom',
		            id: 2
	            },
	            {
					name:'Guest',
					id:3
				}
			];
	        this.visibleRoles = [this.roles[0],this.roles[1],this.roles[3]];
			this.mail_tasks = [
				// {
				//     name:'Do not send',
				//     id:1
				// },
				{
					name:'Daily',
					id:2
				},
				{
					name:'Every Sunday',
					id:3
				},
				{
					name:'Every Monday',
					id:4
				},
				{
					name:'Every Tuesday',
					id:5
				},
				{
					name:'Every Wednesday',
					id:6
				},
				{
					name:'Every Thursday',
					id:7
				},
				{
					name:'Every Friday',
					id:8
				},
				{
					name:'Every Saturday',
					id:9
				},
				{
					name:'Once a month',
					id:10
				}
			];
		}

		CrewModel.prototype = Object.create(Model.prototype);
		CrewModel.prototype.constructor = CrewModel;

		CrewModel.prototype.load = function(params) {
			if (params && params.vessel_id) {
				this.vessel_id = params.vessel_id;
			}

			return Model.prototype.load.call(this, {VesselUserRole_Vessel: this.vessel_id});
		};

		CrewModel.prototype.saveItem = function(data) {
        	if (!data.Id) {
        		//New Crew, let's make sure it's not a duplicate e-mail
		        for (var i=0; i<this.items.length; i++) {
			        var existingCrew = this.items[i];
		        	if (existingCrew.UserName === data.UserName) {
		        		//Duplicate, let's just return back the original
				        return Model.prototype.refreshItem.call(this, existingCrew);
			        }
		        }
	        }
			data.VesselUserRole_Vessel = this.vessel_id;
			return Model.prototype.saveItem.call(this, data);
		};

		CrewModel.prototype.onLoadedItem = function(item) {
			item.profileUrl = s3factory.getProfilePhotoUrl(item.Files_Vessel, Constants.FileComponent_CrewProfile, item.uuid, item.FileName);
			if (!item.profileUrl) {
				item.profileUrl = './img/default-avatar.png';
			}
			item.id = item.Id;
			item.ReceiveTaskAssignedMailText = (item.ReceiveTaskAssignedMail ? 'Yes' : 'No');
			item.ReceiveMailText = (item.ReceiveMail ? 'Yes' : 'No');
			if (item.ReceiveMail) {
				item.ReceiveMailText = item.MailTasksText;
			}
			//Kanban support
			item.name = item.Name;
			if (!item.Name) {
				item.name = item.UserName;
			}
			item.imageUrl = item.profileUrl;
			// item.ServiceDate = new Date();
			console.log('Loaded ' + item.Description);
			//item.profileUrl = item.getProfileUrl();
		};

		CrewModel.prototype.isValidItem = function(item) {
			this.isItemDirty = true;
			item.isItemDirty = true;
			if (!item.UserName || !item.UserName.includes("@") || (item.VesselUserRole_VesselRole == null)){
				return false;
			}
			return true;
		};

	    CrewModel.prototype.getGridColumns = function() {
		    return [
			    common.getDefaultIdColumn(),
			    { name:'Photo', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '100',
				    cellTemplate: 'components/crew/crew_photo_template.html'
			    },
			    { name:'Role', field: 'RoleName', maxWidth: '120', enableHiding:true, filter: { placeholder: 'search Role...'} },
			    { name:'Name', field: 'Name', enableHiding:false, filter: { placeholder: 'search Name...'} },
			    { name:'email', field: 'EmailStatus', visible: !common.phoneplus, enableHiding:false, filter: { placeholder: 'search email...'} },
			    { name:'Report e-mail', field: 'ReceiveMailText', maxWidth: '128', type: 'boolean', visible: !common.phone, enableHiding:false, enableFiltering: false},
			    { name:'Assigned e-mail', field: 'ReceiveTaskAssignedMailText', maxWidth: '138', type: 'boolean', visible: !common.phone, enableHiding:false, enableFiltering: false},
			    common.getSystemModified(),common.getSystemModifiedBy(),
			    { name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '80',
				    cellTemplate: 'components/crew/crew_action_template.html'
			    }
		    ];
	    };

		return CrewModel;
	});

angular.module('app')
	.factory('Crew', function(ItemBase, Configuration) {
		function Crew() {
			ItemBase.call(this);
			this.MailTasks = 8;
			this.ReceiveTaskAssignedMail = 1;
		}

		Crew.prototype = Object.create(ItemBase.prototype);

		Crew.dataColumns = ['VesselUserRole_VesselRole', 'VesselUserRole_Vessel', 'Name', 'UserName', 'Status', 'VesselUserRole_File' ,'ReceiveMail', 'MailTasks', 'ReceiveTaskAssignedMail'];
		Crew.templateUrl = 'components/crew/crew_edit.html';
		Crew.url = Configuration.API + '/crew/:id';

		Crew.prototype.onNewItem = function() {
			// this.VesselUserRole_Vessel = this.modelClass.type_id;
		};

		Object.defineProperty(Crew.prototype, 'value', {
			get: function () {
				return this.NameOrEmail;
			}
		});

		Object.defineProperty(Crew.prototype, 'label', {
			get: function () {
				return this.NameOrEmail;
			}
		});

	    Object.defineProperty(Crew.prototype, 'NameOrEmail', {
		    get: function () {
		    	if (this.Name) {
				    return this.Name;
			    }
			    return this.UserName;
		    }
	    });

	    Object.defineProperty(Crew.prototype, 'NameWithEmail', {
		    get: function () {
			    if (this.Name) {
				    return this.Name + ' (' + this.UserName + ')';
			    }
			    return this.UserName;
		    }
	    });

	    Object.defineProperty(Crew.prototype, 'EmailStatus', {
		    get: function () {
			    if (!this.UserId) {
				    return this.UserName + ' (pending signup)';
			    }
			    else if (this.UserId === this.Vessel_User) {
				    return this.UserName + ' (Owner)';
			    }
			    return this.UserName;
		    }
	    });

	    Object.defineProperty(Crew.prototype, 'RoleName', {
		    get: function () {
		    	if (this.model) {
				    return this.model.roles[this.VesselUserRole_VesselRole].name;
			    }
		    	else {
		    		return 'N/A';
			    }
		    }
	    });


		Object.defineProperty(Crew.prototype, 'MailTasksText', {
		    get: function () {
			    let mailTasksText = 'N/A';
			    for (let i=0; i<this.model.mail_tasks.length; i++) {
				    if (this.model.mail_tasks[i].id === this.MailTasks) {
					    mailTasksText = this.model.mail_tasks[i].name;
					    break;
				    }
			    }
			    return mailTasksText;
		    }
	    });

		return Crew;
	});

