/**
 * Created by petter on 3/31/16.
 */
'use strict';
angular.module('app')
	.directive('datePicker', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=model',
				min: '=minDate',
				max: '=maxDate',
				autofocus: '=autofocus'
			},
			controller: function ($scope) {
				$scope.opened = false;
				$scope.popup = {
					opened: false
				};
				$scope.open = function($event) {
					// $event.preventDefault();
					// $event.stopPropagation();

					$scope.popup.opened = true;
				};
				$scope.dateOptions = {
					formatYear: 'yy',
					// maxDate: new Date(2020, 5, 22),
					// minDate: new Date(),
					startingDay: 1
				};

				$scope.format = 'MM/dd/yyyy';
				$scope.setMax = function(val) {
					if (val) {
						$scope.maxDate = new Date(val);
					}
					else {
						$scope.maxDate = new Date('1/1/2100');
					}
				};
				$scope.setMax($scope.max);

				$scope.setMin = function(val) {
					if (val) {
						$scope.minDate = new Date(val);
					}
					else {
						$scope.minDate = new Date('1/1/1900');
					}
				};
				$scope.setMin($scope.min);
			},
			link: function ($scope, element, attrs) {
				if (attrs.autofocus != null) {
					//Set autofocus on input field
					var angularElement = element[0];
					var inputElement = angularElement.getElementsByTagName('input');
					var inputAttr = angular.element(inputElement[0]);
					inputAttr.prop('autofocus',true);
				}
				if (attrs.maxDate) {
					$scope.$parent.$watch(attrs.maxDate, function(val) {
						$scope.setMax($scope.max);
					});
				}

				if (attrs.minDate) {
					$scope.$parent.$watch(attrs.minDate, function(val) {
						$scope.setMin($scope.min);
					});
				}
			},
			templateUrl: 'core/datepicker_view.html'
		};
	});
