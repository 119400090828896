/**
 * Created by petter on 4/05/16.
 */

'use strict';
angular.module('app')
	.service('profilePhotoModel', function ($http, s3factory, $uibModal, FileModel) {

	    this.uploadFileToUrl = function(vessel_id, component_id, user_id, file, tag, progresscallback, donecallback){

		    s3factory.uploadFile(file, vessel_id, component_id, user_id, tag, null, 'e',
			    function(data, status, headers, config) {
				    //Success
				    //_this.files.push(
				    //  {
				    //    filename:file.name
				    //  }
				    //);
				    //me.load();
				    console.log('s3 upload done');
				    //_this.loadUploads();
			    },
			    function(data, status, headers, config) {
				    //Error
				    console.log('s3 upload failed');
			    },
			    progresscallback,
			    donecallback
		    ).then(function(result){
			    console.log(result);
			    return result;
		    }).then(function(data){
			    console.log('s3 upload done');
		    }).catch(function (e) {
				throw (e);
		    });
	    };

		this.getFileSize = function(file) {
			var filesize = file.filesize;
			if (file.size) {
				filesize = file.size;
			}
			if (filesize) {
				file.filesizeKB = Math.round(filesize / 1024 * 100)/100;
				file.filesizeMB = Math.round(filesize / 1024 / 1024 * 100)/100;
				if (file.filesizeMB > 1) {
					return file.filesizeMB + ' MB';
				} else {
					return file.filesizeKB + ' KB';
				}
			}
			return '0 KB';
		};

	    this.markFileDeleted = function (oldFileId) {
			var fileModel = new FileModel();
		    var item = {
			    Id: oldFileId
		    };
			fileModel.deleteItem(item).then(function(r) {
				console.log('Marked file as delted');
			});
	    };

	});
