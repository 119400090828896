/**
 * Created by petter on 3/24/16.
 */
'use strict';
angular.module('app')
	.controller('ReallyClickController', function ($scope, $uibModalInstance, title, message, textYes, textNo) {
		$scope.message = message;
		$scope.title = title;
		$scope.textYes = textYes;
		$scope.textNo = textNo;

		$scope.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};

		$scope.ok = function () {
			$uibModalInstance.close('ok');
		};
	});
