angular.module('app')
	.controller('DashboardV2Controller', function ($scope, VesselModel, DashboardsModel, DashboardModel, DashboardSystemModel, $rootScope, $location , Exception, $q, qm, s3factory, Constants, $window, debounce, $uibModal, common, $controller) {
		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));
		$rootScope.bodyClass = "dashboard";
		$scope.qm = qm;
		$scope.model = new DashboardModel();
		$scope.dashboardSystemModel = new DashboardSystemModel();
		$scope.dashboardsModel = new DashboardsModel();
		$scope.isEditing = false;
		$scope.activeTab = 0;
		$scope.vesselModel = new VesselModel();

		$scope.checkOrientation = function () {
			if ($window.innerHeight > $window.innerWidth) {
				$scope.isPortrait = true;
				console.log("PORTRAIT");
			}
			else {
				$scope.isPortrait = false;
				console.log("LANDSCAPE");
			}
		};

		// $scope.swipeLeft = function() {
		// 	console.log('swipeLeft:' + $scope.activeTab);
		// 	if ($scope.activeTab < ($scope.tabs.length - 1)) {
		// 		$scope.activeTab = $scope.activeTab + 1;
		// 		$scope.initializeCanvas($scope.tabs[$scope.activeTab]);
		// 	}
		// }
		//
		// $scope.swipeRight = function() {
		// 	console.log('swipeRight:' + $scope.activeTab);
		// 	if ($scope.activeTab > 0) {
		// 		$scope.activeTab = $scope.activeTab -1;
		// 		$scope.initializeCanvas($scope.tabs[$scope.activeTab]);
		// 	}
		// }

		$scope.load = function() {
			if (qm.selectedVessel && !$scope.isEditing) {
				$scope.dashboard = null;    //Reset dashboard to force reload if called a second time
				$scope.dashboardsModel.load(qm.selectedVessel).then(function (result) {
					var tagKeys = [];
					$scope.tabs = [];
					for (var j = 0; j < result.length; j++) {
						var dashboard = result[j];
						if (dashboard.Name) {
							if (tagKeys.indexOf(dashboard.Name) < 0) {
								tagKeys.push(dashboard.Name);
								$scope.tabs.push({
									name: dashboard.Name,
									field: 'Name',
									filter: dashboard.Name,
									dashboard: dashboard
								});
							}
						}
					}
					if ($scope.tabs.length === 0) {
						$scope.noDashboardFound = true;
						$scope.initializeCanvas({dashboard: null});
					}
					else {
						//If we only have one tab, we won't display tabs and we should just load a canvas for that tab
						$scope.noDashboardFound = false;
						$scope.initializeCanvas($scope.tabs[0]);
					}
					//Set system vessel_id, so we can add new systems directly from the dashboard
					$scope.model.vessel_id = qm.selectedVessel.Id;
					$scope.dashboardSystemModel.vessel_id = qm.selectedVessel.Id;
				}, function (error) {
					Exception.errorFn(error);
				});
			}
		};

		// $scope.$watch('qm.selectedVessel', function () {
		// 	if (qm.selectedVessel) {
		// 		// $scope.load();
		// 		// $scope.initializeCanvas();
		// 	}
		// });

		$scope.$watch('qm.vesselList', function () {
			if (qm.vesselList && (qm.vesselList.length === 0)) {
				$scope.showSetupWizard = true;
				common.hideWorking();
			}
		});

		$scope.yachtAdded = function () {
			$scope.showSetupWizard = false;
			qm.loadVessels();
		};

		$scope.systemAdded = function() {
			$scope.initializeCanvas($scope.tabs[$scope.activeTab]);
		};

		$scope.tabSelected = function(tab) {
			// $scope.initializeCanvas(tab);
		};

		$scope.getServiceUrl = function(row) {
			if (!IS_IONIC) {
				return '/systems/'+row.Id+'/services';
			}
			else {
				return '#/tab/systems/'+row.Id;
			}
		};

		$scope.getSummaryUrl = function(row) {
			if (!IS_IONIC) {
				if (row.System_SystemType === 1) {
					//Storage system
					return '/storage/'+row.Id+'/items';
				}
				else {
					//Regular system
					return '/systems/'+row.Id+'/summary';
				}
			}
			else {
				if (row.System_SystemType === 1) {
					//Storage system
					return '#/tab/storage/'+row.Id;
				}
				else {
					//Regular system
					return '#/tab/systems/'+row.Id;
				}
			}
		};

		// $scope.loadCanvas();

		// var objScreen;

		var mouseTarget;	// the display object currently under the mouse, or being dragged
		var dragStarted;	// indicates whether we are currently in a drag operation
		var offset;
		var update = true;

		// var w = angular.element($window);
		// w.bind('resize', function () {
		// 	console.log('resize');
		// 	$scope.screenRedraw();
		// });


		$scope.showHideUnplacedSystems = function() {
			for (var i = 0; i<$scope.containers.length; i++) {
				var container = $scope.containers[i];
				if (!container.system.x || !container.system.y) {
					container.visible = $scope.isEditing;
					// console.log(container.system.Id + ' unplaced:' + container.visible);
					container.removeContainer.visible = false;
				}
				else {
					container.removeContainer.visible = $scope.isEditing;
				}
			}
			$scope.Stage.update();
		};

		$scope.setAllSystemsRotation = function(rotation) {
			if ($scope.containers) {
				for (var i = 0; i < $scope.containers.length; i++) {
					var container = $scope.containers[i];
					if (container.system.x && container.system.y) {
						container.rotation = rotation;
					}
				}
				$scope.Stage.update();
			}
		};

		$scope.editDashboard = function() {
			$scope.isEditing = !$scope.isEditing;
			$scope.showHideUnplacedSystems();
		};

		$scope.saveDashboard = function() {
			$scope.isEditing = !$scope.isEditing;
			$scope.showHideUnplacedSystems();
		};


		$scope.circlealpha = 1;
		$scope.circlealphainc = 1;

		// $scope.createSlider = function(element, contentItem, min, max) {
		// 	// Generate the input element.
		// 	$(element).append('<input type="range" min="' + min +
		// 		'" max="' + max + '" value="' + contentItem.value + '" />');
		// 	// Now, after jQueryMobile has had a chance to process the
		// 	// new DOM addition, perform our own post-processing
		// 	// (basically for each jQuery Mobile widget, like the slider,
		// 	// there is a “<controlname>init” event you can listen for
		// 	// that is called after they have finalized the content of the DOM)
		// 	$(element).one('slideinit', function (e) {
		// 		var $slider = $(e.target);
		// 		// If the content item changes (perhaps due to another control being
		// 		// bound to the same content item, or if a change occurs programmatically),
		// 		// update the visual representation of the slider:
		// 		contentItem.dataBind('value', function (newValue) {
		// 			$slider.val(newValue);
		// 		});
		// 		// Conversely, whenever the user adjusts the slider visually,
		// 		// update the underlying content item:
		// 		$slider.change(function () {
		// 			contentItem.value = $slider.val();
		// 		});
		// 	});
		// };

		// $scope.initializeCanvas();

		$scope.edit = function() {
			$scope.isEditing = !$scope.isEditing;
			console.log("Edit mode:" + $scope.isEditing);
		};

		// $scope.listenForChanges('Systems');

		window.addEventListener('message', function(e) {
			// Do we trust the sender of this message?  (might be
			// different from what we originally opened, for example).

			// if (event.origin !== "http://example.com")
			// 	return;
			if (e.data.key && e.data.key === 'clickSystem') {
				alert('clickSystem: ' + e.data.value);
			}
			else if (e.data.key && e.data.key === 'clickStorage') {
				alert('clickStorage: ' + e.data.value);
			}
			else {
				alert('unknown message received');
			}

			// event.source is popup
			// event.data is "hi there yourself!  the secret response is: rheeeeet!"
		});

	});
