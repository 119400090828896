/**
* Created by petter on 3/31/16.
*/
angular.module('app')
	.controller('CrewController', function ($scope, $controller, VesselModel, CrewModel, $rootScope, $location , Exception, $q, uiGridConstants, qm, $routeParams, profilePhotoModel, $uibModal, Constants) {

	        angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));
	        angular.extend(this, $controller('GridBaseController', {$scope: $scope}));

		$rootScope.bodyClass = "service";
		$scope.qm = qm;
		$scope.model = new CrewModel();
	        $scope.vesselModel = new VesselModel();
		$scope.vessel_id = $routeParams.id;
	        $scope.userProfile = $rootScope.authService.userProfile;
	        $scope.model.vesselRole = 1;   //-1=Owner, 0=Captain, 1=Crew (default)

	        $scope.grid = $scope.createGrid($scope.model, 'crew', 'Crew', null, null, 100);

		$scope.load = function() {
	            var deferred = $q.defer();
	            $scope.model.load({vessel_id:$scope.vessel_id}).then(function(result) {
	                console.log('Loaded crew items:' + result.length);
	                //Need to determine Users edit ability: If Owner, anything goes. Otherwise, if Crew, only edit own user
		            for (var i=0; i<result.length; i++) {
		            	var crew = result[i];
		            	if (crew.UserId === $scope.userProfile.Id) {
		            		//This is me (i.e. the user)
				            if (crew.UserId === crew.Vessel_User) {
				            	//I'm the Owner
					            $scope.model.vesselRole = -1;
					            break;
				            }
				            else {
					            $scope.model.vesselRole = crew.VesselUserRole_VesselRole;
					            break;
				            }
			            }
		            }
		            deferred.resolve(result);
			}, function (error) {
	                Exception.handleError(error, deferred);
			});
			return deferred.promise;
		};
		$scope.load();

		$scope.reloadCrew = function() {
			$scope.load();
		};

	        $scope.loadVessel = function () {
		        $scope.vesselModel.getItem($scope.vessel_id).then(function(result) {
			        $scope.vessel = result;
			        // $scope.load();
		        }, function (error) {
			        Exception.errorFn(error);
		        });
	        };
	        $scope.loadVessel();

	        $scope.showCrewProfileModal = function(crew) {
			var title = crew.Name + '\'s profile photo';
			$scope.showProfileModal(crew, title, Constants.FileComponent_CrewProfile, crew.id, {
				// shape:'circle',
				width: 160,
				height:160
			});
		};

		$scope.showProfileModal = function (entity, title, component_id, entity_id, crop_settings) {

			var modalInstance = $uibModal.open({
				templateUrl: 'core/photo-cropper/modal_crew.html',
				controller: 'PhotoCropperModalCtrl',
				windowClass: 'vessel-photo-modal',
				size: 'crewphoto',
				resolve: {
					title: function () {
						return title;
					},
					vessel_id: function () {
						return $scope.vessel_id;
					},
					component_id: function () {
						return component_id;
					},
					entity: function () {
						return entity;
					},
					entity_id: function () {
						return entity_id;
					},
					model: function () {
						return profilePhotoModel;
					},
					crop_settings: function () {
						return crop_settings;
					}
				}
			});

			modalInstance.result.then(function (entity) {
	                var oldFileId = entity.VesselUserRole_File;
				entity.VesselUserRole_File = entity.FileId;
				$scope.model.saveItem(entity).then(function (result) {
	                    if (oldFileId) {
		                    profilePhotoModel.markFileDeleted(oldFileId);
	                    }
					$scope.load();
				});
			}, function () {
				console.log('Modal dismissed at: ' + new Date());
			});
		};

	        $scope.canEdit = function (item) {
		        if ($scope.model.vesselRole === -1) {
		        	//Owner can always edit
			        return true;
		        }
		        else if ($scope.model.vesselRole === 0) {
		        	//Captain can edit, but not delete himself or Owner
			        return true;
		        }
		        else {
		        	//Owner can only edit himself
			        if (item.UserId === $scope.userProfile.Id) {
			        	//This is me
				        return true;
			        }
			        else {
			        	//Not me
				        return false;
			        }
		        }
	        };

	        $scope.canDelete = function (item) {
		        if ($scope.model.vesselRole === -1) {
			        //Owner can delete, but not delete himself
			        if (item.UserId === $scope.userProfile.Id) {
				        //This is me
				        return false;
			        }
			        else {
				        //Not me
				        return true;
			        }
		        }
		        else if ($scope.model.vesselRole === 0) {
			        //Captain can delete, but not delete himself or Owner
			        if (item.UserId === item.Vessel_User) {
				        //This is the Owner
				        return false;
			        }
			        else if (item.UserId === $scope.userProfile.Id) {
				        //This is me
				        return false;
			        }
			        else {
				        //Not owner or me
				        return true;
			        }
		        }
		        else {
			        //Crew can not delete
			        return false;
		        }
	        };

	        $scope.listenForChanges('VesselUserRoles');

	});
