angular.module('app')
	.controller('SystemSummaryController', function ($scope, SystemModel, FileModel, SystemServiceModel, SystemScheduleModel, TaskModel,
	                                                 $rootScope, $location , Exception, $q, common,
	                                                 s3factory, uiGridConstants, qm, $routeParams, Constants, $document, $controller) {
		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));
		$rootScope.bodyClass = "systemsummary";
		$scope.qm = qm;
		$scope.systemModel = new SystemModel();
		$scope.fileModel = new FileModel();
		$scope.systemServiceModel = new SystemServiceModel();
		$scope.systemScheduleModel = new SystemScheduleModel();
		$scope.taskModel = new TaskModel();
		$scope.grid = {};
		$scope.grid.appScope = $scope;
		$scope.system_id = $routeParams.id;
		$scope.show_hours_column = false;
		$scope.show_expenses_column = false;
		$scope.componentId = Constants.FileComponent_System;

		$scope.fileUrlLoading = 'img/file_loading.gif';
		$scope.fileUrl = $scope.fileUrlLoading;

		$scope.getFileGridColumns = function() {
			return [
				$scope.qm.getDefaultIdColumn(),
				// { name:'Preview', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '82',
				//    cellTemplate: 'components/files/files_thumbnail_template.html'
				// },
				{ name:'FileName', field: 'FileName', enableHiding:false, filter: { placeholder: 'search description...'} },
				{ name:'FileSize', field: 'FileSizeDescription', width: '80', visible: !$scope.qm.phoneplus, enableHiding:false, filter: { placeholder: 'search ...'} },
				{ name:'Type', field: 'Type', width: '20%', enableHiding:false},
				{ name:'Expires', field: 'ExpireDate', type: 'date', width: '80', visible: !$scope.qm.phoneplus, cellFilter: qm.dateCellFilter, enableHiding:false, sort: { priority: 0, direction: 'desc' }, filter: { placeholder: 'search...'} },
				{ name:'Uploaded', maxWidth: '80', field: 'Created', visible: !$scope.qm.phoneplus, cellFilter: qm.dateCellFilter, enableHiding:false, filter: { placeholder: 'search...'} },
				$scope.qm.getSystemModified(),$scope.qm.getSystemModifiedBy()
				// { name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '100',
				// 	cellTemplate: 'components/files/files_action_template.html'
				// }
			];
		};
		$scope.getScheduleGridColumns = function() {
			return [
				$scope.qm.getDefaultIdColumn(),
				{ name:'Interval (Days)', field: 'IntervalDays', width: '120', sort: { priority: 0, direction: 'asc' }, filter: { placeholder: 'search days...'} },
				{ name:'Hours', field: 'Hours', visible: $scope.show_hours_column, width: '120', filter: { placeholder: 'search hours...'} },
				{ name:'Description', field: 'Description', enableHiding:false, filter: { placeholder: 'search description...'} },
				{ name:'Next Date', field: 'ScheduleDate', type: 'date', width: '100', visible: !$scope.qm.phone, cellFilter: qm.dateCellFilter, enableHiding:false, filter: { placeholder: 'search date...'} },
				{ name:'Next Hours', field: 'NextScheduleHours', visible: $scope.show_hours_column, width: '120', filter: { placeholder: 'search hours...'} },
				{ name:'Notes', field: 'Notes', visible: !$scope.qm.phoneplus, enableHiding:false, filter: { placeholder: 'search notes...'} },
				$scope.qm.getSystemModified(),$scope.qm.getSystemModifiedBy()
				// { name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '70',
				// 	cellTemplate: 'components/systemschedules/systemschedules_action_template.html'
				// }
			];
		};
		$scope.getTaskGridColumns = function() {
			return [
				$scope.qm.getDefaultIdColumn(),
				{ name:'Description', field: 'Description', enableHiding:false, sort: { priority: 1, direction: 'asc' }, filter: { placeholder: 'search description...'},
					cellTemplate: 'components/tasks/tasks_description_template.html'},
				{ name:'State', field: 'state.name', width: '140', enableHiding:false, filter: {
					placeholder: 'select type...',
					condition: uiGridConstants.filter.EXACT,
					type: uiGridConstants.filter.SELECT,
					selectOptions: [
						{value:'To Do', label:'To Do'},
						{value:'In progress', label:'In progress'},
						{value:'Done', label:'Done'}
					]
				} },
				{ name:'Due Date', field: 'DueDate', type: 'date', width: '140', visible: !common.phoneplus, cellFilter: common.dateCellFilter, enableHiding:false, sort: { priority: 0, direction: 'asc' }, filter: { placeholder: 'search due date...'},
					cellTemplate: 'components/tasks/tasks_expires_template.html'
				},
				{ name:'Assigned To', field: 'assignedResource.name', enableHiding:false, visible: !common.phoneplus, filter: {
					placeholder: 'select type...',
					condition: uiGridConstants.filter.EXACT,
					type: uiGridConstants.filter.SELECT,
					selectOptions: this.crew
				} },
				{ name:'System', field: 'Systems_Description', enableHiding:true, visible:false, filter: { placeholder: 'search system...'},
					cellTemplate: 'components/tasks/tasks_system_template.html'},
				$scope.qm.getSystemModified(),$scope.qm.getSystemModifiedBy()
			];
		};
		$scope.getServiceGridColumns = function() {
			return [
				$scope.qm.getDefaultIdColumn(),
				{ name:'Date', field: 'ServiceDate', type: 'date', maxWidth: 70, cellFilter: qm.dateCellFilter, enableHiding:false, sort: { priority: 0, direction: 'desc' }, filter: { placeholder: 'search...'} },
				{ name:'Hours', field: 'Hours', visible: $scope.show_hours_column, width: '120', filter: { placeholder: 'search hours...'} },
				{ name:'Expenses', field: 'Expenses', visible: $scope.show_expenses_column, width: '120', filter: { placeholder: 'search expenses...'} },
				{ name:'Description', field: 'Description', enableHiding:false, filter: { placeholder: 'search description...'} },
				{ name:'Notes', field: 'Notes', visible: !$scope.qm.phoneplus, enableHiding:false, filter: { placeholder: 'search notes...'} },
				$scope.qm.getSystemModified(),$scope.qm.getSystemModifiedBy(),
				{ name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '100',
					cellTemplate: 'components/systemsummary/systemservices_action_template.html'
				}
			];
		};

		$scope.setFileGridOptions = function() {
			$scope.fileGridOptions = {
				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: false,
				enableColumnResizing: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				columnDefs: $scope.getFileGridColumns(),
				// data: 'model.items',
				tabs: [],
				importerShowMenu: false,
				importerDataAddCallback: function (grid, newObjects) {},
				onRegisterApi: function( gridApi ) {
					$scope.fileGridApi = gridApi;
				}
			};            };
		$scope.setScheduleGridOptions = function() {
			$scope.scheduleGridOptions = {
				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: false,
				enableColumnResizing: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				columnDefs: $scope.getScheduleGridColumns(),
				// data: 'model.items',
				tabs: [],
				importerShowMenu: false,
				importerDataAddCallback: function (grid, newObjects) {},
				onRegisterApi: function( gridApi ) {
					$scope.scheduleGridApi = gridApi;
				}
			};
		};
		$scope.setTaskGridOptions = function() {
			$scope.taskGridOptions = {
				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: false,
				enableColumnResizing: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				columnDefs: $scope.getTaskGridColumns(),
				// data: 'model.items',
				tabs: [],
				importerShowMenu: false,
				importerDataAddCallback: function (grid, newObjects) {},
				onRegisterApi: function( gridApi ) {
					$scope.scheduleGridApi = gridApi;
				}
			};
		};
		$scope.setServiceGridOptions = function() {
			$scope.serviceGridOptions = {
				enableGridMenu: true,
				gridMenuShowHideColumns: true,
				exporterMenuCsv: true,
				exporterMenuAllData: false,
				exporterMenuVisibleData: true,
				exporterCsvFilename: 'Quartermaster-Services-Log.csv',
				exporterMenuExcel: false,
				exporterMenuPdf: false,
				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: false,
				enableColumnResizing: true,
				enableColumnReordering: true,
				enableColumnMoving: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				columnDefs: $scope.getServiceGridColumns(),
				data: 'systemServiceModel.items',
				tabs: [],
				exporterSuppressColumns: ['Id', 'Actions'],
				importerShowMenu: false,
				importerDataAddCallback: function (grid, newObjects) {},
				onRegisterApi: function( gridApi ) {
					$scope.serviceGridApi = gridApi;
				}
			};
		};

		$scope.setFileGridOptions();
		$scope.setScheduleGridOptions();
		$scope.setTaskGridOptions();
		$scope.setServiceGridOptions();

		$scope.$on('screen-changed', function(event, args) {
			// $scope.scheduleGridOptions.columnDefs = $scope.getScheduleGridColumns();
			// $scope.scheduleGridApi.core.refresh();

			$scope.serviceGridOptions.columnDefs = $scope.getServiceGridColumns();
			$scope.serviceGridApi.core.refresh();
		});

		$scope.load = function() {
			var deferred = $q.defer();
			$scope.scheduleGridOptions.loading = true;
			$scope.taskGridOptions.loading = true;
			$scope.serviceGridOptions.loading = true;
			$scope.systemModel.getItem($scope.system_id).then(function(result) {
				$scope.system = result;
				$scope.show_hours_column = $scope.system.TrackHours;
				$scope.show_expenses_column = $scope.system.TrackExpenses;

				$scope.scheduleGridOptions.columnDefs = $scope.getScheduleGridColumns();
				$scope.taskGridOptions.columnDefs = $scope.getTaskGridColumns();
				$scope.serviceGridOptions.columnDefs = $scope.getServiceGridColumns();

				$scope.fileModel.load($scope.componentId, $scope.system.Id).then(function(result) {
					$scope.fileGridOptions.loading = false;
					for (var i=0; i<$scope.fileModel.items.length; i++) {
						$scope.fileModel.items[i].url = 'img/' + $scope.fileModel.items[i].icon + '.svg';
					}
				}, function (error) {
					Exception.errorFn(error);
				});

				$scope.systemScheduleModel.load($scope.system).then(function(result) {
					$scope.scheduleGridOptions.loading = false;
				}, function (error) {
					Exception.errorFn(error);
				});

				$scope.taskModel.load($scope.system.System_Vessel, {system_id: $scope.system.Id}).then(function(result) {
					$scope.taskGridOptions.loading = false;
				}, function (error) {
					Exception.errorFn(error);
				});

				$scope.systemServiceModel.load($scope.system).then(function(result) {
					$scope.serviceGridOptions.loading = false;
				}, function (error) {
					Exception.handleError(error, deferred);
				});
				//TODO: Wait for all above operations before resolving
				deferred.resolve(result);
			}, function (error) {
				Exception.handleError(error, deferred);
			});
			return deferred.promise;
		};
		$scope.load();

		$scope.getFilesUrl = function() {
			return '/systems/'+$scope.system_id+'/services/files/'+Constants.FileComponent_System;
		};

		$scope.getServiceFilesUrl = function(row) {
			// console.warn(row);
			return '/systems/'+row.Id+'/services/files/'+Constants.FileComponent_SystemService;
		};

		$scope.getScheduleUrl = function() {
			return '/systems/'+$scope.system_id+'/schedules';
		};

		$scope.getFileLink = function(item) {
			return s3factory.getSignedUrl($scope.componentId, item.id);
		};

		$scope.viewFile = function(item) {
			console.log('view');
			var ext = item.FileName.split('.');
			$scope.fileExtension = ext[ext.length -1].toLowerCase();
			$scope.getFileLink(item).then(function(response){
				// $window.location.href = response;
				$scope.fileUrl = response;
				$scope.fileName = item.FileName;
				console.log($scope.fileUrl);
				// $scope.trustSrc = $sce.trustAsResourceUrl($scope.fileUrl);
				// console.log($scope.trustedFileUrl);
				if (($scope.fileExtension ==='jpg') || ($scope.fileExtension ==='jpeg') || ($scope.fileExtension ==='png')) {
					// $location.path('/tab/files/' + item.Id);
					// window.open($scope.fileUrl, '_blank');
					//TODO: Open photo in a preview mode
					$scope.showImage();

					// $scope.showModal('js/components/files/files-view-image.html');
				}
				else {
					$scope.downloadFile();
					// $scope.showModal('js/components/files/files-view-pdf.html');
				}
			});
		};

		$scope.downloadFile = function() {
			console.log('downloading...');
			// window.open($scope.fileUrl, '_system', 'location=yes,disallowoverscroll=no,toolbar=yes,closebuttoncaption: \'Done\'');
			window.location.assign($scope.fileUrl);
		};

		// Lightbox code
		// Open the Lightbox Modal
		$scope.openModal = function () {
			document.getElementById('photosModal').style.display = "block";
		};

		// Close the Lightbox Modal
		$scope.closeModal = function () {
			document.getElementById('photosModal').style.display = "none";
			$scope.fileUrl = $scope.fileUrlLoading;
		};

		// Show Lightbox Image
		$scope.showImage = function() {
			$scope.openModal();
		};

		//TODO: Need to liste for more than one table, also schedules and files
		$scope.listenForChanges('SystemServices');


	});
