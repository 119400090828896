angular.module('app')
	.controller('BaseCtrl', function($scope, $rootScope, qm) {

		$scope.listenForChanges = function(table) {
			//Start listening right away (just to get a baseline for comparison)
			// qm.checkNeedsSync(table)
			//Make sure we only create one timer
			if (!$scope.heartBeatTimer) {
				$scope.heartBeatTimer = setInterval(function () {
					//Check for changes every 120 seconds
					qm.checkNeedsSync(table);
				}, 30 * 1000);
			}

			$scope.needsReloadListener = $rootScope.$on('qm.needsReload.' + table, function() {
				if (qm.selectedVessel) {
					$scope.load();
				}
			});

			$scope.$on("$destroy",function (){
				if ($scope.needsReloadListener) {
					$scope.needsReloadListener();
					console.log('$destroy: needsReloadListener cleared');
				}
				if ($scope.heartBeatTimer) {
					clearInterval($scope.heartBeatTimer);
					console.log('$destroy: heartBeatTimer cleared');
				}
			});
		};

		$scope.resetChanges = function () {
			//TODO: Implement a way to pause listenForChanges. Basically, prevent load right after safe. Could we possibly use showWorking as an easy way to suspend and reset  until hideWorking?
		};

		$scope.canEdit = function (vessel) {
			var applicableVessel = qm.selectedVessel;
			if (vessel) {
				applicableVessel = vessel;

			}
			else if (qm.selectedVessel) {
				applicableVessel = qm.selectedVessel;
			}
			if (applicableVessel) {
				for (var i = 0; i<qm.vesselList.length; i++) {
					if (applicableVessel.Id === qm.vesselList[i].Id) {
						//Find out what Role user has
						// if ($rootScope.authService.userProfile.Id === applicableVessel.Vessel_User) {
						// 	//User is Owner
						// 	return true;
						// }
						if (applicableVessel.VesselUserRole_VesselRole < 3) {
							//User is Captain or Crew
							return true;
						}
						else {
							return false;
						}
					}
				}
				return false;
			}
			return false;
		};


	});
