/**
 * Created by petter on 11/21/17.
 */
'use strict';

angular.module('app')
	.factory('AccountSubscriptionModel', function ($q, Model, AccountSubscription) {
		function AccountSubscriptionModel() {
			Model.call(this, AccountSubscription);
		}

	    AccountSubscriptionModel.prototype = Object.create(Model.prototype);
	    AccountSubscriptionModel.prototype.constructor = AccountSubscriptionModel;

	    AccountSubscriptionModel.prototype.load = function(params) {
			return Model.prototype.load.call(this);
		};

	    AccountSubscriptionModel.prototype.saveItem = function(data) {
			return Model.prototype.saveItem.call(this, data);
		};

	    //Create a no-op refreshItem method to account for missing GET in API
	    AccountSubscriptionModel.prototype.refreshItem = function(responseData) {
		    var deferred = $q.defer();
		    deferred.resolve(responseData);
		    return deferred.promise;
	    };

	    return AccountSubscriptionModel;
	});

angular.module('app')
	.factory('AccountSubscription', function(ItemBase, Configuration) {
		function AccountSubscription() {
			ItemBase.call(this);
		}

	    AccountSubscription.prototype = Object.create(ItemBase.prototype);

	    AccountSubscription.dataColumns = [];
	    AccountSubscription.url = Configuration.API + '/account_subscription/:id';

		return AccountSubscription;
	});

