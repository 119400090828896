/**
 * Created by petter on 4/05/16.
 */

'use strict';
angular.module('app')
	.controller('PhotoCropperModalCtrl', function ($scope, $uibModalInstance, $timeout, title, model, vessel_id, component_id, entity, entity_id, crop_settings, ThumbnailService, Exception, common) {
		$scope.model = model;
		$scope.title = title;
		$scope.vessel_id = vessel_id;
		$scope.component_id = component_id;
		$scope.entity = entity;
		$scope.entity_id = entity_id;
		$scope.imageCropStep = 1;
		$scope.crop_settings = crop_settings;
		$scope.common = common;

		$scope.cropper = {};
		$scope.cropper.sourceImage = null;
		$scope.cropper.croppedImage   = null;
		$scope.bounds = {};
		$scope.bounds.left = 0;
		$scope.bounds.right = 0;
		$scope.bounds.top = 0;
		$scope.bounds.bottom = 0;

		$scope.allowSaveWithoutCrop = false;

		$scope.fileChanged = function(e) {
        	if (e.target.files.length === 0) {
		        return;
	        }
	        common.showWorking();
			var files = e.target.files;
			$scope.file = files[0];
	        $scope.file.progress = 0;
	        $scope.file.total = 100;
	        $scope.file.loaded = 0;
			if ($scope.file) {
	            $scope.allowSaveWithoutCrop = false;
            	if ($scope.file.name.toLowerCase().endsWith('.svg')) {
		            $scope.allowSaveWithoutCrop = true;
	            }
				var fileReader = new FileReader();
				fileReader.readAsDataURL(files[0]);

				fileReader.onload = function(e) {
                	console.log('Load file');
					$scope.imgSrc = this.result;
					$scope.cropper.sourceImage = this.result;
					$timeout(function() {
						$scope.$apply();
					}, 1);
				};
	            fileReader.onloadend = function(e) {
		            common.hideWorking();
	            };
			}
		};

		$scope.clear = function() {
			$scope.imageCropStep = 1;
			delete $scope.imgSrc;
			delete $scope.result;
			delete $scope.resultBlob;
		};

		$scope.undoCrop = function() {
			$scope.imageCropStep = 2;
		};

		$scope.callFileControl = function () {
			// $("#fileInput").click();
			// var fileInput = angular.element('#fileInput');
	        var fileInput = document.getElementById('fileInput');
	        element.click();

	        if (fileInput) {
				fileInput.click();
				// fileInput.trigger('click');
			}
		};

		$scope.$watch('resultBlob', function () {
			if ($scope.imageCropStep === 3) {
				$scope.ok();
			}
		});

		function dataURItoBlob(dataURI) {
			// convert base64/URLEncoded data component to raw binary data held in a string
			var byteString;
			if (dataURI.split(',')[0].indexOf('base64') >= 0)
				byteString = atob(dataURI.split(',')[1]);
			else
				byteString = unescape(dataURI.split(',')[1]);

			// separate out the mime component
			var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

			// write the bytes of the string to a typed array
			var ia = new Uint8Array(byteString.length);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			return new Blob([ia], {type:mimeString});
		}

	    $scope.progresscallback = function (progress) {
		    //Progress
		    $scope.file.percent = progress.percent;
		    $scope.file.total = progress.total;
		    $scope.file.loaded = progress.loaded;
		    console.log('upload progress:' + $scope.file.percent);
		    $timeout(function() {
			    $scope.$apply();
		    }, 1);
	    };

	    $scope.donecallback = function (result, err) {
		    //Done
		    if (err) {
			    result.name = err;
			    //$scope.$apply();
		    }
		    else {
			    if (!$scope.file.cancelled) {
				    $scope.entity.FileId = result.Id;
				    $uibModalInstance.close($scope.entity);
			    }
		    }
	    };

		$scope.ok = function () {
			try {
				console.log('Generating thumbnail');
				common.showWorking();
				$scope.file.uploading = true;
				// $scope.resultBlob.name = $scope.file.name;
				// var img_b64 = $scope.cropper.croppedImage;
				// var png = img_b64.split(',')[1];

				// var the_file = new Blob([window.atob(png)],  {type: 'image/png', encoding: 'utf-8'});
				// var the_file = dataURItoBlob($scope.cropper.croppedImage);
				// the_file.name = $scope.file.name;

				ThumbnailService.generate($scope.cropper.croppedImage, {
					width: $scope.crop_settings.width,
					height: $scope.crop_settings.height,
					// returnType: 'blob',  //Blob Doesn't work in Safari, defaults to base64
					type: 'image/jpeg',
					noDistortion: true,
					encoderOptions: 1
				}).then(function (blob) {
					console.log('Thumbnail generated');
					try {
						// var image = blob;
						var image = dataURItoBlob(blob);
						if ($scope.crop_settings.nothumbnail) {
							image = dataURItoBlob($scope.cropper.croppedImage);
						}
						image.name = $scope.file.name;
						console.log('Uploading file');

						$scope.model.uploadFileToUrl($scope.vessel_id, $scope.component_id, $scope.entity_id, image, 'profilephoto', $scope.progresscallback, $scope.donecallback);
					}
					catch (e) {
						common.showError(e);
					}
				}, function (err) {
					// $uibModalInstance.dismiss('cancel');
					Exception.errorFn(err);
					throw err;
				});
			}
			catch (e) {
				common.hideWorking();
			}
			//$modalInstance.close($scope.imgSrc);
		};

	    $scope.saveUncropped = function () {
			try {
				console.log('Saving uncropped file');
				console.log('Uploading file');
				$scope.file.uploading = true;
				common.showWorking();
				$scope.model.uploadFileToUrl($scope.vessel_id, $scope.component_id, $scope.entity_id, $scope.file, 'profilephoto', $scope.progresscallback, $scope.donecallback);
			}
			catch (e) {
				common.showError(e);
			}
	    };

		$scope.cancel = function () {
	        common.hideWorking();
	        if ($scope.file) {
	        	$scope.file.cancelled = true;
	        }
			$uibModalInstance.dismiss('cancel');
		};

		//No longer used. If needed again, try using dayjs instead of moment
		// $scope.getExpirationDateClass = function(expirationDate) {
		//     if (expirationDate)
		//     {
		//         var expirationDate = moment.utc(expirationDate).add(1, 'days');
		//         var currentDate = moment.utc();
		//         if (expirationDate < currentDate)
		//         {
		//             return 'danger';
		//         }
		//         if (expirationDate < currentDate.add(30, 'days'))
		//         {
		//             return 'warning';
		//         }
		//     }
		//     return '';
		// };

		// $scope.callFileControl();

	});

angular.module('app')
	.directive('ngAutoClick', function() {
		return function (scope, element, attrs) {
			var fileElement = element[0];
			fileElement.click();
		};
	});
