/**
 * Created by petterbergman on 7/8/16.
 */
angular.module('app')
	.service('Constants', function () {
		// console.log('created Constants');

		this.FileComponent_Files = 1;
		this.FileComponent_VesselProfile = 2;
		this.FileComponent_CrewProfile = 3;
		this.FileComponent_DashboardTemplate = 4;
		this.FileComponent_System = 5;
		this.FileComponent_SystemService = 6;
		this.FileComponent_SystemSchedule = 7;
	    this.FileComponent_StorageItemProfile = 8;
	    this.FileComponent_Storage = 9;
	    this.FileComponent_Task = 10;
	    this.FileComponent_SIO_ShipPhoto = 101;
	    this.FileComponent_SIO_CompanyPhoto = 102;
	    this.FileComponent_SIO_YardPhoto = 103;
	    this.FileComponent_SIO_PartPhoto = 104;
	});

