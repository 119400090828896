angular.module('app')
	.service('authService', function ($rootScope, authWrapper, $location, $timeout, Configuration) {
		// console.log('created authService');
		$rootScope.authService = this;

		var homePath = Configuration.HomePath;
		var loggedInPath = Configuration.LoggedInPath;

		var accessToken;
		var idToken;

		this.getIdToken = function() {
			return idToken;
		};

		this.getAccessToken = function() {
			return accessToken;
		};

		try {
			this.userProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
		}
		catch (err) {
			console.error(err);
		}
		this.clearLocalStorageItems = function () {
			// localStorage.clear();
			localStorage.removeItem('isLoggedIn');
			localStorage.removeItem('id_token');
			localStorage.removeItem('access_token');
			// localStorage.removeItem('username'); //Google seems to always remember who you are, so then so will we
			localStorage.removeItem('userProfile');
		};

		this.login = function(username, password, cb) {
			//Clear old items first, to make sure they don't interfere with new login
			this.clearLocalStorageItems();

			authWrapper.login(username, password, cb, this);
			// authWrapper.authorize();
		};

		this.signup = function(username, password, cb) {
			//Clear old items first, to make sure they don't interfere with new login
			this.clearLocalStorageItems();

			authWrapper.signup(username, password, cb, this);
		};

		this.changePassword = function(username, password, newpassword, cb) {
			authWrapper.changePassword(username, password, newpassword, cb, this);
		};

		this.resetPassword = function(username, cb) {
			authWrapper.resetPassword(username, cb);
		};

		this.resetPasswordConfirmNew = function(resetpasswordtoken, username, newpassword, newpasswordconfirm, cb) {
			authWrapper.resetPasswordConfirmNew(resetpasswordtoken, username, newpassword, newpasswordconfirm, cb, this);
		};

		this.handleAuthResult = function(err, authResult) {
			if (authResult && authResult.accessToken && authResult.idToken) {
				if ($location.$$hash) {
					localStorage.setItem('hash', $location.$$hash);
				}

				this.localLogin(authResult);

				var wasLoggedIn = localStorage.getItem('isLoggedIn');
				if ((wasLoggedIn === null) || (wasLoggedIn === false))
				{
					//Not previously logged in, take user to start
					$location.path(loggedInPath);
				}
				else {
					if (($location.$$path === '/callback') || ($location.$$path === '/')) {
						$location.path(loggedInPath);
					}
				}
			} else if (err) {
				console.error(err);
			}
			else {
				console.warn('Bad call to handleAuthResult');
				$timeout(function() {
					$location.path(homePath);
				});
			}
		};

		this.checkForAdmin = function() {
			//Super Admin (access to Admin menu)
			if (this.userProfile.email === Configuration.AdminEmailAddress)
			{
				this.userProfile.isSuperAdmin = true;
				this.userProfile.isAdmin = true;
			}
			else if (Configuration.AdminEmailAddressList.indexOf(this.userProfile.email) > -1) {
				this.userProfile.isSuperAdmin = false;
				this.userProfile.isAdmin = true;
			}
			else {
				this.userProfile.isSuperAdmin = false;
				this.userProfile.isAdmin = false;
			}
		};

		//Renewal scheduler method
		this.tokenRenewalTimeout;
		this.scheduleRenewal = function(renewalAt) {
			if (this.tokenRenewalTimeout) {
				//console.log('Cancelling previous token renewal schedule');
				$timeout.cancel(this.tokenRenewalTimeout);
			}
			var _this = this;
			var delay = renewalAt - Date.now();
			console.log('Token renews in ' + Math.round(delay / 1000) + ' seconds');
			if (delay > 0) {
				this.tokenRenewalTimeout = $timeout(function() {
					_this.renewTokens();
				}, delay);

			}
			else {
				console.warn('Delay for scheduleRenewal in the past');
			}
		};

		this.setLoggedInState = function(authResult, user) {
			if (authResult && user) {
				// Set the time that the access token will expire at
				// expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();    //authResult.expiresIn shows account setting access token expiration,not id_token expiration as set in Auth0 Application setting
				var token_expiration = 86400;    //24 hours (60 * 60 * 24)
				if (window.AUTH_TOKEN_EXPIRATION) {
					token_expiration = window.AUTH_TOKEN_EXPIRATION;
				}
				try {
					var tokenExpirationFromToken = authWrapper.getTokenExpirationDate(authResult.idToken);
					if (tokenExpirationFromToken) {
						var tokenExpirationDiff = tokenExpirationFromToken - Date.now();
						token_expiration = tokenExpirationDiff / 1000;
					}
				}
				catch (tokenExpirationError) {
					console.error('tokenExpirationError: ' + tokenExpirationError);
				}
				console.log('Auth token expires in ' + token_expiration);
				var expiresAt = (token_expiration) * 1000 + new Date().getTime(); //Always assume expires in 3600 - 30 seconds (1 hour less 30 seconds) and keep silently renewing
				if (token_expiration > 3600) {
					//Limit tokenExpiration to 3600 (1 hour)
					token_expiration = 3600;
					console.log('Auth token renewal limited to ' + token_expiration);
				}
				var renewalAt = (token_expiration - 30) * 1000 + new Date().getTime(); //Always assume expires in 3600 - 30 seconds (1 hour less 30 seconds) and keep silently renewing
				// accessToken = authResult.accessToken;
				idToken = authResult.idToken;
				console.log('Token expires on ' + new Date(expiresAt).toLocaleString());
				console.log('Token will be renewed on ' + new Date(renewalAt).toLocaleString());

				// console.log(user);
				this.userProfile = user;
				this.checkForAdmin();

				// localStorage.setItem('isLoggedIn', 'true');
				this.scheduleRenewal(renewalAt);

				//Remove URL fragments
				$location.url($location.path());

				$rootScope.isAuthenticated = true;
				$rootScope.$broadcast('userProfileSet', this.userProfile);
			}
			else {
				throw new Error("Can't set logged in state.  Missing valid input.");
			}
		};

		this.localLogin = function(authResult) {
			console.log('localLogin');
			var _this = this;
			// Set isLoggedIn flag in localStorage
			// localStorage.setItem('isLoggedIn', 'true');
			localStorage.setItem('id_token', authResult.idToken);
			// localStorage.setItem('access_token', authResult.accessToken);
			authWrapper.userInfo(authResult.idToken, function(err, user) {
				if (err) {
					console.error(err);
					throw new Error("Failed local login", err);
					$location.path(homePath);
				}
				else {
					// Now you have the user's information
					try {
						localStorage.setItem('userProfile', JSON.stringify(user));
						localStorage.setItem('username', user.email);
						_this.setLoggedInState(authResult, user);
					}
					catch (err) {
						console.error(err);
					}
				}
			});
		};

		this.renewTokens = function() {
			console.log('renewTokens');
			var _this = this;
			authWrapper.checkSession({},
				function(err, result) {
					console.log('checkSession callback');
					if (err) {
						// console.error(err.error_description);
						console.error(err.data);
						$timeout(function() {
							$location.path(homePath);
						});
					} else {
						_this.localLogin(result);
					}
				}
			);
		};

		this.isTokenExpired = function(token) {
			console.log('isTokenExpired');
			return authWrapper.isTokenExpired(token, 0);
		};

		this.logout = function() {
			console.log('logout');
			clearTimeout(this.tokenRenewalTimeout);

			// Remove isLoggedIn flag from localStorage
			this.clearLocalStorageItems();
			// localStorage.removeItem('expiresIn');
			$rootScope.isAuthenticated = false;
			// Remove tokens and expiry time
			accessToken = '';
			idToken = '';

			$rootScope.$broadcast('loggedOut', this.userProfile);

			authWrapper.logout({
				return_to: window.location.origin
			});

			// $state.go('home');
			$location.path(homePath);
		};

		//Check's for current tokens in localstorage and checks to see if they're valid, renewing them if required.
		//If cached tokens and userProfile is valid, or attempt at renew tokens is successful, logged in state is set to true.
		//If not token is present, logged in state is false.
		this.checkAuthOnRefresh = function() {
			console.log('checkAuthOnRefresh');
			var token = localStorage.getItem("id_token");
			// var access_token = localStorage.getItem("access_token");

			// if (token && access_token) {
			if (token) {
				this.renewTokens();
			}
			else {
				//Not logged in
				$rootScope.isAuthenticated = false;
				$timeout(function() {
					$location.path(homePath);
				});
			}
		};

		this.getUserFromToken = function(token) {
			return authWrapper.getUserFromToken(token);
		};

		try {
			this.userProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
		}
		catch (err) {
			console.error(err);
		}

		$rootScope.$on('tokenHasExpired', function() {
			alert('Your session has expired!');
		});

	});
