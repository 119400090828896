/**
* Created by petter on 10/18/19.
*/
angular.module('app')
	.controller('ReportController', function ($scope, $controller,
		VesselModel,
		CrewModel,
		FileModel,
		SystemServiceModel,
		TaskModel,
		SystemExpenseModel,
		$rootScope, $location , Exception, $q, uiGridConstants, qm, $routeParams) {

	        angular.extend(this, $controller('GridBaseController', {$scope: $scope}));

	        //Show Yearly (pick year) or Monthly (pick month) lists of Tasks, Service Logs & Expenses (not yet published), Inventory?

		$rootScope.bodyClass = "service";
		$scope.qm = qm;
		// $scope.model = new CrewModel();
		// $scope.fileModel = new FileModel();
	        $scope.vesselModel = new VesselModel();
		$scope.taskModel = new TaskModel();
		$scope.systemExpenseModel = new SystemExpenseModel();
	        $scope.systemServiceModel = new SystemServiceModel();
	        $scope.systemServiceModel.show_expenses_column = true;
	        $scope.systemServiceModel.show_hours_column = true;
		$scope.vessel_id = $routeParams.id;
	        $scope.periodChoices = [
		        {
			        label: 'Annually',
			        value: 1
		        },
		        {
		        	label: 'Quarterly',
			        value: 2
		        },
		        {
			        label: 'Monthly',
			        value: 3
		        },
		        {
			        label: 'Custom',
			        value: 4
		        }
	        ];
	        $scope.today = new Date();
	        $scope.startYear = $scope.today.getFullYear();

	        //Set default period
	        $scope.periodSelection = $scope.periodChoices[0];   //Annual
	        $scope.FromDate = new Date($scope.startYear,0,1);
	        $scope.ToDate = new Date($scope.startYear,11,31);
	        $scope.FromDateSelection = $scope.FromDate;
	        $scope.ToDateSelection = $scope.ToDate;

	        $scope.annualChoices = [];
	        //TODO: Use account start year
	        for (var year=$scope.startYear; year >= 2017; year--) {
		        $scope.annualChoices.push(year);
	        }
	        $scope.annualSelection = $scope.annualChoices[0];

	        $scope.quarterlyChoices = [];
	        $scope.quarterlyChoices.push({label:'1st Quarter',value:0, lastday:31});
	        $scope.quarterlyChoices.push({label:'2nd Quarter',value:1, lastday:30});
	        $scope.quarterlyChoices.push({label:'3rd Quarter',value:2, lastday:30});
	        $scope.quarterlyChoices.push({label:'4th Quarter',value:3, lastday:31});
	        $scope.quarterlySelection = $scope.quarterlyChoices[0];

	        $scope.monthlyChoices = [];
	        $scope.monthlyChoices.push({label:'Jan',value:0});
	        $scope.monthlyChoices.push({label:'Feb',value:1});
	        $scope.monthlyChoices.push({label:'Mar',value:2});
	        $scope.monthlyChoices.push({label:'Apr',value:3});
	        $scope.monthlyChoices.push({label:'May',value:4});
	        $scope.monthlyChoices.push({label:'Jun',value:5});
	        $scope.monthlyChoices.push({label:'Jul',value:6});
	        $scope.monthlyChoices.push({label:'Aug',value:7});
	        $scope.monthlyChoices.push({label:'Sep',value:8});
	        $scope.monthlyChoices.push({label:'Oct',value:9});
	        $scope.monthlyChoices.push({label:'Nov',value:10});
	        $scope.monthlyChoices.push({label:'Dec',value:11});
	        $scope.monthlySelection = $scope.monthlyChoices[0];

	        // $scope.grid = $scope.createGrid($scope.model, 'report', 'Report', null, null, 100);
	        // $scope.filesGrid = $scope.createGrid($scope.fileModel, 'report-files', 'Report-Files', null, null, 100);
	        $scope.taskGrid = $scope.createGrid($scope.taskModel, 'report-tasks', 'Report-Tasks');
	        $scope.systemServicesGrid = $scope.createGrid($scope.systemServiceModel, 'report-serviceslogs', 'Report-ServicesLogs');
	        $scope.systemExpensesGrid = $scope.createGrid($scope.systemExpenseModel, 'report-expenses', 'Report-Expenses');

	        //Hide Action column
	        // $scope.taskGrid.gridOptions.columnDefs.splice($scope.taskGrid.gridOptions.columnDefs.length - 1, 1);
	        // $scope.systemServicesGrid.gridOptions.columnDefs.splice($scope.systemServicesGrid.gridOptions.columnDefs.length - 1, 1);
	        // $scope.systemExpensesGrid.gridOptions.columnDefs.splice($scope.systemExpensesGrid.gridOptions.columnDefs.length - 1, 1);

	        $scope.getTaskGridColumns = function() {
		        return [
			        $scope.qm.getDefaultIdColumn(),
			        { name:'Description', field: 'Description', enableHiding:false, sort: { priority: 1, direction: 'asc' }, filter: { placeholder: 'search description...'},
				        cellTemplate: 'components/tasks/tasks_description_template.html'},
			        { name:'State', field: 'state.name', width: '140', enableHiding:true, filter: {
					        placeholder: 'select type...',
					        condition: uiGridConstants.filter.EXACT,
					        type: uiGridConstants.filter.SELECT,
					        selectOptions: [
						        {value:'To Do', label:'To Do'},
						        {value:'In progress', label:'In progress'},
						        {value:'Done', label:'Done'}
					        ]
				        } },
			        { name:'Due Date', field: 'DueDate', type: 'date', width: '140', visible: !$scope.qm.phoneplus, cellFilter: $scope.qm.dateCellFilter, enableHiding:false, sort: { priority: 0, direction: 'asc' }, filter: { placeholder: 'search due date...'},
				        cellTemplate: 'components/tasks/tasks_expires_template.html'
			        },
			        { name:'Assigned To', field: 'assignedResource.name', enableHiding:true, visible: !$scope.qm.phoneplus, filter: {
					        placeholder: 'select type...',
					        condition: uiGridConstants.filter.EXACT,
					        type: uiGridConstants.filter.SELECT,
					        selectOptions: this.crew
				        } },
			        { name:'System', field: 'Systems_Description', enableHiding:true, visible:true, filter: { placeholder: 'search system...'} },
				        // cellTemplate: 'components/tasks/tasks_system_template.html'},
			        $scope.qm.getSystemModified(),$scope.qm.getSystemModifiedBy()
		        ];
	        };

	        $scope.getServiceGridColumns = function() {
		        return [
			        $scope.qm.getDefaultIdColumn(),
			        { name:'Date', field: 'ServiceDate', type: 'date', maxWidth: 70, cellFilter: qm.dateCellFilter, enableHiding:false, sort: { priority: 0, direction: 'desc' }, filter: { placeholder: 'search...'} },
			        { name:'Hours', field: 'Hours', visible: $scope.show_hours_column, width: '120', filter: { placeholder: 'search hours...'} },
			        { name:'Expenses', field: 'Expenses', visible: $scope.show_expenses_column, width: '120', filter: { placeholder: 'search expenses...'} },
			        { name:'Description', field: 'Description', enableHiding:false, filter: { placeholder: 'search description...'} },
			        { name:'System', field: 'Systems_Description', enableHiding:true, visible:!$scope.qm.phoneplus, filter: { placeholder: 'search system...'} },
			        { name:'Notes', field: 'Notes', visible: !$scope.qm.phoneplus, enableHiding:false, filter: { placeholder: 'search notes...'} },
			        $scope.qm.getSystemModified(),$scope.qm.getSystemModifiedBy()
		        ];
	        };

	        $scope.startWatches = function() {
		        $scope.$watch('periodSelection', function (newVal, oldVal) {
			        $scope.setDatesFromSelection(newVal, oldVal);		        });

		        $scope.$watch('annualSelection', function (newVal, oldVal) {
			        $scope.setDatesFromSelection(newVal, oldVal);		        });

		        $scope.$watch('quarterlySelection', function (newVal, oldVal) {
			        $scope.setDatesFromSelection(newVal, oldVal);		        });

		        $scope.$watch('monthlySelection', function (newVal, oldVal) {
			        $scope.setDatesFromSelection(newVal, oldVal);
		        });

		        $scope.$watch('FromDateSelection', function (newVal, oldVal) {
			        $scope.setDatesFromSelection(newVal, oldVal);
			        // $scope.FromDate = $scope.FromDateSelection;
			        // $scope.reload();
		        });

		        $scope.$watch('ToDateSelection', function (newVal, oldVal) {
			        $scope.setDatesFromSelection(newVal, oldVal);
			        // $scope.ToDate = $scope.ToDateSelection;
			        // $scope.reload();
		        });
	        };

	        $scope.setDatesFromSelection = function(newVal, oldVal) {
	        	if (newVal && (newVal != oldVal)) {
			        if ($scope.periodSelection.value === 1) {
				        //Annually
				        if ($scope.annualSelection) {
					        $scope.FromDate = new Date($scope.annualSelection,0,1);
					        $scope.ToDate = new Date($scope.annualSelection,11,31);
					        $scope.reload();
				        }
			        }
			        else if ($scope.periodSelection.value === 2) {
				        //Quarterly
				        if ($scope.quarterlySelection) {
				        }
				        else {
					        $scope.quarterlySelection = $scope.quarterlyChoices[0];
				        }
				        $scope.FromDate = new Date($scope.annualSelection,(3 * $scope.quarterlySelection.value),1);
				        $scope.ToDate = new Date($scope.annualSelection,(3 * $scope.quarterlySelection.value) + 2, $scope.quarterlySelection.lastday);
				        $scope.reload();
			        }
			        else if ($scope.periodSelection.value === 3) {
				        //Monthly
				        if (!$scope.monthlySelection) {
					        $scope.monthlySelection = $scope.monthlyChoices[0];
				        }
				        $scope.FromDate = new Date($scope.annualSelection,$scope.monthlySelection.value,1);
				        $scope.ToDate = new Date($scope.annualSelection,$scope.monthlySelection.value,31);
				        $scope.reload();
			        }
			        else if ($scope.periodSelection.value === 4) {
				        //Custom
				        $scope.FromDate = $scope.FromDateSelection;
				        $scope.ToDate = $scope.ToDateSelection;
				        $scope.reload();
			        }
		        }

	        };

	        $scope.reload = function() {
		        if ($scope.FromDate && $scope.ToDate) {
			        $scope.load();
		        }
	        };

	        $scope.load = function() {
	            var deferred = $q.defer();
		        $scope.taskGrid.gridOptions.columnDefs = $scope.getTaskGridColumns();
		        $scope.systemServicesGrid.gridOptions.columnDefs = $scope.getServiceGridColumns();

		        // $scope.model.load({vessel_id:$scope.vessel_id}).then(function(result) {
	            //     console.log('Loaded crew items:' + result.length);
	            //     deferred.resolve(result);
			// }, function (error) {
	            //     Exception.handleError(error, deferred);
			// });
	            // $scope.fileModel.load(1, $scope.vessel_id).then(function(result) {
		        //     console.log('Loaded files items:' + result.length);
		        //     deferred.resolve(result);
	            // }, function (error) {
		        //     Exception.handleError(error, deferred);
	            // });
		        $scope.taskModel.loadReport($scope.vessel_id, $scope.FromDate, $scope.ToDate).then(function(result) {
			        $scope.systemServiceModel.loadReport($scope.vessel_id, $scope.FromDate, $scope.ToDate).then(function(result) {
				        deferred.resolve(result);
				        // $scope.systemExpenseModel.loadReport($scope.vessel_id, $scope.FromDate, $scope.ToDate).then(function(result) {
					    //     deferred.resolve(result);
				        // }, function (error) {
					    //     Exception.handleError(error, deferred);
				        // });
			        }, function (error) {
				        Exception.handleError(error, deferred);
			        });
	            }, function (error) {
		            Exception.handleError(error, deferred);
	            });
			return deferred.promise;
		};

		$scope.loadVessel = function () {
			$scope.vesselModel.getItem($scope.vessel_id).then(function(result) {
				$scope.vessel = result;
				$scope.load().then(function () {
					$scope.startWatches();
				});
			}, function (error) {
				Exception.errorFn(error);
			});
		};

	        $scope.loadVessel();

	});
