/**
 * Created by petter on 7/13/16.
 */
'use strict';

angular.module('app')
	.factory('StripeChargesModel', function ($q, Model, StripeCharges) {
		function StripeChargesModel() {
			Model.call(this, StripeCharges);
		}

		StripeChargesModel.prototype = Object.create(Model.prototype);
		StripeChargesModel.prototype.constructor = StripeChargesModel;

		StripeChargesModel.prototype.load = function(params) {
			return Model.prototype.load.call(this, params);
		};

		StripeChargesModel.prototype.saveItem = function(data) {
			// data.VesselUserRole_Vessel = this.vessel_id;
			return Model.prototype.saveItem.call(this, data);
		};

		return StripeChargesModel;
	});

angular.module('app')
	.factory('StripeCharges', function(ItemBase, Configuration) {
		function StripeCharges() {
			ItemBase.call(this);
		}

		StripeCharges.prototype = Object.create(ItemBase.prototype);

		StripeCharges.dataColumns = [];
		// SubscriptionClientToken.templateUrl = 'quartermaster/crew/crew_edit.html';
		StripeCharges.url = Configuration.API + '/stripe_charges/:id';

		StripeCharges.prototype.onNewItem = function() {
			// this.VesselUserRole_Vessel = this.modelClass.type_id;
		};

		return StripeCharges;
	});

