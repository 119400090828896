angular.module('app')
	.controller('SystemServicesController', function ($scope, SystemModel, SystemServiceModel, SystemService, $rootScope, $location , Exception, $q, uiGridConstants, qm, $routeParams, Constants, $controller) {
		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));
		$rootScope.bodyClass = "service";
		$scope.qm = qm;
		$scope.model = new SystemServiceModel();
		$scope.systemModel = new SystemModel();
		$scope.grid = {};
		$scope.grid.appScope = $scope;
		$scope.system_id = $routeParams.id;
		$scope.model.show_hours_column = false;
	        $scope.model.show_expenses_column = false;



		$scope.setGridOptions = function() {
			$scope.gridOptions = {
	                enableGridMenu: true,
				gridMenuShowHideColumns: true,
	                exporterMenuCsv: true,
	                exporterMenuAllData: false,
	                exporterMenuVisibleData: true,
	                exporterCsvFilename: 'Quartermaster-Services-Log.csv',
	                exporterMenuExcel: false,
	                exporterMenuPdf: false,
	                // exporterPdfFilename: 'Quartermaster-Logs.pdf',
	                // exporterPdfDefaultStyle: {fontSize: 9},
	                // exporterPdfTableStyle: {margin: [30, 30, 30, 30]},
	                // exporterPdfTableHeaderStyle: {fontSize: 10, bold: true, italics: true, color: 'blue'},
	                // exporterPdfHeader: { text: "Ships", style: 'headerStyle' },
	                // exporterPdfFooter: function ( currentPage, pageCount ) {
		             //    return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
	                // },
	                // exporterPdfCustomFormatter: function ( docDefinition ) {
		             //    docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
		             //    docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
		             //    return docDefinition;
	                // },
	                // exporterPdfOrientation: 'portrait',
	                // exporterPdfPageSize: 'LETTER',
	                // exporterPdfMaxGridWidth: 500,

				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: true,
				enableColumnResizing: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				columnDefs: $scope.model.getGridColumns(),
				data: 'model.items',
				tabs: [],
	                rowTemplate:
		                '<div ng-class="{ \'grid-row-zero-quantity\': grid.appScope.rowFormatter( row ) }">' +
		                '  <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }"  ui-grid-cell></div>' +
		                '</div>',
	                exporterSuppressColumns: ['Id', 'Actions'],
	                importerNewObject: SystemService,
	                importerShowMenu: true,
	                importerDataAddCallback: function (grid, newObjects) {
	                	//TODO: This needs to be shared by all grids
		                for (var newObjectCounter=0;newObjectCounter < newObjects.length; newObjectCounter++) {
			                var newObject = newObjects[newObjectCounter];
			                //Strip blank string, i.e. ""
			                for (var property in newObject) {
				                if ((typeof newObject[property] === 'string') && (newObject[property] === "")) {
					                // Skip blank strings (it's not a number and will cause ,, in SQL statements
					                delete newObject[property];
				                }
			                }
			                $scope.model.saveItem(newObject).then(function (newItem) {
				                $scope.model.items.unshift(newItem);
			                }, function(err) {
				                console.log(err);
			                });
		                }
		                // $scope.model.items = $scope.model.items.concat(newObjects);
	                },
	                onRegisterApi: function( gridApi ) {
					$scope.gridApi = gridApi;
				}
			};
		};
		$scope.setGridOptions();

		$scope.$on('screen-changed', function(event, args) {
			$scope.gridOptions.columnDefs = $scope.model.getGridColumns();
			$scope.gridApi.core.refresh();
		});

		$scope.load = function() {
	            var deferred = $q.defer();
			$scope.gridOptions.loading = true;
			$scope.systemModel.getItem($scope.system_id).then(function(result) {
				$scope.system = result;
				$scope.model.show_hours_column = $scope.system.TrackHours;
	                $scope.model.show_expenses_column = $scope.system.TrackExpenses;
				$scope.gridOptions.columnDefs = $scope.model.getGridColumns();
				$scope.model.load($scope.system).then(function(result) {
					var tagKeys = [];
					$scope.gridOptions.tabs = [{name:'All'}];
					for (var j = 0; j < result.length; j++) {
						var offer = result[j];
						if (offer.status) {
							if (tagKeys.indexOf(offer.status) < 0) {
								tagKeys.push(offer.status);
								$scope.gridOptions.tabs.push({name:offer.status, field:'status', filter:offer.status});
							}
						}
					}
					$scope.gridOptions.loading = false;
	                    deferred.resolve(result);
				}, function (error) {
					Exception.errorFn(error);
				});
			}, function (error) {
				Exception.errorFn(error);
			});
	            return deferred.promise;
		};
		$scope.load();

		$scope.getFilesUrl = function(row) {
			return '/systems/'+row.entity.Id+'/services/files/'+Constants.FileComponent_SystemService;
		};

		$scope.listenForChanges('SystemServices');

	});
