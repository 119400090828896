/**
 * Created by petter on 01/13/21.
 */
'use strict';

angular.module('app')
	.factory('VesselCopyModel', function ($q, Model, VesselCopy, s3factory, Constants, common) {
		function VesselCopyModel() {
			Model.call(this, VesselCopy);
			// this.types = [
			//     {
			//         Type:'Sail',
			//         Id:1
			//     },
			//     {
			//         Type:'Power',
			//         Id:2
			//     },
			//     {
			//         Type:'Tender',
			//         Id:3
			//     },
	        //     {
		    //         Type:'Catamaran',
		    //         Id:4
	        //     }
			// ]
		}

		VesselCopyModel.prototype = Object.create(Model.prototype);
		VesselCopyModel.prototype.constructor = VesselCopyModel;

		// VesselCopyModel.prototype.load = function(id, params) {
		//     return Model.prototype.load.call(this, params);
		// };
		//
		// VesselCopyModel.prototype.onLoadedItem = function(item) {
		//     item.profileUrl = s3factory.getProfilePhotoUrl(item.Id, Constants.FileComponent_VesselProfile, item.uuid, item.FileName);
		//     if (!item.profileUrl) {
		//         if (item.Type) {
		//             item.profileUrl = './img/default-yacht-'+item.Type.toLowerCase()+'.svg';
		//         }
		//         else {
		//             item.profileUrl = './img/default-yacht-power.svg';
		//         }
		//     }
		//     // item.templateUrl = s3factory.getProfilePhotoUrl(Constants.FileComponent_VesselTemplate, item.Template_uuid, item.Template_FileName);
		//         item.id = item.Id;
		//     console.log('Loaded ' + item.Name);
		// };

		VesselCopyModel.prototype.isValidItem = function(item) {
			this.isItemDirty = true;
			item.isItemDirty = true;
			if (!item.Name || !item.Vessel_VesselType){
				return false;
			}
			return true;
		};

	    // VesselCopyModel.prototype.getGridColumns = function() {
		//     return [
		// 	    common.getDefaultIdColumn(),
		// 	    { name:'Photo', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '138',
		// 		    cellTemplate: 'components/vessels/vessels_photo_template.html'
		// 	    },
		// 	    { name:'Name', field: 'Name', enableHiding:false, sort: { priority: 0, direction: 'asc' },
		// 		    visible: !common.phone,
		// 		    filter: { placeholder: 'search name...'} },
		// 	    { name:'Type', field: 'Type', visible: !common.phoneplus, enableHiding:true, filter: { placeholder: 'search type...'} },
		// 	    { name:'Manufacturer', field: 'Manufacturer', visible: !common.phoneplus, enableHiding:true, filter: { placeholder: 'search manufacturer...'} },
		// 	    { name:'Model', field: 'Model', visible: !common.mobile, enableHiding:true, filter: { placeholder: 'search model...'} },
		// 	    common.getSystemModified(),common.getSystemModifiedBy(),
		// 	    { name:'Actions', width: '220', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false,
		// 		    cellTemplate: 'components/vessels/vessels_action_template.html'
		// 	    }
		//     ];
	    // };

		return VesselCopyModel;
	});

angular.module('app')
	.factory('VesselCopy', function(ItemBase, Configuration) {
		function VesselCopy() {
			ItemBase.call(this);
		}

		VesselCopy.prototype = Object.create(ItemBase.prototype);

		VesselCopy.dataColumns = ['sourceYachtId', 'Vessel_User', 'Name', 'Vessel_VesselType', 'Vessel_File', 'Vessel_TemplateFile','Manufacturer', 'Model', 'IncludeServiceLogs', 'IncludeTasks'];
		VesselCopy.templateUrl = 'components/vessels/vessels_copy.html';
		VesselCopy.url = Configuration.API + '/vessels_copy/:id';

		return VesselCopy;
	});

