/**
 * Created by petter on 3/24/16.
 */
'use strict';
angular.module('app')
	.controller('ErrorModalController', function ($scope, $uibModalInstance, err) {
		$scope.err = err;

		$scope.ok = function () {
			$uibModalInstance.close();
		};

		$scope.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};

		$scope.showFeedback = function() {
			$uibModalInstance.dismiss('cancel');
	        Sentry.showReportDialog();
			// try {
			//     throw $scope.err;
			// }
			// catch (newErr) {
			//     Raven.captureException(newErr);
			//     Raven.showReportDialog();
			// }
		};

	});