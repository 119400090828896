angular.module('app')
	.controller('CouponController', function ($rootScope, $scope, authService, $routeParams, $location, Exception, Configuration) {
		// $rootScope.bodyClass = "coupon";
		$scope.coupon = $routeParams.coupon;
		if ($scope.coupon) {
			//If coupon, save it
			try {
				localStorage.setItem('coupon', $scope.coupon);
				//Track coupon
				if (window.ga && (location.hostname === Configuration.PrimaryDomin)) {
					ga('send', 'event', 'Funnel', 'coupon', $scope.coupon);
				}
			}
			catch (err) {
				console.error(err);
				var error = new Error('Local storage must be enabled to use Quartermaster.  Perhaps you are using "private" browsing?', err);
				Exception.errorFn(error);
			}

			if ($rootScope.isAuthenticated) {
				//If already signed in, go straight to account to show buy options and promo
				$location.path("/account");
			}
			else {
				//Go home //We think forcing signup might scare some people away, so no forced signup
				$location.path(Configuration.HomePath);
			}
		}
		else {
			$location.path(Configuration.LoggedInPath);
		}


	});
