/**
 * Created by petter on 2/14/19.
 */
'use strict';

angular.module('app')
	.factory('FleetServiceScheduleModel', function ($q, Model, SystemScheduleModel, FleetServiceSchedule) {
		function FleetServiceScheduleModel() {
	        Model.call(this, FleetServiceSchedule);
		}

		FleetServiceScheduleModel.prototype = Object.create(SystemScheduleModel.prototype);
		FleetServiceScheduleModel.prototype.constructor = FleetServiceScheduleModel;

		FleetServiceScheduleModel.prototype.load = function(vessel_id_list) {
	        return Model.prototype.load.call(this, {vessel_id_list: vessel_id_list});
		};

		FleetServiceScheduleModel.prototype.saveItem = function() {
        	throw new Error('Not supported');
		};

		FleetServiceScheduleModel.prototype.isValidItem = function(item) {
	        return false;
		};

		return FleetServiceScheduleModel;
	});

angular.module('app')
	.factory('FleetServiceSchedule', function(SystemSchedule, Configuration) {
		function FleetServiceSchedule() {
	        SystemSchedule.call(this);
		}

		FleetServiceSchedule.prototype = Object.create(SystemSchedule.prototype);

		FleetServiceSchedule.dataColumns = [];
		FleetServiceSchedule.templateUrl = '';
		FleetServiceSchedule.url = Configuration.API + '/fleetserviceschedules/:id';

	    return FleetServiceSchedule;
	});

