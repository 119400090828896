/**
 * Created by petter on 01/12/21.
 */
'use strict';
angular.module('app')
	.controller('VesselsTransferOwnershipModalController', function ($scope, $uibModalInstance, CrewModel, common, $q, title, modalObject, model) {
		$scope.modalObjectSource = modalObject;
		if (modalObject.constructor && (modalObject.constructor.name === 'ItemBase')) {
	        $scope.modalObject = Object.assign({}, modalObject);  //Create a deep copy, so we can cancel without saving, but also inherit class properties
		}
		else {
			$scope.modalObject = angular.extend({}, modalObject); //Create a shallow copy, so we can cancel without saving
		}
		$scope.model = model;
		$scope.title = title;
		// $scope.newOwner = newOwner;
		// $scope.selectedOwner = null;

	    $scope.crewModel = new CrewModel();
		$scope.load = function () {
	        var deferred = $q.defer();
	        common.showWorking();
	        $scope.crew = [];
	        $scope.crewModel.load({vessel_id:modalObject.Id}).then(function(result) {
		        console.log('Loaded crew items:' + result.length);
		        for (var i=0;i<result.length;i++) {
			        var crewMember = result[i];
		        	if ((crewMember.VesselUserRole_VesselRole === 0)
				        && (crewMember.UserId)
				        && (crewMember.UserId != modalObject.Vessel_User)
			        ) {
		        		$scope.crew.push(crewMember);
			        }
		        }
		        common.hideWorking();
		        deferred.resolve(result);
	        }, function (error) {
		        common.hideWorking();
		        Exception.handleError(error, deferred);
	        });
	        return deferred.promise;
		};
		$scope.load();

		$scope.ok = function () {
	        // $scope.newOwner.sourceYachtId = sourceVessel.Id;
	        // if ($scope.selectedOwner) {
		    //     $scope.newOwner.destinationOwnerUserId = $scope.selectedOwner.UserId;
		    //     $scope.newOwner.destinationOwnerUserName = $scope.selectedOwner.UserName;
	        // }

	        if (!$scope.model.isValidItem($scope.modalObject)){
		        return;
	        }

	        //Shallow copy back changes, before returning
	        angular.extend($scope.modalObjectSource, $scope.modalObject);
	        $uibModalInstance.close($scope.modalObjectSource);
		};

		$scope.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};
	});
