angular.module('app')
	.controller('KanbanController', function ($scope, TaskModel, $rootScope, $location , Exception, $q, uiGridConstants, qm) {
		$rootScope.bodyClass = "task";
		$scope.qm = qm;
		$scope.model = new TaskModel();
		$scope.grid = {};
		$scope.grid.appScope = $scope;

		// $scope.gridOptions = {
		//     showColumnFooter: false,
		//     enableSorting: true,
		//     enableFiltering: true,
		//     enableColumnResizing: true,
		//     enablePaginationControls: false,
		//     // paginationPageSizes: [3, 5, 10, 25, 50, 75, 100],
		//     // paginationPageSize: 5,
		//     columnDefs: [
		//         { name:'Id', field: 'Id', enableHiding:false, width: '70', filter: { placeholder: '...'} },
		//         { name:'Due Date', field: 'DueDate', width: '140', cellFilter: qm.dateCellFilter, enableHiding:false, sort: { priority: 0, direction: 'desc' }, filter: { placeholder: 'search due date...'} },
		//         { name:'Description', field: 'Description', enableHiding:false, sort: { priority: 1, direction: 'asc' }, filter: { placeholder: 'search description...'} },
		//         // { name:'Created', field: 'Created', cellFilter: qm.dateTimeCellFilter, enableHiding:false, filter: { placeholder: 'search first...'} },
		//         // { name:'CreatedBy', field: 'CreatedBy', enableHiding:false, filter: { placeholder: 'search first...'} },
		//         { name:'Modified', field: 'Modified', width: '140', cellFilter: qm.dateTimeCellFilter, enableHiding:true, filter: { placeholder: 'search modified...'} },
		//         { name:'ModifiedBy', field: 'ModifiedBy', width: '180', enableHiding:true, filter: { placeholder: 'search modified by...'} },
		//         { name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '80',
		//             cellTemplate: 'components/tasks/tasks_action_template.html'
		//         }
		//     ],
		//     data: 'model.items',
		//     tabs: [],
		//     onRegisterApi: function( gridApi ) {
		//         $scope.gridApi = gridApi;
		//     }
		// };

		$scope.load = function() {
			if (qm.selectedVessel.Id) {
				$scope.model.load(qm.selectedVessel.Id).then(function(result) {
					// var tagKeys = [];
					// $scope.gridOptions.tabs = [{name:'All'}];
					// for (var j = 0; j < result.length; j++) {
					//     var offer = result[j];
					//     if (offer.status) {
					//         if (tagKeys.indexOf(offer.status) < 0) {
					//             tagKeys.push(offer.status);
					//             $scope.gridOptions.tabs.push({name:offer.status, field:'status', filter:offer.status});
					//         }
					//     }
					// }
					console.log('Loaded task items:' + $scope.model.items.length);
					// $scope.model.loadCrew().then(function(result) {
					//     console.log('Loaded crew items:' + $scope.model.crew.length);
					// }, function (error) {
					//     Exception.errorFn(error);
					// });
					// $scope.initKanbanBoard();
				}, function (error) {
					Exception.errorFn(error);
				});
			}
		};

		$scope.$watch('qm.selectedVessel', function () {
			if (qm.selectedVessel) {
				$scope.load();
			}
		});

		$scope.getServiceUrl = function(row) {
			return '/tasks/'+row.entity.Id+'/services';
		};

		$scope.getScheduleUrl = function(row) {
			return '/tasks/'+row.entity.Id+'/schedules';
		};

		//Kanban
		$scope.initKanbanBoard = function() {
			var nextIteration = { groups: [], items: [] };

			$scope.states = $scope.model.states;
			$scope.groups = $scope.model.groups;
			// $scope.items = items;
			$scope.items = $scope.model.items;
			$scope.assignableResources = $scope.model.crew;    // assignableResources;
			// Initialize a newly created item before adding it to the user interface.
			$scope.initializeNewItem = function (item) {
				item.assignedResource = null;
				console.log('A new item was created.');
			};
			// Allow item deletion by clicking a button in the user interface.
			$scope.deleteItem = function (item) {
				items.splice(items.indexOf(item), 1);
				console.log('Item ' + item.name + ' was deleted.');
			};
			// Handle changes.
			$scope.onItemStateChanged = function (item, state) {
				console.log('State of ' + item.name + ' was changed to: ' + state.name);
			};
			$scope.onItemGroupChanged = function (item, group) {
				console.log('Group of ' + item.name + ' was changed to: ' + group.name);
			};
			// Move items to the next iteration.
			$scope.nextIteration = nextIteration;
			$scope.moveItemToNextIteration = function (type, index) {
				if (type === kanbanComponent.Controls.KanbanBoard.types.group) {
					// Move an entire group (story) and all its items.
					var group = groups[index];
					for (var i = 0; i < items.length; i++) {
						var item = items[i];
						if (item.group === group) {
							items.splice(i--, 1);
							nextIteration.items.push(item);
						}
					}
					groups.splice(index, 1);
					if (nextIteration.groups.indexOf(group) < 0)
						nextIteration.groups.push(group);
					console.log('Group ' + group.name + ' and its items were moved to next iteration.');
				}
				else {
					// Move a single item, and copy the group (story) if needed.
					var item = items[index];
					items.splice(index, 1);
					nextIteration.items.push(item);
					var group = item.group;
					if (nextIteration.groups.indexOf(group) < 0)
						nextIteration.groups.push(group);
					console.log('Item ' + item.name + ' was moved to next iteration.');
				}
				// $scope.$apply();
			};
		};
		$scope.initKanbanBoard();

	});
