/**
 * Created by petter on 4/05/16.
 */
// Use this directive to add a file upload/download fa icon as an action menu in a grid.  Must supply item (a data object item with property .id as column identifier and .file_id as file identifier).
'use strict';

angular.module('app')
	.directive('fileAction', function ($window, $uibModal, s3factory, Exception, qm) {
		return {
			restrict: 'E',
			scope: {
				component_id: '=fileComponentId',   //The number of the component
				fileitem: '=fileItem',  //The data item (must have an id and file_id property)
				canDelete: '=fileCanDelete',    //If the delete should show up (optional)
				tag: '=fileTag' //The file tag used (typically a key for the type of file used, such as )
			},
			templateUrl: 'app/core/fileaction.html',
			controller: ['$scope', '$uibModal', 's3factory', 'Exception', function($scope, $uibModal, s3factory, Exception) {
				if (!$scope.fileitem) {
					console.error('Must supply file-item for fileaction directive');
				}

				if (!$scope.component_id) {
					console.error('Must supply file-component-id for fileaction directive');
				}
				if (!$scope.tag) {
					console.error('Must supply file-tag for fileaction directive');
				}

				$scope.fileChanged = function(e) {
					var item = $scope.$root.file_action_directive_clickedItem;
					if (!$scope.component_id || !item.id || !$scope.tag) {
						throw new Error('Missing parameters for file directive');
					}
					//var target = e.target;
					var files = e.target.files;
					if (files.length > 0) {
						var file = files[0];
						//var item = $scope.fileitem;
						console.log(item.id +':' + file.name);

						$scope.uploadWithProgressDialog(file, item);
					}
				};

				$scope.clickUpload = function(item){
					$scope.$root.file_action_directive_clickedItem = item;
					console.log('clicked ' + item.id);
					// angular.element('#fileactionupload').trigger('click');
	                    var element = document.getElementById('fileactionupload');
	                    element.click();

				};

				$scope.getFileLink = function(item) {
					s3factory.getSignedUrl($scope.component_id, item.file_id).then(function(response){
						$window.location.href = response;
					});
				};

				$scope.deleteFile = function(item) {
					s3factory.deleteObject($scope.component_id, item.id, item.file_id,
						function(data, status, headers, config) {
							//Success
							$scope.fileitem.file_id = null;
						},
						function(data, status, headers, config) {
							//Error
							Exception.errorFn(data);
						}
					);
				};

				$scope.uploadWithProgressDialog = function(file, item) {
					$uibModal.open({
						templateUrl: "app/directives/fileaction-progress.html",
						controller: 'fileActionProgressCtrl',
						resolve: {
							file: function () {
								return file;
							},
							item: function () {
								return item;
							},
							component_id: function () {
								return $scope.component_id;
							},
							tag: function () {
								return $scope.tag;
							}
						}
					});
				};
			}]
		};
	})
	.controller('fileActionProgressCtrl', function ($scope, $modalInstance, s3factory, file, item, component_id, tag, $timeout) {
		$scope.file = file;
		$scope.item = item;
		$scope.component_id = component_id;
		$scope.tag = tag;
		$scope.title = 'Upload progress';

		$scope.file.loaded = 0;
		$scope.file.total = $scope.file.size;
		$scope.file.percent = 0;

		//Perform the upload right away in this controller, so we can track progress
		s3factory.putObject($scope.file, qm.selectedVessel.Id, $scope.component_id, $scope.item.id, $scope.tag, null, null, null, null,
			function(progress) {
				//Progress
				//console.log('upload progress:' + $scope.file.percent);
				$timeout(function() {
					$scope.$apply();
				}, 1);
			},
			function(result, err) {
				//Done
				$modalInstance.close('ok');
				if (err) {
					Exception.errorFn(err);
				}
				else {
					item.file_id = result.id;
				}
			})
			.then(function(result){
				console.log(result);
				return result;
			});

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	});
