angular.module('app')
	.controller('DashboardController', function ($scope, VesselModel, DashboardsModel, DashboardModel, DashboardSystemModel, $rootScope, $location , Exception, $q, qm, s3factory, Constants, $window, debounce, $uibModal, common, $controller) {
		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));
		$rootScope.bodyClass = "dashboard";
		$scope.qm = qm;
		$scope.model = new DashboardModel();
		$scope.dashboardSystemModel = new DashboardSystemModel();
		$scope.dashboardsModel = new DashboardsModel();
		$scope.isEditing = false;
		$scope.activeTab = 0;
		$scope.vesselModel = new VesselModel();

		$scope.checkOrientation = function () {
			if ($window.innerHeight > $window.innerWidth) {
				$scope.isPortrait = true;
				console.log("PORTRAIT");
			}
			else {
				$scope.isPortrait = false;
				console.log("LANDSCAPE");
			}
		};

		$scope.swipeLeft = function() {
			console.log('swipeLeft:' + $scope.activeTab);
			if ($scope.activeTab < ($scope.tabs.length - 1)) {
				$scope.activeTab = $scope.activeTab + 1;
				$scope.initializeCanvas($scope.tabs[$scope.activeTab]);
			}
		};

		$scope.swipeRight = function() {
			console.log('swipeRight:' + $scope.activeTab);
			if ($scope.activeTab > 0) {
				$scope.activeTab = $scope.activeTab -1;
				$scope.initializeCanvas($scope.tabs[$scope.activeTab]);
			}
		};

		$scope.load = function() {
			if (qm.selectedVessel && !$scope.isEditing) {
				$scope.dashboard = null;    //Reset dashboard to force reload if called a second time
				$scope.dashboardsModel.load(qm.selectedVessel).then(function (result) {
					var tagKeys = [];
					$scope.tabs = [];
					for (var j = 0; j < result.length; j++) {
						var dashboard = result[j];
						if (dashboard.Name) {
							if (tagKeys.indexOf(dashboard.Name) < 0) {
								tagKeys.push(dashboard.Name);
								$scope.tabs.push({
									name: dashboard.Name,
									field: 'Name',
									filter: dashboard.Name,
									dashboard: dashboard
								});
							}
						}
					}
					if ($scope.tabs.length === 0) {
						$scope.noDashboardFound = true;
						$scope.initializeCanvas({dashboard: null});
					}
					else {
						//If we only have one tab, we won't display tabs and we should just load a canvas for that tab
						$scope.noDashboardFound = false;
						$scope.initializeCanvas($scope.tabs[0]);
					}
					//Set system vessel_id, so we can add new systems directly from the dashboard
					$scope.model.vessel_id = qm.selectedVessel.Id;
					$scope.dashboardSystemModel.vessel_id = qm.selectedVessel.Id;
				}, function (error) {
					Exception.errorFn(error);
				});
			}
		};

		$scope.$watch('qm.selectedVessel', function () {
			if (qm.selectedVessel) {
				$scope.load();
				// $scope.initializeCanvas();
			}
			else {
				common.hideWorking();
			}
		});

		$scope.$watch('qm.vesselList', function () {
			if (qm.vesselList && (qm.vesselList.length === 0)) {
				$scope.showSetupWizard = true;
				common.hideWorking();
			}
		});

		$scope.yachtAdded = function () {
			$scope.showSetupWizard = false;
			qm.loadVessels();
		};

		$scope.systemAdded = function() {
			$scope.initializeCanvas($scope.tabs[$scope.activeTab]);
		};

		$scope.tabSelected = function(tab) {
			$scope.initializeCanvas(tab);
		};

		$scope.getServiceUrl = function(row) {
			if (!IS_IONIC) {
				return '/systems/'+row.Id+'/services';
			}
			else {
				return '#/tab/systems/'+row.Id;
			}
		};

		$scope.getSummaryUrl = function(row) {
			if (!IS_IONIC) {
				if (row.System_SystemType === 1) {
					//Storage system
					return '/storage/'+row.Id+'/items';
				}
				else {
					//Regular system
					return '/systems/'+row.Id+'/summary';
				}
			}
			else {
				if (row.System_SystemType === 1) {
					//Storage system
					return '#/tab/storage/'+row.Id;
				}
				else {
					//Regular system
					return '#/tab/systems/'+row.Id;
				}
			}
		};

		$scope.loadCanvas = function() {
			var stage = new createjs.Stage("dashboardCanvas");

			var circle = new createjs.Shape();
			circle.graphics.beginFill("DeepSkyBlue").drawCircle(0, 0, 50);
			circle.x = 100;
			circle.y = 100;
			stage.addChild(circle);

			stage.update();
		};
		// $scope.loadCanvas();

		// var objScreen;

		var mouseTarget;	// the display object currently under the mouse, or being dragged
		var dragStarted;	// indicates whether we are currently in a drag operation
		var offset;
		var update = true;

		var w = angular.element($window);
		w.bind('resize', function () {
			console.log('resize');
			$scope.screenRedraw();
		});

		$scope.$on("$destroy",function (){
			// $(window).off("resize.doResize"); //remove the handler added earlier
			console.log('$destroy: resize.doResize');
			w.unbind('resize');

			createjs.Ticker.removeAllEventListeners();

			if ($scope.Queue) {
				$scope.Queue.removeAllEventListeners();
			}
			if ($scope.Stage) {
				$scope.Stage.removeAllEventListeners();
			}
			console.log('$destroy: removed createjs event listeners');
		});

		$scope.showHideUnplacedSystems = function() {
			for (var i = 0; i<$scope.containers.length; i++) {
				var container = $scope.containers[i];
				if (!container.system.x || !container.system.y) {
					container.visible = $scope.isEditing;
					// console.log(container.system.Id + ' unplaced:' + container.visible);
					container.removeContainer.visible = false;
				}
				else {
					container.removeContainer.visible = $scope.isEditing;
				}
			}
			$scope.Stage.update();
		};

		$scope.setAllSystemsRotation = function(rotation) {
			if ($scope.containers) {
				for (var i = 0; i < $scope.containers.length; i++) {
					var container = $scope.containers[i];
					if (container.system.x && container.system.y) {
						container.rotation = rotation;
					}
				}
				$scope.Stage.update();
			}
		};

		$scope.editDashboard = function() {
			$scope.isEditing = !$scope.isEditing;
			$scope.showHideUnplacedSystems();
		};

		$scope.saveDashboard = function() {
			$scope.isEditing = !$scope.isEditing;
			$scope.showHideUnplacedSystems();
		};

		$scope.saveSystem = debounce(1000, function (system) {
			// Do things here.
			if (system) {
				console.log('Saving system');
				$scope.model.saveItem(system);
			}
		}, false);

		$scope.removeSystem = debounce(1000, function (system) {
			// Do things here.
			if (system) {
				console.log('Removing system');
				$scope.model.removeItem(system).then(function () {
					$scope.initializeCanvas($scope.tabs[$scope.activeTab]);
				});
			}
		}, false);

		$scope.rotate = function () {
			$scope.isPortrait = !$scope.isPortrait;
			$scope.screenRedraw();
		};

		function getOrientation() {
			// $scope.Stage.regX = $scope.Stage.canvas.width /2;
			// $scope.Stage.regY = $scope.Stage.canvas.height/2;
			if ($scope.isPortrait) {
				$scope.Stage.x = 0;
				$scope.Stage.y = $scope.Stage.canvas.height;
				$scope.Stage.y = $scope.Stage.canvas.height;
				$scope.Stage.rotation = -90;
			}
			else {
				$scope.Stage.x = 0;
				$scope.Stage.y = 0;
				$scope.Stage.rotation = 0;
			}
			//TODO: Need to re-calculate size and scales.  We need to do this as a method so it can also be re-used in redraw
		}

		function setSizeAndScale() {
			$scope.checkOrientation();
			//Set canvas size
			// var width = $('#dashboardCanvas').width();
			// var height = $('#dashboardCanvas').height();
			var dashboardContainer = document.getElementById('dashboardContainer');
			if (dashboardContainer) {
				var width = dashboardContainer.offsetWidth;
				var height = dashboardContainer.offsetHeight;
				// var width = $('#dashboardContainer').width();
				// var height = $('#dashboardContainer').height();
				var dashboardCanvas = document.getElementById('dashboardCanvas');
				if (dashboardCanvas) {
					dashboardCanvas.style.maxWidth = width + "px";
					dashboardCanvas.style.maxHeight = height + "px";

					// $('#dashboardCanvas').css('height',height);
					var portraitFactor = 1.0;

					//Determine scale
					//Set canvas height based on image height and scale
					if ($scope.LayoutBitmap.image) {
						// console.warn('isPortrait:' + $scope.isPortrait + ' ' + width + 'x' + height);
						$scope.LayoutScale = width / $scope.LayoutBitmap.image.width;
						$scope.Stage.scaleX = $scope.LayoutScale;
						$scope.Stage.scaleY = $scope.LayoutScale;
						$scope.layoutCanvas.width = width;
						$scope.layoutCanvas.height = $scope.LayoutBitmap.image.height * $scope.LayoutScale;
						if ($scope.isPortrait) {
							$scope.LayoutScale = width / $scope.LayoutBitmap.image.height;
							// $('#dashboardCanvas').css('width',$scope.LayoutBitmap.image.width * $scope.LayoutScale);
							$scope.Stage.scaleX = $scope.LayoutScale;
							$scope.Stage.scaleY = $scope.LayoutScale;
							$scope.layoutCanvas.height = $scope.LayoutBitmap.image.width * $scope.LayoutScale;
							$scope.layoutCanvas.width = $scope.LayoutBitmap.image.height * $scope.LayoutScale;
						}
						// $('#dashboardCanvas').css('width',width);
						// $('#dashboardCanvas').css('height',$scope.layoutCanvas.height);
					} else {
						$scope.LayoutScale = width / 1000;
						$scope.Stage.scaleX = $scope.LayoutScale;
						$scope.Stage.scaleY = $scope.LayoutScale;
						$scope.layoutCanvas.height = 500 * $scope.LayoutScale;
						if ($scope.isPortrait) {
							$scope.LayoutScale = height / 500;
							$scope.layoutCanvas.height = 1000 * $scope.LayoutScale;
							$scope.layoutCanvas.width = 500 * $scope.LayoutScale;

						}
					}

					if ($scope.isPortrait) {
						$scope.Stage.x = 0;
						$scope.Stage.y = $scope.Stage.canvas.height;
						$scope.Stage.y = $scope.Stage.canvas.height;
						$scope.Stage.rotation = -90;
					} else {
						$scope.Stage.x = 0;
						$scope.Stage.y = 0;
						$scope.Stage.rotation = 0;
					}

					console.log("Scale:" + $scope.LayoutScale + " Height:" + $scope.layoutCanvas.height + " Width:" + $scope.layoutCanvas.width);
				}
			}
		}

		$scope.screenRedraw = debounce(500, function () {
			console.log('screenRedraw');
			if ($scope.layoutCanvas) {
				setSizeAndScale();
				if ($scope.isPortrait) {
					$scope.setAllSystemsRotation(90);
				}
				else {
					$scope.setAllSystemsRotation(0);
				}
				if ($scope.Stage) {
					$scope.Stage.update();
				}
			}
		}, false);

		$scope.initializeCanvas = function(tab) {
			// //Skip, if already initialized (this caused add system to NOT update unplaced systems list)
			// if ($scope.dashboard && ($scope.dashboard.Id === tab.dashboard.Id)) {
			// 	//This tab already initialized
			// 	return;
			// }
			// Get an instance of the Canvas
			var dashboard = tab.dashboard;
			$scope.dashboard = tab.dashboard;
			// $scope.layoutCanvas = $("#dashboardCanvas")[0];
			$scope.layoutCanvas = document.getElementById("dashboardCanvas");

			// $scope.screenRedraw();
			// var width = $(document).width();
			// width = width * 85 / 100;   //Make it 95% of document width
			// if (width == 0)
			//     width = 1000;
			// var height = width / 2;
			// if (height == 0)
			//     height = 300;
			// $scope.layoutCanvas.width = $scope.layoutCanvas.height / 2;
			// $scope.layoutCanvas.height = height;

			//Cleanup any previous event listeners
			if ($scope.Queue) {
				$scope.Queue.removeAllEventListeners();
			}
			if ($scope.Stage) {
				$scope.Stage.removeAllEventListeners();
			}
			if ($scope.containers) {
				for (var i = 0; i < $scope.containers.length; i++) {
					var container = $scope.containers[i];
					container.removeAllEventListeners();
					$scope.dashboardContainer.removeChild(container);
				}
				$scope.containers.length = 0;
			}

			// Create a Stage on the Canvas
			$scope.Stage = new createjs.Stage($scope.layoutCanvas);
			// $scope.screenRedraw();
			$scope.dashboardContainer = new createjs.Container();
			$scope.Stage.addChild($scope.dashboardContainer);

			//Enable touch if supported by device
			createjs.Touch.enable($scope.Stage);

			//Enable mouse over events
			$scope.Stage.enableMouseOver(10);

			//Pick a layout
			// var layoutAsset = "app/img/" + $scope.Vessel.VesselType.Type + "Layout.png";
			var layoutAsset = "img/layout-" + $scope.qm.selectedVessel.Type.toLowerCase() + ".svg";
			// var engineAsset = "img/Engine.png";
			// var watermakerAsset = "img/Watermaker.png";

			if (dashboard) {
				if (dashboard.Template_uuid) {
					var templateUrl = s3factory.getProfilePhotoUrl(dashboard.Files_Vessel, Constants.FileComponent_DashboardTemplate, dashboard.Template_uuid, dashboard.Template_FileName);
					layoutAsset = templateUrl;
				}
				//Asset queue
				$scope.Queue = new createjs.LoadQueue(false);
				$scope.Queue.addEventListener("error", $scope.queueLoadError);
				$scope.Queue.addEventListener("complete", $scope.queueLoadComplete);
				// $scope.Queue.loadManifest([{ id: "layout", src: layoutAsset, type: createjs.LoadQueue.IMAGE }, { id: "Engine", src: engineAsset }, { id: "Watermaker", src: watermakerAsset }]);
				$scope.Queue.loadManifest([{ id: "layout", src: layoutAsset, type: createjs.LoadQueue.IMAGE }]);
			}
		};

		$scope.queueLoadError = function(event) {
			console.error("queueLoadError");
			var error = "Queue load error:";
			if (event.data) {
				error += event.data.src;
			}
			Exception.errorFn(error);
		};

		$scope.queueLoadComplete = function(event) {
			//Create the layout

			$scope.LayoutBitmap = new createjs.Bitmap($scope.Queue.getResult("layout"));
			$scope.LayoutBitmap.x = 0;
			$scope.LayoutBitmap.y = 0;

			$scope.LayoutBitmap.onload = setSizeAndScale();

			$scope.dashboardContainer.addChild($scope.LayoutBitmap);

			// var circle = new createjs.Shape();
			// circle.graphics.beginFill("DeepSkyBlue").drawCircle(0, 0, 50);
			// circle.x = 100;
			// circle.y = 100;
			// $scope.Stage.addChild(circle);
			$scope.circles = [];

			//Add systems
			$scope.model.load(qm.selectedVessel.Id, $scope.dashboard.Id).then(function(results) {
				// $scope.Vessel.getSystems().then(function (results) {
				if (!$scope.layoutCanvas) {
					//Navigated away from the page, i.e. let's cancel
					return;
				}
				$scope.containers = [];
				var x = 100;
				var y = $scope.layoutCanvas.height - 110;
				y = 20;
				// results.each(function (system) {
				angular.forEach(results, function(system) {
					//system.dataBind("value", function (value) {
					//    if (value) {
					//        alert(value);
					//    }
					//});
					try {
						var circleRadius = 30;
						var circleTextPadding = 5;

						var name = system.Description;
						// var name = system.Description;
						var text = new createjs.Text(name, "12px Arial", "black");
						text.x = 0;
						text.y = 0;
						text.textBaseline = "alphabetic";
						text.textAlign = "center";

						var container = new createjs.Container();

						// var systemBmp = new createjs.Bitmap($scope.Queue.getResult(system.SystemType));
						if (system.x && system.y) {
							container.x = system.x;
							container.y = system.y;
						}
						else {
							//Default, we'll line them up in a row
							console.log('Unplaced system: ' + system.Description);
							container.y = 50;
							container.x = x;
							x = x + 70;
							container.visible = $scope.isEditing;
						}
						$scope.containers.push(container);

						var circle = new createjs.Shape();
						if (system.System_SystemType === 1) {
							//Storage system
							circle.graphics.beginFill("maroon").drawCircle(0, circleRadius + circleTextPadding, circleRadius);
						}
						else {
							//Regular system
							circle.graphics.beginFill("red").drawCircle(0, circleRadius + circleTextPadding, circleRadius);
						}
						circle.x = circle.y = 0;
						circle.alpha = 0.5;
						$scope.circles.push(circle);

						var removeContainer = new createjs.Container();
						removeContainer.visible = $scope.isEditing;
						var removeButton = new createjs.Shape();
						removeButton.graphics.beginFill("black").drawCircle(circleRadius, (circleRadius * 2), (circleRadius / 5));
						removeButton.x = removeButton.y = 0;
						removeButton.alpha = 0.5;
						var removeText = new createjs.Text('X', "8px Arial", "white");
						removeText.x = circleRadius;
						removeText.y = (circleRadius * 2) + 1;
						removeText.textBaseline = "middle";
						removeText.textAlign = "center";
						// removeButton.addChild(removeText);
						removeContainer.addChild(removeButton);
						removeContainer.addChild(removeText);

						//systemBmp.alpha = 0.99;
						//systemBmp.SetBounds(10, 10, 100, 100);
						$scope.dashboardContainer.addChild(container);

						if ($scope.dashboard.DashboardsConfig.Scale > 0 && $scope.dashboard.DashboardsConfig.Scale < 201) {
							var scale = (1 * $scope.dashboard.DashboardsConfig.Scale) / 100;
							circle.scaleX = scale;
							circle.scaleY = scale;
							text.scaleX = scale;
							text.scaleY = scale;
							removeContainer.scaleX = scale;
							removeContainer.scaleY = scale;
						}

						container.addChild(circle);
						container.addChild(text);
						container.addChild(removeContainer);
						if (system.TaskCount > 0) {
							const countContainer = new createjs.Container();
							// countContainer.visible = this.isEditing;
							const countButton = new createjs.Shape();
							countButton.graphics.beginFill("black").drawCircle(circleRadius, (circleRadius / 2), (circleRadius / 3));
							countButton.x = countButton.y = 0;
							countButton.alpha = 0.5;
							const countText = new createjs.Text(system.TaskCount, '10px Arial', 'white');
							countText.x = circleRadius;
							countText.y = (circleRadius / 2) + 1;
							countText.textBaseline = "middle";
							countText.textAlign = "center";
							countContainer.addChild(countButton);
							countContainer.addChild(countText);
							container.addChild(countContainer);
						}
						container.system = system;
						container.removeContainer = removeContainer;
						removeContainer.system = system;
						circle.system = system;
						//container.setBounds(0, 0, systemBmp.image.width, systemBmp.image.height);
						//container.alpha = 0.99;
						var textBounds = text.getBounds();
						if (textBounds) {
							container.regY = textBounds.height + circleRadius + circleTextPadding + textBounds.y;
						}

						//###############
						container.cursor = "pointer";
						container.scaleX = container.scaleY = container.scale = 1;
						//container.scaleX = $scope.LayoutScale;
						//container.scaleY = $scope.LayoutScale;

						// using "on" binds the listener to the scope of the currentTarget by default
						// in this case that means it executes in the scope of the button.
						container.on("mousedown", function (evt) {
							this.parent.addChild(this);
							console.log('this:' + this.x + ':' + this.y);
							console.log('stage:' + evt.stageX + ':' + evt.stageY);

							if ($scope.isPortrait) {
								this.offset = {x: this.x + (evt.stageY / $scope.LayoutScale), y: this.y - (evt.stageX / $scope.LayoutScale)};
								// this.offset = {x: this.x - evt.stageY, y: this.y - evt.stageX};
							}
							else {
								this.offset = {x: this.x - (evt.stageX / $scope.LayoutScale), y: this.y - (evt.stageY / $scope.LayoutScale)};
							}
							console.log('offset:' + this.offset.x + ':' + this.offset.y);
						});

						// the pressmove event is dispatched when the mouse moves after a mousedown on the target until the mouse is released.
						container.on("pressmove", function (evt) {
							if ($scope.isEditing) {
								if ($scope.isPortrait) {
									//Swap x and y offset in Portrait mode
									this.x = - (evt.stageY / $scope.LayoutScale) + this.offset.x;
									this.y = (evt.stageX / $scope.LayoutScale) + (this.offset.y);
									console.log(evt.stageY +':'+ this.x);
								}
								else {
									this.x = (evt.stageX / $scope.LayoutScale) + this.offset.x;
									this.y = (evt.stageY / $scope.LayoutScale) + this.offset.y;
								}
								// console.log(this.x + ':' + this.y)
								// indicate that the stage should be updated on the next tick:
								update = true;
								if ($scope.isPortrait) {
									this.system.x = Math.round(this.x);
									this.system.y = Math.round(this.y);
								}
								else {
									this.system.x = Math.round(this.x);
									this.system.y = Math.round(this.y);
								}
								this.system.System_Dashboards = $scope.dashboard.Id;
								$scope.showHideUnplacedSystems();
								//TODO: Implement save
								// myapp.activeDataWorkspace.ApplicationData.saveChanges();
								$scope.saveSystem(this.system);
							}
						});

						container.on("rollover", function (evt) {
							this.scaleX = this.scaleY = this.scale * 1.2;
							update = true;
						});

						container.on("rollout", function (evt) {
							this.scaleX = this.scaleY = this.scale;
							update = true;
						});
						//###############

						circle.addEventListener("click", function (event) {
							//alert('click');
							if ($scope.isEditing != true) {
								var summaryUrl = $scope.getSummaryUrl(container.system);
								console.log('serviceUrl=' + summaryUrl);
								$rootScope.navigateTo(summaryUrl);
							}
						});

						removeContainer.addEventListener("click", function (event) {
							event.bubbles = false;
							if ($scope.isEditing === true) {
								// alert('remove');
								var modalInstance = $uibModal.open({
									templateUrl: 'core/really_click_modal.html',
									controller: 'ReallyClickController',
									//windowClass: 'modal-files',
									//size: 'fit',
									//size: 'md',
									resolve: {
										title: function () {
											return 'Remove system from dashboard';
										},
										message: function () {
											return 'Remove ' + container.system.Description + ' from this dashboard? ' + container.system.Description + ' will not be deleted.';
										},
										textYes: function () {
											return 'Yes';
										},
										textNo: function () {
											return 'No';
										}
									}
								});

								//var _scope = scope;
								modalInstance.result.then(function () {
									//Confirmed
									//scope.$parent.model.deleteUpload(scope.file);
									setTimeout(function () {
										// container.system.x = null;
										// container.system.y = null;
										// container.system.isDirty = true;
										// container.system.System_Dashboards = null;
										$scope.containers.pop(container);
										$scope.dashboardContainer.removeChild(container);
										$scope.removeSystem(container.system);
										// alert('removed');
									}, 1);
								});
							}
						});

						// if ($scope.isPortrait) {
						//     container.regX = $scope.Stage.canvas.width/2;
						//     container.regY = $scope.Stage.canvas.height/2;
						//     container.rotation = 90;
						// }
					}
					catch (err) {
						console.error(err);
					}
				});

				//Edit button
				var editButtonContainer = new createjs.Container();
				// $scope.Stage.addChild(editButtonContainer);

				var editButtonText = new createjs.Text("Edit", "24px Arial", "#000000");
				editButtonText.x = 10;
				editButtonText.y = 20;
				editButtonText.textBaseline = "alphabetic";

				var editButtonCircle = new createjs.Shape();
				editButtonCircle.graphics.beginFill("red").drawCircle(0, 0, 30);
				editButtonCircle.x = editButtonCircle.y = 50;
				editButtonCircle.alpha = 0.5;

				// editButtonContainer.addChild(editButtonCircle);
				editButtonContainer.addChild(editButtonText);

				editButtonContainer.cursor = "pointer";
				editButtonContainer.scaleX = editButtonContainer.scaleY = editButtonContainer.scale = 1;

				editButtonContainer.addEventListener("click", function (event) {
					$scope.isEditing = !$scope.isEditing;
					console.log("Edit mode:" + $scope.isEditing);
					if ($scope.isEditing === true) {
						editButtonText.text = "Editing ...";
						editButtonText.color = "#ff0000";
					}
					else {
						editButtonText.text = "Edit";
						editButtonText.color = "#000000";
					}
					$scope.Stage.update();
				});
				$scope.editButtonContainer = editButtonContainer;

				// if ($scope.isPortrait) {
				//     // var c=document.getElementById("dashboardCanvas");
				//     // var ctx=c.getContext("2d");
				//     // ctx.rotate(90*Math.PI/180);
				//     // ctx.fillRect(50,20,100,50);
				// }

				if ($scope.isPortrait) {
					$scope.setAllSystemsRotation(90);
				}
				else {
					$scope.setAllSystemsRotation(0);
				}

				$scope.Stage.update();
			}, function (error) {
				Exception.errorFn(error);
			});
			//// Create a Shape on the Stage
			//$scope.Circle = new createjs.Shape();
			//// Make the Shape a red circle
			//$scope.Circle.graphics.beginFill("red").drawCircle(0, 0, 40);
			//// Set the location of the Shape on the Stage
			//$scope.Circle.y = 250;
			//// Add a handler that will only fire when the shape is clicked on
			//$scope.Circle.addEventListener("mousedown", onCircleMouseDown, false);
			//// Add the Shape to the Stage
			//$scope.Stage.addChild($scope.Circle);
			// Create a Timer that will fire a method called tick
			// createjs.Ticker.addEventListener("tick", $scope.tick);
			// Set the Ticker
			// createjs.Ticker.setFPS(10);
		};

		$scope.circlealpha = 1;
		$scope.circlealphainc = 1;

		$scope.tick = function(event) {
			//// Move the circle 5 positions
			//$scope.Circle.x = $scope.Circle.x + 5;
			//// The y position is set to whatever the latest value for
			//// $scope.VerticalPosition is
			//$scope.Circle.y = $scope.VerticalPosition;
			//// The circle size is set to whatever the latest value for
			//// $scope.CircleSize is
			//$scope.Circle.scaleX = $scope.CircleSize;
			//$scope.Circle.scaleY = $scope.CircleSize;
			//// If the Circle moves off the camvas start it over
			//// by moving it back to zero
			//if ($scope.Circle.x > $scope.Stage.canvas.width) {
			//    $scope.Circle.x = 0;
			//}
			//// Update the visuals
			//$scope.Stage.update(event);

			angular.forEach($scope.circles, function(circle) {
				//Create blinking circles
				// circle.alpha = 1 / $scope.circlealpha;
				// $scope.circlealpha = $scope.circlealpha + $scope.circlealphainc;
				// if ($scope.circlealpha > 10) {
				//     $scope.circlealpha = 1;
				//     $scope.circlealphainc = $scope.circlealphainc = (-1);
				// }
				// if ($scope.circlealpha < 1) {
				//     $scope.circlealpha = 1;
				//     $scope.circlealphainc = $scope.circlealphainc = (+1);
				// }

				// circle.x += 10;
				// //Will cause the circle to wrap back
				// if (circle.x > $scope.Stage.canvas.width) { circle.x = 0; }
			});
			$scope.Stage.update();

			if (update) {
				update = false; // only update once
				$scope.Stage.update(event);
			}
		};

		$scope.onCircleMouseDown = function(event) {
			// The Shape (Circle) has been clicked on
			var paramX = event.target.x;
			var paramY = event.target.y;
			// Display the currrent position of the Shape (Circle)
			alert('paramX:' + paramX + ' paramY:' + paramY);
		};

		// $scope.createSlider = function(element, contentItem, min, max) {
		// 	// Generate the input element.
		// 	$(element).append('<input type="range" min="' + min +
		// 		'" max="' + max + '" value="' + contentItem.value + '" />');
		// 	// Now, after jQueryMobile has had a chance to process the
		// 	// new DOM addition, perform our own post-processing
		// 	// (basically for each jQuery Mobile widget, like the slider,
		// 	// there is a “<controlname>init” event you can listen for
		// 	// that is called after they have finalized the content of the DOM)
		// 	$(element).one('slideinit', function (e) {
		// 		var $slider = $(e.target);
		// 		// If the content item changes (perhaps due to another control being
		// 		// bound to the same content item, or if a change occurs programmatically),
		// 		// update the visual representation of the slider:
		// 		contentItem.dataBind('value', function (newValue) {
		// 			$slider.val(newValue);
		// 		});
		// 		// Conversely, whenever the user adjusts the slider visually,
		// 		// update the underlying content item:
		// 		$slider.change(function () {
		// 			contentItem.value = $slider.val();
		// 		});
		// 	});
		// };

		// $scope.initializeCanvas();

		$scope.edit = function() {
			$scope.isEditing = !$scope.isEditing;
			console.log("Edit mode:" + $scope.isEditing);
		};

		$scope.listenForChanges('Systems');

	});
