/**
 * Created by petter on 2/18/16.
 */
angular.module('app')
	.service('qm', function (Exception, $uibModal, $q, VesselModel, localStorageService, $location, $rootScope, screenSize, common, Configuration, Model, $http) {
		// console.log('created qm');
		$rootScope.qm = this;
	    // this.needSyncModel = new Model(function NeedSyncItem() { this.dataColumns = []}, Configuration.API + '/needssync/:id');

		screenSize.rules = {
			// max: '(min-width: 992px) and (max-width: 1199px)',
			// desktop: '(min-width: 769px) and (max-width: 768px)',
			mobile: '(min-width: 415px) and (max-width: 768px)',
			phoneplus: '(min-width: 321px) and (max-width: 414px)',
			phone: '(max-width: 320px)'
		};

		// Using dynamic method `on`, which will set the variables initially and then update the variable on window resize
		// if (screenSize.is('xs, sm')) {
		//     this.mobile = true;
		// }
		// else {
		//     this.mobile = false;
		// }
		// this.desktop = screenSize.is('desktop');
		// console.log('desktop:'+this.desktop);
		// screenSize.on('desktop', function(match){
		//     // console.log('screenSize:' + match);
		//     if ($rootScope.qm.desktop === !match) {
		//         console.log('desktop:' + match);
		//         $rootScope.qm.desktop = match;
		//         $rootScope.$broadcast('screen-changed');
		//     }
		// });
		this.mobile = screenSize.is('phone, phoneplus, mobile');
		// console.log('mobile:'+this.mobile);
		screenSize.on('phone, phoneplus, mobile', function(match){
			// console.log('screenSize:' + match);
			if ($rootScope.qm.mobile === !match) {
				console.log('mobile:' + match);
				$rootScope.qm.mobile = match;
				$rootScope.$broadcast('screen-changed');
			}
		});
		this.phoneplus = screenSize.is('phone, phoneplus');
		// console.log('phoneplus:'+this.phoneplus);
		screenSize.on('phone, phoneplus', function(match){
			// console.log('screenSize:' + match);
			if ($rootScope.qm.phoneplus === !match) {
				console.log('phoneplus:' + match);
				$rootScope.qm.phoneplus = match;
				$rootScope.$broadcast('screen-changed');
			}
		});
		this.phone = screenSize.is('phone');
		// console.log('phone:'+this.phone);
		screenSize.on('phone', function(match){
			// console.log('screenSize:' + match);
			if ($rootScope.qm.phone === !match) {
				console.log('phone:' + match);
				$rootScope.qm.phone = match;
				$rootScope.$broadcast('screen-changed');
			}
		});

		this.getDefaultIdColumn = function() {
			return {
				name:'Id', field: 'Id',
				// cellClass: 'hidden-sm hidden-xs', headerCellClass: 'hidden-sm hidden-xs',
				visible: !this.mobile && DEBUG,
				enableHiding:true, maxWidth: '70', filter: { placeholder: '...'}
			};
		};

		this.getSystemModified = function() {
			return {
				name:'Modified', field: 'Modified', width: '140',
				visible: !this.mobile && DEBUG,
				cellFilter: this.dateTimeCellFilter, enableHiding:true, filter: { placeholder: 'search modified...'}
			};
		};

		this.getSystemModifiedBy = function() {
			return {
				name:'ModifiedBy', field: 'ModifiedBy',
				visible: !this.mobile && DEBUG,
				enableHiding:true, filter: { placeholder: 'search modified by...'}
			};
		};

		this.needsSyncList = null;
		this.needsSyncProperties = ["Systems", "Tasks", "Files", "SystemServices", "SystemSchedules", "Dashboards" ,"StorageItems", "VesselUserRoles", "Vessels"];

		this.resetNeedsSync = function () {
			this.needsSyncList = null;
		};

		//TODO: Need to take a list of table names (Dashboard and Summary needs to listen for more than one)
		this.checkNeedsSync = function (table) {
			var _this = this;
			var params = {};
			if (table) {
				params.table = table;
			}
			$http({
				method: 'GET',
				url: Configuration.API + "/needssync",
				params: params
			})
			// _this.needSyncModel.load()
				.then(function (response) {
					if ((response.status === 200) && response.data) {
						var result = response.data;
						// console.log(result);
						if (_this.needsSyncList) {
							//Already have some result to check against
							for (var i = 0; i < _this.needsSyncList.length && i < result.length; i++) {
								var needsSyncCache = _this.needsSyncList[i];
								var needsSyncNew = result[i];
								var needsReload = false;
								for (var j=0; j<_this.needsSyncProperties.length; j++) {
									var property = _this.needsSyncProperties[j];
									if (needsSyncCache[property] !== needsSyncNew[property]) {
										needsReload = true;
										break;
									}
								}
								if (needsReload) {
									console.log('qm.needsReload!');
									_this.needsSyncList = angular.copy(result);
									if (table) {
										$rootScope.$broadcast('qm.needsReload.' + table);
									}
									else {
										$rootScope.$broadcast('qm.needsReload');
									}
								}
							}
							console.log('needsSyncList checked!');
						} else {
							//First time checking, simply save results
							_this.needsSyncList = angular.copy(result);
						}
					}
				})
				.catch(function (e) {
					console.error(e);
				});
		};

		//Cell filter for use in grids to format data: https://docs.angularjs.org/api/ng/filter/date
		this.dateCellFilter = 'date:\'shortDate\'';
		this.dateTimeCellFilter = 'date:\'short\'';

		this.selectedVessel = null;
		this.vesselList = null;

		this.loadVessels = function() {
			console.log('Loading vessels');
			console.time('perf:load vessels data');
			var deferred = $q.defer();
			try {
				var _this = this;
				if ((_this.vesselList != null) && (this.selectedVessel != null)) {
					//Vessel already loaded, just return;
					console.log('Vessel already loaded');
					deferred.resolve(_this.vesselList);
				}
				else {
					console.log('Loading VesselModel');
					var model = new VesselModel();
					// var vesselsModel = new Object();
					// var model = new Model(Vessel, 'http://localhost:1337/api/vessels/:id');
					model.load().then(function () {
						// this.selectFacilitiesAPI.query(function(response) {
						var vessels = model.items;
						// var s3_bucket = S3_SETTINGS[ENVIRONMENT];
						// for (var i=0;i<vessels.length;i++) {
						//     var vessel = vessels[i];
						//     if (!vessel.file_url) {
						//         vessel.profileUrl = './img/default-avatar.png';
						//     }
						//     // if (vessel.file_url) {
						//     //     var url = 'https://s3.amazonaws.com/' + s3_bucket + '/3/' + vessel.file_url.substr(0, 8) + '-' + vessel.file_url.substr(8, 4) + '-' + vessel.file_url.substr(12, 4) + '-' + vessel.file_url.substr(16, 4) + '-' + vessel.file_url.substr(20, 12) + '/' + vessel.file_url.substr(32);
						//     //     vessel.profileUrl = url;
						//     // }
						// }
						_this.vesselList = vessels;
						_this.initVessels(_this.vesselList);
						console.timeEnd('perf:load vessels data');

						deferred.resolve(_this.vesselList);
						$rootScope.$broadcast('qm.vesselsLoaded');
						//Now start listening for changes
	                    //Wait 10 seconds, before listening
	                    // setTimeout(function () {
						// 	//Make sure we only create one timer
		                //     if (!_this.heartBeatTimer) {
			            //         setInterval(function () {
				        //             //Check for changes every 5 seconds
				        //             _this.heartBeatTimer = _this.checkNeedsSync()
			            //         }, 60 * 1000);
		                //     }
	                    //
	                    // }, 60 * 1000)
					},function (resp) { //catch error
						$rootScope.$broadcast('qm.vesselsLoadFail');
						// throw new Error('Failed to load list of vessels');
					});
				}
			}
			catch (err) {
				console.error(err);
				deferred.reject(err);
			}
			return deferred.promise;
		};

		this.reloadVessels = function() {
			this.vesselList = null;
			this.selectedVessel = null;
			return this.loadVessels();
		};

		this.initVessels = function(vesselList) {
			//Fetch previously selected vessel from local storage
			this.selectedVessel = localStorageService.get('selected-vessel');
			//Use a copy of the list to prevent circular reference and other components from changing it
			this.vesselList = angular.copy(vesselList);
			if (this.vesselList.length > 0) {
				if (this.selectedVessel) {
					var Id = this.selectedVessel.Id;
					var matchedVessel = null;
					for (var i=0; i<this.vesselList.length; i++) {
						var vessel = this.vesselList[i];
						if (vessel.Id == Id) {
							//Matched current vessel
							this.selectedVessel = vessel;
							matchedVessel = vessel;
							break;
						}
					}
					if (!matchedVessel) {
						//Previosly selected vessel doesn't match in the list, pick the first one
						this.selectedVessel = this.vesselList[0];
					}
				}
				else {
					//No vessel previously selected, pick first from list
					this.selectedVessel = this.vesselList[0];
				}
			}
			else {
				//List of vessels is empty, clear the selected vessel
				this.selectedVessel = null;
			}
			// localStorageService.set('selected-vessel', this.selectedVessel);
			this.setSelectedVessel(this.selectedVessel);
		};

		this.setSelectedVessel = function(vessel) {
			this.selectedVessel = vessel;
			//Save to local storage
			if (this.selectedVessel) {
				this.selectedVessel.model = null;
			}
			localStorageService.set('selected-vessel', this.selectedVessel);
			if (!this.previouslySelectedVesselId) {
				this.previouslySelectedVesselId = this.selectedVessel ? this.selectedVessel.Id : null;
			}
			else {
				if (this.selectedVessel && (this.selectedVessel.Id != this.previouslySelectedVesselId)) {
					this.previouslySelectedVesselId = this.selectedVessel ? this.selectedVessel.Id : null;
					if (!IS_IONIC) {
						$location.path(Configuration.LoggedInPath);
					}
				}
				this.previouslySelectedVesselId = this.selectedVessel ? this.selectedVessel.Id : null;
			}
		};

		this.addItem = function (model, title, refresh) {
			var item = model.newItem();
			this.openItem(model, title, model.items, item, refresh);
		};

		this.openItem = function (model, title, list, item, refresh) {
			//TODO: We should re-load the grid, if user press the save data
	        if (!list) {
		        console.error('blank list passed to open item');
	        }
	        var qm = this;
			model.isItemDirty = false;
			var modalInstance = null;
			if (model.templateUrl) {
				modalInstance = $uibModal.open({
					templateUrl: model.templateUrl,
					controller: 'ModalController',
					resolve: {
						title: function () {
							return title;
						},
						modalObject: function () {
							return item;
						},
						model: function () {
							return model;
						}
					}
				});
			}
			else {
				throw new Error('Model must have templateUrl');
			}

			modalInstance.result.then(function (modalObject) {
				if (model.saveItem) {
					common.showWorking();
					var savePromise = model.saveItem(modalObject).then(function(responseData) {
	                    // console.log('result:' + result);
						if (list) {
                        	var newListItem = true;
							for (var i = 0; i < list.length; i++) {
								if (list[i].Id == responseData.Id) {
									//Update list item
									list[i] = responseData;
									//Shortcut a return, since it's already been added
	                                newListItem = false;
									break;
								}
							}
							if (newListItem) {
	                            list.unshift(responseData);
							}
						}
	                    if (refresh) {
		                    var promise = refresh();
		                    if (promise) {
		                        promise.then(function (value) {
									common.hideWorking();
								});
							}
							else {
								common.hideWorking();
							}
	                    }
	                    else {
							common.hideWorking();
	                    }
					}, function (error) {
						common.hideWorking();
	                    Exception.errorFn(error);
					});
					if (!savePromise) {
						common.hideWorking();
					}

				}
			}, function () {
				console.log('Modal dismissed at: ' + new Date());
			});
		};

		this.deleteItem = function(model, list, item) {
			if (item.id) {
				return model.deleteItem(item).then(function (result) {
					var index = list.indexOf(item);
					if (index > -1) {
						list.splice(index, 1);
					}
				});
			}
		};

		// $rootScope.$watch('isAuthenticated', function() {
		//     if ($rootScope.isAuthenticated) {
		//         try {
		//             $rootScope.qm.loadVessels();
		//         }
		//         catch (err) {
		//             console.error(err);
		//         }
		//     }
		// });

	    $rootScope.$on('userProfileSet', function(event, args) {
		    if ($rootScope.isAuthenticated) {
			    try {
				    $rootScope.qm.loadVessels();
			    }
			    catch (err) {
				    console.error(err);
			    }
		    }
	    });

	    $rootScope.$on('loggedOut', function(event, args) {
		    $rootScope.qm.selectedVessel = null;
		    $rootScope.qm.vesselList = null;
	    });


	});
