/**
 * Created by petter on 2/14/19.
 */
'use strict';

angular.module('app')
	.factory('FleetModel', function ($q, Model, Fleet, s3factory, Constants) {
		function FleetModel() {
			Model.call(this, Fleet);
			this.types = [
				{
					Type:'Sail',
					Id:1
				},
				{
					Type:'Power',
					Id:2
				},
				{
					Type:'Tender',
					Id:3
				},
	            {
		            Type:'Catamaran',
		            Id:4
	            }
			];
		}

		FleetModel.prototype = Object.create(Model.prototype);
		FleetModel.prototype.constructor = FleetModel;

		FleetModel.prototype.load = function(id, params) {
			return Model.prototype.load.call(this, params);
		};

		FleetModel.prototype.onLoadedItem = function(item) {
			item.profileUrl = s3factory.getProfilePhotoUrl(item.Id, Constants.FileComponent_VesselProfile, item.uuid, item.FileName);
			if (!item.profileUrl) {
				if (item.Type) {
					item.profileUrl = './img/default-yacht-'+item.Type.toLowerCase()+'.svg';
				}
				else {
					item.profileUrl = './img/default-yacht-power.svg';
				}
			}
			// item.templateUrl = s3factory.getProfilePhotoUrl(Constants.FileComponent_VesselTemplate, item.Template_uuid, item.Template_FileName);
			item.id = item.Id;
			console.log('Loaded ' + item.Name);
			//item.profileUrl = item.getProfileUrl();
	        item.systemschedules = [];
	        item.systemServiceModel = {};
	        item.systemServiceModel.items = [];

		};

		FleetModel.prototype.isValidItem = function(item) {
	        return false;
		};

		return FleetModel;
	});

angular.module('app')
	.factory('Fleet', function(ItemBase, Configuration) {
		function Fleet() {
			ItemBase.call(this);
		}

		Fleet.prototype = Object.create(ItemBase.prototype);

		Fleet.dataColumns = ['Name', 'Vessel_VesselType', 'Vessel_File', 'Vessel_TemplateFile','Manufacturer', 'Model'];
		Fleet.templateUrl = '';
		Fleet.url = Configuration.API + '/vessels/:id';

		return Fleet;
	});

