/**
 * Created by petter on 2/18/16.
 */
'use strict';

angular.module('app')
	.factory('SystemScheduleModel', function ($q, Model, SystemSchedule, CrewModel) {
		function SystemScheduleModel() {
			Model.call(this, SystemSchedule);

	        this.crewModel = new CrewModel();
	        this.crew = this.crewModel.items;
		}

		SystemScheduleModel.prototype = Object.create(Model.prototype);
		SystemScheduleModel.prototype.constructor = SystemScheduleModel;

		SystemScheduleModel.prototype.load = function(system) {
	        var deferred = $q.defer();
	        var _this = this;

			if (system) {
				this.system = system;
			}

	        this.crewModel.load({vessel_id:_this.system.System_Vessel}).then(function(result) {
		        Model.prototype.load.call(_this, {system_id: _this.system.Id}).then(function(result) {
			        deferred.resolve(result);
		        }, function (error) {
			        deferred.reject(error);
		        });
	        }, function (error) {
		        deferred.reject(error);
	        });

	        return deferred.promise;
		};

	    SystemScheduleModel.prototype.loadAll = function(vessel_id) {
		    var deferred = $q.defer();
		    var _this = this;

		    if (vessel_id) {
			    this.vessel_id = vessel_id;
		    }

		    this.crewModel.load({vessel_id:_this.vessel_id}).then(function(result) {
			    Model.prototype.load.call(_this, {vessel_id: _this.vessel_id}).then(function(result) {
				    deferred.resolve(result);
			    }, function (error) {
				    deferred.reject(error);
			    });
		    }, function (error) {
			    deferred.reject(error);
		    });

		    return deferred.promise;
	    };

		SystemScheduleModel.prototype.saveItem = function(data) {
        	if (!data.SystemSchedule_System) {
		        data.SystemSchedule_System = this.system.Id;
	        }
			return Model.prototype.saveItem.call(this, data);
		};

		SystemScheduleModel.prototype.onLoadedItem = function(item) {
			item.id = item.Id;
	        console.log('Loaded ' + item.Description);
			if (!item.IntervalDays) {
	            item.IntervalDays = 1; //Default to 1 day, it not specified or set to 0
			}

	        item.assignedResource = null;

	        //Assign crew
	        if (this.crewModel) {
		        for (var i = 0; i < this.crewModel.items.length; i++) {
			        var crew = this.crewModel.items[i];
			        if (item.SystemSchedule_VesselUserRoles === crew.Id) {
				        item.assignedResource = crew;
			        }
		        }
	        }

	        this.isItemDirty = false;
		};

		SystemScheduleModel.prototype.isValidItem = function(item) {
	        this.isItemDirty = true;
	        item.isItemDirty = true;
	        if (!item.Description){
		        return false;
	        }
			return true;
		};

		return SystemScheduleModel;
	});

angular.module('app')
	.factory('SystemSchedule', function(ItemBase, Configuration) {
		function SystemSchedule() {
			ItemBase.call(this);
		}

		SystemSchedule.prototype = Object.create(ItemBase.prototype);

		SystemSchedule.dataColumns = ['Description', 'IntervalDays', 'ScheduleDate', 'Hours', 'NextScheduleHours', 'Notes', 'SystemSchedule_System', 'SystemSchedule_VesselUserRoles'];
		SystemSchedule.templateUrl = 'components/systemschedules/systemschedules_edit.html';
		SystemSchedule.url = Configuration.API + '/systemschedules/:id';

	    SystemSchedule.prototype.isOverdue = function() {
		    if (!this.ScheduleDate) {
			    return false;
		    }
		    var oldScheduleDate = new Date(this.ScheduleDate);
		    var today = new Date();
		    //Set time to noon, to get a day comparison, basically ignoring the time component
		    oldScheduleDate.setHours(12,0,0, 0);
		    today.setHours(12,0,0, 0);

		    if (oldScheduleDate < today) {
			    console.log('Overdue: ' + this.ScheduleDate);
			    return true;
		    }
		    else {
			    return false;
		    }
	    };

	    SystemSchedule.prototype.isDueToday = function() {
		    if (!this.ScheduleDate) {
			    return false;
		    }
		    var oldScheduleDate = new Date(this.ScheduleDate);
		    var today = new Date();
		    //Set time to noon, to get a day comparison, basically ignoring the time component
		    oldScheduleDate.setHours(12,0,0, 0);
		    today.setHours(12,0,0, 0);

		    if (oldScheduleDate.toDateString() === today.toDateString()) {
			    console.log('Due today: ' + this.ScheduleDate);
			    return true;
		    }
		    else {
			    return false;
		    }
	    };

	    return SystemSchedule;
	});

