'use strict';
angular.module('app')
	.controller('CallbackController', function ($rootScope, $scope, $location, $timeout, Configuration) {
		console.log('Callback:' + $location.$$path);

		//Remove URL fragments
		$location.url($location.path());

		$scope.checkForRedirect = function (lastAttempt) {
			if ($location.$$path === "/callback") {
				//We're still here, can we redirect user?
				if ($rootScope.isAuthenticated) {
					//Logged in, let's take user to ships
					$location.path(Configuration.LoggedInPath);
				}
				else if (lastAttempt) {
					//Last attempt, take you home
					console.warn('Last attempt waiting for authentication in callback.  Redirect to home.');
					$location.path(Configuration.HomePath);
				}
				else {
					console.warn('Stuck in callback.  Not yet authenticated.');
				}
			}
		};

		$timeout(function() {
			$scope.checkForRedirect();
		}, 1 * 1000);

		$timeout(function() {
			$scope.checkForRedirect();
		}, 3 * 1000);

		$timeout(function() {
			$scope.checkForRedirect();
		}, 5 * 1000);

		$timeout(function() {
			$scope.checkForRedirect(true);
		}, 10 * 1000);
	});
