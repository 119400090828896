/**
 * Created by petter on 7/13/16.
 */
'use strict';

angular.module('app')
	.factory('StripePaymentModel', function ($q, Model, StripePayment) {
		function StripePaymentModel() {
			Model.call(this, StripePayment);
		}

		StripePaymentModel.prototype = Object.create(Model.prototype);
		StripePaymentModel.prototype.constructor = StripePaymentModel;

		StripePaymentModel.prototype.load = function(params) {
			return Model.prototype.load.call(this);
		};

		StripePaymentModel.prototype.saveItem = function(data) {
			// data.VesselUserRole_Vessel = this.vessel_id;
			return Model.prototype.saveItem.call(this, data);
		};

	    //Create a no-op refreshItem method to account for missing GET in API
	    StripePaymentModel.prototype.refreshItem = function(responseData) {
		    var deferred = $q.defer();
		    deferred.resolve(responseData);
		    return deferred.promise;
	    };

	    StripePaymentModel.prototype.isValidItem = function(item) {
		    return true;
	    };

	    return StripePaymentModel;
	});

angular.module('app')
	.factory('StripePayment', function(ItemBase, Configuration) {
		function StripePayment() {
			ItemBase.call(this);
		}

		StripePayment.prototype = Object.create(ItemBase.prototype);

		StripePayment.dataColumns = ['token', 'subscription', 'coupon'];
		// SubscriptionClientToken.templateUrl = 'quartermaster/crew/crew_edit.html';
		StripePayment.url = Configuration.API + '/stripe_payment/:id';

		StripePayment.prototype.onNewItem = function() {
			// this.VesselUserRole_Vessel = this.modelClass.type_id;
		};

		return StripePayment;
	});

