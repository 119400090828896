/**
 * Created by petter on 2/18/16.
 */
'use strict';

angular.module('app')
	.factory('SystemServiceModel', function ($q, Model, SystemService, common) {
		function SystemServiceModel() {
			Model.call(this, SystemService);
	        this.show_expenses_column = false;
	        this.show_hours_column = false;
		}

		SystemServiceModel.prototype = Object.create(Model.prototype);
		SystemServiceModel.prototype.constructor = SystemServiceModel;

		SystemServiceModel.prototype.load = function(system) {
			if (system) {
				this.system = system;
			}

			return Model.prototype.load.call(this, {system_id: this.system.Id});
		};

	    SystemServiceModel.prototype.loadReport = function(vessel_id, from_date, to_date) {
		    return Model.prototype.load.call(this, {
		    	vessel_id:vessel_id,
			    from_date:from_date,
			    to_date:to_date
		    });
	    };

		SystemServiceModel.prototype.saveItem = function(data) {
			if (this.system) {
				data.SystemService_System = this.system.Id;
			}
			return Model.prototype.saveItem.call(this, data);
		};

		SystemServiceModel.prototype.onLoadedItem = function(item) {
			item.id = item.Id;
			// item.ServiceDate = new Date();
			console.log('Loaded ' + item.Description);
		};

		SystemServiceModel.prototype.isValidItem = function(item) {
			this.isItemDirty = true;
			item.isItemDirty = true;
			if (!item.Description || !item.ServiceDate){
				return false;
			}
			return true;
		};

	    SystemServiceModel.prototype.getGridColumns = function() {
		    return [
			    common.getDefaultIdColumn(),
			    { name:'Date', field: 'ServiceDate', type: 'date', maxWidth: 70, cellFilter: common.dateCellFilter, enableHiding:false, sort: { priority: 0, direction: 'desc' }, filter: { placeholder: 'search...'} },
			    { name:'Hours', field: 'Hours', visible: this.show_hours_column, width: '120', filter: { placeholder: 'search hours...'} },
			    { name:'Expenses', field: 'Expenses', visible: this.show_expenses_column, width: '120', filter: { placeholder: 'search expenses...'} },
			    { name:'Description', field: 'Description', enableHiding:false, filter: { placeholder: 'search description...'} },
			    { name:'Notes', field: 'Notes', visible: !common.phoneplus, enableHiding:false, filter: { placeholder: 'search notes...'} },
			    common.getSystemModified(),common.getSystemModifiedBy(),
			    { name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '100',
				    cellTemplate: 'components/systemservices/systemservices_action_template.html'
			    }
		    ];
	    };

		return SystemServiceModel;
	});

angular.module('app')
	.factory('SystemService', function(ItemBase, Configuration) {
		function SystemService() {
			ItemBase.call(this);
			this.ServiceDate = new Date();
		}

		SystemService.prototype = Object.create(ItemBase.prototype);

		SystemService.dataColumns = ['Description', 'Notes', 'ServiceDate', 'SystemService_System', 'Hours', 'Expenses'];
		SystemService.templateUrl = 'components/systemservices/systemservices_edit.html';
		SystemService.url = Configuration.API + '/systemservices/:id';

		return SystemService;
	});

