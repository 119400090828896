/**
 * Created by petter on 2/15/17.
 */
'use strict';

// StorageModel is a proxy for System in which case the type of system is storage (i.e. SystemType = 1)
angular.module('app')
	.factory('StorageModel', function ($q, Model, SystemModel, Storage, common) {
		function StorageModel() {
	        Model.call(this, Storage);
		}

		StorageModel.prototype = Object.create(SystemModel.prototype);
		StorageModel.prototype.constructor = StorageModel;

	    StorageModel.prototype.getGridColumns = function() {
		    return [
			    common.getDefaultIdColumn(),
			    { name:'Description', field: 'Description', enableHiding:false, sort: { priority: 1, direction: 'asc' }, filter: { placeholder: 'search description...'},
				    cellTemplate: 'components/storage/storage_description_template.html'
			    },
			    { name:'Notes', field: 'Notes', visible: !common.phoneplus, enableHiding:false, filter: { placeholder: 'search notes...'} },
			    // { name:'Notes', field: 'Notes', enableHiding:true, filter: { placeholder: 'search notes...'} },
			    common.getSystemModified(),common.getSystemModifiedBy(),
			    { name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '100',
				    cellTemplate: 'components/storage/storage_action_template.html'
			    }
		    ];
	    };

		return StorageModel;
	});

angular.module('app')
	.factory('Storage', function(ItemBase, System, Configuration) {
		function Storage() {
	        ItemBase.call(this);
			this.System_SystemType = 1; //Default to Storage system
		}

		Storage.prototype = Object.create(System.prototype);

		Storage.dataColumns = System.dataColumns;
		Storage.templateUrl = 'components/storage/storage_edit.html';
		Storage.url = Configuration.API + '/storage/:id';

		return Storage;
	});

