/**
 * Created by petter on 3/24/16.
 */
'use strict';
angular.module('app')
	.controller('ModalController', function ($scope, $uibModalInstance, title, modalObject, model) {
		$scope.modalObjectSource = modalObject;
		if (modalObject.constructor && (modalObject.constructor.name === 'ItemBase')) {
			$scope.modalObject = Object.assign( new model.modelClass(), modalObject);  //Create a deep copy, so we can cancel without saving, but also inherit class properties
		}
		else {
			$scope.modalObject = angular.extend({}, modalObject); //Create a shallow copy, so we can cancel without saving
		}
		$scope.model = model;
		$scope.title = title;

		$scope.ok = function () {
			if (!$scope.model.isValidItem($scope.modalObject)){
				return;
			}

			//Shallow copy back changes, before returning
			angular.extend($scope.modalObjectSource, $scope.modalObject);
			$uibModalInstance.close($scope.modalObjectSource);
		};

		$scope.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};
	});