angular.module('app')
	.service('authWrapper', function (jwtHelper, $location, $http, Configuration) {
		this.authKey = AUTH_CLIENT_ID_WEB;

		this.login = function(username, password, cb, authservice) {
	        $http({
		        method: 'POST',
		        url: Configuration.PUBLIC_API + '/auth/login',
		        data: {
			        Key: this.authKey,
			        UserName: username,
			        Password: password
		        }
	        })
		        .then(function (value) {
			        console.log('Successfully logged in');
			        var token = value.data;
			        //Fake authResult
			        // $location.$$hash = token;
			        var authResult = {
				        accessToken: token,
				        idToken: token
			        };
			        localStorage.setItem('id_token', token);
			        authservice.handleAuthResult(null, authResult);
			        cb(null);   //Report as success
		        })
		        .catch(function (reason) {
			        console.warn('Failed to login to Auth endpoint:' + reason.data);
			        cb(reason);
		        });
		};

		this.signup = function(username, password, cb, authservice) {
	        $http({
		        method: 'POST',
		        url: Configuration.PUBLIC_API + '/auth/signup',
		        data: {
			        Key: this.authKey,
			        UserName: username,
			        Password: password
		        }
	        })
		        .then(function (value) {
			        console.log('Successfully signed up');
			        var token = value.data;
			        var authResult = {
				        accessToken: token,
				        idToken: token
			        };
			        localStorage.setItem('id_token', token);
			        authservice.handleAuthResult(null, authResult);
			        cb(null);   //Report as success
		        })
		        .catch(function (reason) {
			        console.warn('Failed to signup with Auth endpoint:' + reason.data);
			        cb(reason);
		        });
		};

		this.changePassword = function(username, password, newpassword, cb, authservice) {
	        $http({
		        method: 'POST',
		        url: Configuration.PUBLIC_API + '/auth/changePassword',
		        data: {
			        Key: this.authKey,
			        UserName: username,
			        Password: password,
			        NewPassword: newpassword
		        }
	        })
		        .then(function (value) {
			        console.log('Successfully changed password and logged in');
			        cb(null);   //Report as success
			        var token = value.data;
			        //Fake authResult
			        // $location.$$hash = token;
			        var authResult = {
				        accessToken: token,
				        idToken: token
			        };
			        localStorage.setItem('id_token', token);
			        authservice.handleAuthResult(null, authResult);
		        })
		        .catch(function (reason) {
			        console.warn('Failed to change password with Auth endpoint:' + reason.data);
			        cb(reason);
		        });
		};

		this.resetPassword = function(username, cb) {
	        $http({
		        method: 'POST',
		        url: Configuration.PUBLIC_API + '/auth/forgotPassword',
		        data: {
			        Key: this.authKey,
			        UserName: username
		        }
	        })
		        .then(function (value) {
			        console.log('Successfully reset password');
			        cb(null);   //Report as success
		        })
		        .catch(function (reason) {
			        console.warn('Failed to reset password with Auth endpoint:' + reason.message);
			        cb(reason);
		        });
		};

	    this.resetPasswordConfirmNew = function(resetpasswordtoken, username, newpassword, newpasswordconfirm, cb, authservice) {
		    $http({
			    method: 'POST',
			    url: Configuration.PUBLIC_API + '/auth/forgotpasswordconfirmnew',
			    data: {
				    Key: this.authKey,
				    ResetPasswordToken: resetpasswordtoken,
				    UserName: username,
				    NewPassword: newpassword,
				    NewPasswordConfirm: newpasswordconfirm
			    }
		    })
			    .then(function (value) {
				    console.log('Successfully changed password and logged in');
				    cb(null);   //Report as success
				    var token = value.data;
				    //Fake authResult
				    // $location.$$hash = token;
				    var authResult = {
					    accessToken: token,
					    idToken: token
				    };
				    localStorage.setItem('id_token', token);
				    authservice.handleAuthResult(null, authResult);
			    })
			    .catch(function (reason) {
				    console.warn('Failed to reset password with Auth endpoint:' + reason.data);
				    cb(reason);
			    });
	    };

		this.userInfo = function(accessToken, callback) {
	        try {
		        //Try parsing Quartermaster token first
		        var decodedToken = jwtHelper.decodeToken(accessToken);
		        // console.log(decodedToken);
		        callback(null, decodedToken.user);
	        }
	        catch (err){
		        console.error(err);
		        callback(err, null);
	        }
		};

	    this.getUserFromToken = function(token) {
		    var decodedToken = jwtHelper.decodeToken(token);
		    return decodedToken;
	    };

		//Renew token
		this.checkSession = function(emptyObject, callback) {
	        var token = localStorage.getItem("id_token");
	        if (token) {
		        //Attempt to renew with Quartermaster Auth API
		        $http({
			        method: 'POST',
			        url: Configuration.PUBLIC_API + '/auth/renew',
			        data: {
				        Key: this.authKey,
				        token: token
			        }
		        })
			        .then(function (value) {
				        console.log('Successfully renewed token');
				        // cb(null);   //Report as success
				        var token = value.data;
				        //Fake authResult
				        // $location.$$hash = token;
				        var authResult = {
					        accessToken: token,
					        idToken: token
				        };
				        localStorage.setItem('id_token', token);
				        callback(null, authResult);
				        // authservice.handleAuthResult(null, authResult);
			        })
			        .catch(function (reason) {
				        console.warn('Failed to renew token with Quartermaster Auth endpoint:' + reason.data);
			        });
	        }
		};

		this.isTokenExpired = function (token, offset) {
	        try {
		        return jwtHelper.isTokenExpired(token, offset);
	        }
	        catch (err) {
		        console.error(err);
		        return true;
	        }
		};

		this.getTokenExpirationDate = function (token) {
	        try {
		        return jwtHelper.getTokenExpirationDate(token);
	        }
	        catch (err) {
		        console.error(err);
		        return new Date();
	        }
		};

		this.logout = function(config) {
	        $location.path(config.return_to);
		};

	});
