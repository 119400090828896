angular.module('app')
	.controller('TaskController', function ($scope, $timeout, TaskModel, $rootScope, $location , Exception, $q, uiGridConstants, qm, Constants, $controller) {
		angular.extend(this, $controller('BaseCtrl', {$scope: $scope}));

		$rootScope.bodyClass = "task";
		$scope.qm = qm;
		$scope.model = new TaskModel();
		$scope.grid = {};
		$scope.grid.appScope = $scope;
		$scope.activeTab = 1;

		$scope.setGridOptions = function() {
			$scope.gridOptions = {
	                enableGridMenu: true,
	                gridMenuShowHideColumns: true,
	                exporterMenuCsv: true,
	                exporterMenuAllData: false,
	                exporterMenuVisibleData: true,
	                exporterCsvFilename: 'Quartermaster-Tasks.csv',
	                exporterMenuExcel: false,
	                exporterMenuPdf: false,
				showColumnFooter: false,
				enableSorting: true,
				enableFiltering: true,
				enableColumnResizing: true,
				enablePaginationControls: false,
				enableColumnMenus: false,
				// paginationPageSizes: [3, 5, 10, 25, 50, 75, 100],
				// paginationPageSize: 5,
				columnDefs: $scope.model.getGridColumns(),
				data: 'model.items',
				tabs: [],
	                exporterSuppressColumns: ['Id', 'Actions'],
	                importerShowMenu: false,
	                importerDataAddCallback: function (grid, newObjects) {},
	                onRegisterApi: function( gridApi ) {
					$scope.gridApi = gridApi;
				}
			};
		};
		$scope.setGridOptions();

		$scope.$on('screen-changed', function(event, args) {
			$scope.gridOptions.columnDefs = $scope.getGridColumns();
			$scope.gridApi.core.refresh();
		});

		$scope.model.taskUpdated = function(item) {
			console.log('taskUpdated ' + item.name);

			if (item._saveTaskTimeout)
				$timeout.cancel(item._saveTaskTimeout);

			item._saveTaskTimeout = $timeout(function() {
				console.log('saving... ');
				$scope.model.saveItem(item);
			}, 1000); // delay 1000 ms
		};

		$scope.load = function() {
	            var deferred = $q.defer();
			if (qm.selectedVessel.Id) {
				$scope.model.load(qm.selectedVessel.Id).then(function(result) {
	                    console.log('Loaded task items:' + $scope.model.items.length);

					// $scope.$watchCollection('model.items', function(newNames, oldNames) {
					//     console.log('watchCollection:' + newNames.length);
					// });
					// result.forEach(function(item) {
					//     $scope.$watch(item, function(newValue, oldValue) {
					//         console.log('watch:' + newValue);
					//     });
					// });
	                    deferred.resolve(result);
				}, function (error) {
	                    Exception.errorFn(error);
				});
			}
			else {
	                deferred.resolve(null);
			}
	            return deferred.promise;
		};

		$scope.$watch('qm.selectedVessel', function () {
			if (qm.selectedVessel) {
				$scope.load();
			}
		});

		$scope.getServiceUrl = function(row) {
			return '/tasks/'+row.entity.Id+'/services';
		};

		$scope.getScheduleUrl = function(row) {
			return '/tasks/'+row.entity.Id+'/schedules';
		};

		$scope.getFilesUrl = function(row) {
			return '/tasks/'+row.entity.Id+'/files/'+Constants.FileComponent_Task;
		};

		$scope.getSummaryUrl = function(row) {
			return '/systems/'+row.entity.Task_System+'/summary';
		};

		//Kanban
		$scope.initKanbanBoard = function() {
			// var nextIteration = { groups: [], items: [] };

			$scope.states = $scope.model.states;
			$scope.groups = $scope.model.groups;
			// $scope.items = items;
			// $scope.items = $scope.model.items;
			$scope.assignableResources = $scope.model.crew;    // assignableResources;
			// Initialize a newly created item before adding it to the user interface.
			$scope.initializeNewItem = function (item) {
				item.assignedResource = null;
				console.log('A new item was created.');
				item.Description = item.name;
				if (item.group.crew_id) {
					item.Task_VesselUserRoles = item.group.crew_id;
				}
				$scope.model.saveItem(item).then(function (savedItem) {
	                    //We have our new saved Item as a Task object, swap basic object from Kanban with our proper Task object.
	                    var list = $scope.model.items;
	                    for (var i = 0; i < list.length; i++) {
		                    if (list[i] == item) {
			                    //Update list item
			                    list[i] = savedItem;
			                    //Shortcut a return
			                    break;
		                    }
	                    }
				});
			};
			// Allow item deletion by clicking a button in the user interface.
			$scope.deleteItem = function (type, index) {
				if (type === 'item') {
					var item = $scope.model.items[index];
					console.log('Deleting ' + item.name);
					$scope.model.items.splice(index, 1);
					qm.deleteItem($scope.model, $scope.model.items, item);
					console.log('Item ' + item.name + ' was deleted.');
				}
			};
			// Allow item edit by clicking a button in the user interface.
			$scope.editItem = function (item) {
				// $scope.model.items.splice($scope.model.items.indexOf(item), 1);
				qm.openItem($scope.model, 'Edit ' + item.Description, $scope.model.items, item, $scope.load);
				console.log('Item ' + item.name + ' was edited.');
			};
			// Handle changes.
			$scope.onItemStateChanged = function (item, state) {
				console.log('State of ' + item.name + ' was changed to: ' + state.name);
				item.State = state.state;
				$scope.model.saveItem(item);
			};
			$scope.onItemGroupChanged = function (item, group) {
				console.log('Group of ' + item.name + ' was changed to: ' + group.name);
				if (item.group.crew_id) {
					item.Task_VesselUserRoles = item.group.crew_id;
				}
				else {
					item.Task_VesselUserRoles = null;
				}
				$scope.model.taskUpdated(item);
				// $scope.model.saveItem(item);
			};

			$scope.onItem = function (item, group) {
				console.log('Group of ' + item.name + ' was changed to: ' + group.name);
			};


			// Move items to the next iteration.
			// $scope.nextIteration = nextIteration;
			// $scope.moveItemToNextIteration = function (type, index) {
			//     if (type === DlhSoft.Controls.KanbanBoard.types.group) {
			//         // Move an entire group (story) and all its items.
			//         var group = groups[index];
			//         for (var i = 0; i < items.length; i++) {
			//             var item = items[i];
			//             if (item.group === group) {
			//                 items.splice(i--, 1);
			//                 nextIteration.items.push(item);
			//             }
			//         }
			//         groups.splice(index, 1);
			//         if (nextIteration.groups.indexOf(group) < 0)
			//             nextIteration.groups.push(group);
			//         console.log('Group ' + group.name + ' and its items were moved to next iteration.');
			//     }
			//     else {
			//         // Move a single item, and copy the group (story) if needed.
			//         var item = items[index];
			//         items.splice(index, 1);
			//         nextIteration.items.push(item);
			//         var group = item.group;
			//         if (nextIteration.groups.indexOf(group) < 0)
			//             nextIteration.groups.push(group);
			//         console.log('Item ' + item.name + ' was moved to next iteration.');
			//     }
			//     // $scope.$apply();
			// };
		};
		$scope.initKanbanBoard();

		$scope.listenForChanges('Tasks');

	});
