/**
 * Created by petter on 3/24/16.
 */
'use strict';
angular.module('app')
	.directive('ngReallyClick', function($window, $uibModal) {
		var linkFunction = function (scope, element, attrs) {
			element.bind('click', function (event) {
				var ignoreEvent = attrs.hasOwnProperty('ngReallyIgnoreEvent');
				if (event && ignoreEvent) {
					event.preventDefault();
					event.stopPropagation();
				}
				var message = attrs.ngReallyMessage;
				var title = attrs.ngReallyTitle;
				var textYes = attrs.ngReallyYes;
				var textNo = attrs.ngReallyNo;

				//if (message && $window.confirm(message)) {
				//    scope.$apply(attrs.ngReallyClick);
				//}

				var modalInstance = $uibModal.open({
					templateUrl: 'core/really_click_modal.html',
					controller: 'ReallyClickController',
					//windowClass: 'modal-files',
					//size: 'fit',
					//size: 'md',
					resolve: {
						title: function () {
							return title;
						},
						message: function () {
							return message;
						},
						textYes: function () {
							return textYes;
						},
						textNo: function () {
							return textNo;
						}
					}
				});

				//var _scope = scope;
				modalInstance.result.then(function () {
					//Confirmed
					//scope.$parent.model.deleteUpload(scope.file);
					setTimeout(function () {
						//$scope.message = "Timeout called!";
						scope.$apply(attrs.ngReallyClick);
						// AngularJS unaware of update to $scope
					}, 1);

				});
			});
		};
		return {
			restrict: 'A',
			link: linkFunction
		};
	});
