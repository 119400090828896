/**
 * Created by petter on 3/09/18.
 */
'use strict';

angular.module('app')
	.factory('SystemExpenseModel', function ($q, Model, SystemExpense, common) {
		function SystemExpenseModel() {
			Model.call(this, SystemExpense);
		}

		SystemExpenseModel.prototype = Object.create(Model.prototype);
		SystemExpenseModel.prototype.constructor = SystemExpenseModel;

		SystemExpenseModel.prototype.load = function(vessel_id, params) {
	        if (vessel_id) {
		        this.vessel_id = vessel_id;
		        if (!params) {
			        params = {vessel_id: vessel_id};
		        }
		        else {
			        params.vessel_id = vessel_id;
		        }
	        }

			return Model.prototype.load.call(this, params);
		};

	    SystemExpenseModel.prototype.loadReport = function(vessel_id, from_date, to_date) {
		    return this.load(vessel_id,{
			    vessel_id:vessel_id,
			    from_date:from_date,
			    to_date:to_date
		    });
	    };

	    SystemExpenseModel.prototype.saveItem = function(data) {
			if (this.system) {
				data.SystemService_System = this.system.Id;
			}
			return Model.prototype.saveItem.call(this, data);
		};

		SystemExpenseModel.prototype.onLoadedItem = function(item) {
			item.id = item.Id;
			console.log('Loaded ' + item.Description);
		};

		SystemExpenseModel.prototype.isValidItem = function(item) {
			this.isItemDirty = true;
			item.isItemDirty = true;
			if (!item.Description || !item.ServiceDate){
				return false;
			}
			return true;
		};

	    SystemExpenseModel.prototype.getGridColumns = function() {
		    return [
			    common.getDefaultIdColumn(),
			    { name:'Date', field: 'ServiceDate', type: 'date', maxWidth: 70, cellFilter: common.dateCellFilter, enableHiding:false, sort: { priority: 0, direction: 'desc' }, filter: { placeholder: 'search...'} },
			    { name:'Expenses', field: 'Expenses', width: '120', filter: { placeholder: 'search expenses...'} },
			    { name:'Description', field: 'Description', enableHiding:false, filter: { placeholder: 'search description...'} },
			    { name:'Notes', field: 'Notes', visible: !common.phoneplus, enableHiding:false, filter: { placeholder: 'search notes...'} },
			    common.getSystemModified(),common.getSystemModifiedBy(),
			    { name:'Actions', enableHiding:false ,enableSorting: false, enableFiltering: false, enableColumnMenu: false, width: '70',
				    cellTemplate: 'components/systemexpenses/systemexpenses_action_template.html'
			    }
		    ];
	    };

		return SystemExpenseModel;
	});

angular.module('app')
	.factory('SystemExpense', function(ItemBase, Configuration) {
		function SystemExpense() {
			ItemBase.call(this);
			this.ServiceDate = new Date();
		}

		SystemExpense.prototype = Object.create(ItemBase.prototype);

		SystemExpense.dataColumns = ['Description', 'Notes', 'ServiceDate', 'SystemService_System', 'Expenses'];
		SystemExpense.templateUrl = 'components/systemexpenses/systemexpenses_edit.html';
		SystemExpense.url = Configuration.API + '/expenses/:id';

		return SystemExpense;
	});

