/**
 * Created by petter on 7/13/16.
 */
'use strict';

angular.module('app')
	.factory('StripeCouponsModel', function ($q, Model, StripeCoupons) {
		function StripeCouponsModel() {
			Model.call(this, StripeCoupons);
		}

		StripeCouponsModel.prototype = Object.create(Model.prototype);
		StripeCouponsModel.prototype.constructor = StripeCouponsModel;

		StripeCouponsModel.prototype.load = function(params) {
			return Model.prototype.load.call(this, params);
		};

		return StripeCouponsModel;
	});

angular.module('app')
	.factory('StripeCoupons', function(ItemBase, Configuration) {
		function StripeCoupons() {
			ItemBase.call(this);
		}

		StripeCoupons.prototype = Object.create(ItemBase.prototype);

		StripeCoupons.dataColumns = [];
		StripeCoupons.url = Configuration.API + '/stripe_coupons/:id';

		return StripeCoupons;
	});

