/**
 * Created by petter on 6/23/16.
 */
angular.module('app')
	.service('Configuration', function () {
		// console.log('created configuration');
		this.API = window.location.origin + '/webapi/v3';
		this.PUBLIC_API =  window.location.origin + '/public';
		this.HomePath = '/';
		this.LoggedInPath = '/dashboard';
		this.PrimaryDomin = 'quartermaster.me';
		this.TeamEmailAddress = 'team@quartermaster.me';
		this.TestEmailAddress = 'test@quartermaster.me';
		this.AdminEmailAddress = 'petterbergman@me.com';
		this.AdminEmailAddressList = '';
		this.TrialDays = 30;
	});
