/**
 * Created by petter on 2/18/16.
 */
'use strict';

angular.module('app')
	.factory('Model', function ($resource, $q, Exception, common) {

		function Model(modelClass, url) {
			this.modelClass = modelClass;
			if (!url) {
				url = modelClass.url;
			}
			this.resource = $resource(url);
			// this.resource = $resource(url, {}, {
			// 	query: {
			// 		method: 'GET',
			//         params: {},
			//         isArray: true,
			//         cache: false,
			//         timeout: 10,
			//         withCredentials: true
			//     }
			// });

			this.dateFromResponse = function(dateString) {
				if (dateString) {
					if (dateString.length > 10) {
						return new Date(dateString);
						// return new Date(moment.utc(new Date(dateString)).format('L'));
					}
					else {
						// return new Date(dayjs(new Date(dateString)).format('L'));
						return dateString;
					}
				}
				return '';
			};

			this.dateForRequest = function(date) {
				if (date) {
					// return moment(new Date(date)).format('YYYY-MM-DD');
					return date;
				}
				return null;
			};

			this.Id = 0;
			this.items = [];
			this.isLoaded = false;
			this.itemIndex = 0;
			this.progressValue = 0;
			this.isItemDirty = false;
			// if (!this.modelClass.dataColumns) {
			//     throw new Error(this.modelClass.name +  ' model class missing static property dataColumns');
			// }
			//if (!this.modelClass.isValidItem) {
			//    throw new Error(this.modelClass.name +  ' model class missing member method isValidItem');
			//}
			if (!this.resource) {
				throw new Error(this.modelClass.name +  ' missing static property url or a url must be passed in the Model constructor');
			}

			this.refreshModelProgress = function() {
				// if (this.getProgress) {
				//     this.progressValue = this.getProgress();
				//     if (this.progressModel) {
				//         //console.log(this.constructor.name + ' model calling refreshTotalProgress()');
				//         this.progressModel.refreshTotalProgress();
				//     }
				// }
			};

			this.isDateColumnName = function(name) {
				return name.match('.+Date$') || name.match('^Modified$') || name.match('^Created$');
			};
		}

		Object.defineProperty(Model.prototype, 'class', {
			get: function () {
				return this.modelClass;
			}
		});

		Object.defineProperty(Model.prototype, 'templateUrl', {
			get: function () {
				return this.modelClass.templateUrl;
			}
		});

		Model.prototype.isValidItem = function(item) {
			return this.modelClass.isValidItem(item);
		};

		Model.prototype.newItem = function(container) {
			var item = new this.modelClass();
			item.index = this.itemIndex++;
			item.model = this;

			if (item.onNewItem) {
				item.onNewItem(container);
			}
			// if (container.onItemChanged) {
			//     item.notifyItemChanged = container.onItemChanged;
			// }
			return item;
		};

		Model.prototype.load = function(params) {
			console.debug('getting list of ' + this.constructor.name);
			var deferred = $q.defer();
			if (this.isLoading) {
				//TODO: Temporary fix for the fact that component is somehow calling load multiple times on the same model
				var alreadyLoadingError = new Error('Already loading list');
				console.warn(alreadyLoadingError.message);
				// Exception.logError(alreadyLoadingError, deferred);
				return deferred.promise;
			}
			this.isLoading = true;
			console.log('loading ' + this.constructor.name);
			common.showWorking();
			var _this = this;
			if (this.modelClass && this.modelClass.onBeforeLoad) {
				this.modelClass.onBeforeLoad();
			}
			if (this.modelClass && this.onBeforeLoad) {
				this.onBeforeLoad();
			}
			if (!params) {
				params = {};
			}
			var query = this.resource.query(params, function(data) {
				try {
					if (data) {
						_this.items.length = 0;
						_this.itemIndex = 0;
						for (var i = 0; i < data.length; i++) {
							var dataItem = data[i];
							var item = _this.newItem(_this);

							item = angular.extend(item, dataItem);

							//Iterate through all defined columns to check for dates that need converting
							if (_this.modelClass.dataColumns) {
								for (var j = 0; j < _this.modelClass.dataColumns.length; j++) {
									var name = _this.modelClass.dataColumns[j];
									if (_this.isDateColumnName(name)) {
										item[name] = _this.dateFromResponse(dataItem[name]);
									}
								}
							}
							for (var property in dataItem) {
								if (dataItem.hasOwnProperty(property)) {
									if (_this.isDateColumnName(property)) {
										item[property] = _this.dateFromResponse(item[property]);
									}
								}
							}

							item.isItemDirty = false;
							if (item.Ver != 0) {    //TODO: A cheap way to not display deleted items, but we should probably filter on the server end
								_this.items.push(item);
								if (item.onLoadedItem) {
									item.onLoadedItem();
								}
								if (_this.onLoadedItem) {
									_this.onLoadedItem(item);
								}
							}
						}
					}
					console.log('loaded ' + _this.items.length + ' items in ' + _this.constructor.name);
					_this.refreshModelProgress();
					_this.isLoaded = true;
					_this.isLoading = false;
					common.hideWorking();
					deferred.resolve(_this.items);
				}
				catch (err) {
					Exception.handleError(err, deferred, 'Failed to run resource query');
					_this.isLoading = false;
				}
			}, function(err) {
				Exception.handleError(err, deferred);
				_this.isLoading = false;
			});
			query.$promise
				.then(function(success) {
					// console.log("success!");
				})
				.catch(function(err) {
					// throw new Error('Unexpected server error:' + err.message));
					console.error(err);
					Exception.logError(new Error('Unexpected server error:' + err.message));
					_this.isLoading = false;
				});
			return deferred.promise;
		};

		Model.prototype.loadCount = function(params) {
			var deferred = $q.defer();
			console.log('loading count ' + this.constructor.name);
			params.getcount = true;
			this.resource.query(params, function(data) {
				try {
					deferred.resolve(data);
				}
				catch (err) {
					Exception.handleError(err, deferred, 'Failed to run resource query for count');
				}
			}, function(err) {
				Exception.handleError(err, deferred, 'Failed to load data');
			});
			return deferred.promise;
		};

		Model.prototype.getItem = function(Id) {
			console.debug('getting item ' + this.constructor.name);
			common.showWorking();
			var deferred = $q.defer();
			var _this = this;
			this.resource.get({id:Id}, function(dataItem) {
				if (dataItem) {
					var item = _this.newItem(_this);

					item = angular.extend(item, dataItem);

					//Iterate through all defined columns to check for dates that need converting
					if (_this.modelClass.dataColumns) {
						for (var j=0; j<_this.modelClass.dataColumns.length; j++) {
							var name = _this.modelClass.dataColumns[j];
							if (_this.isDateColumnName(name)) {
								item[name] = _this.dateFromResponse(dataItem[name]);
							}
						}
					}

					item.isItemDirty = false;
					_this.items.push(item);
					if (item.onLoadedItem) {
						item.onLoadedItem();
					}
					if (_this.onLoadedItem) {
						_this.onLoadedItem(item);
					}
					console.log('got item ' + _this.constructor.name);
					_this.refreshModelProgress();
					common.hideWorking();
					deferred.resolve(item);
				}
				else {
					throw new Error(_this.constructor.name + ' does not exist with Id ' + Id);
				}
			}, function(err) {
				Exception.handleError(err, deferred, 'Failed to get item');
			});
			return deferred.promise;
		};

		Model.prototype.refreshItem = function(responseData) {
			console.log('refreshing item ' + this.constructor.name);
			common.showWorking();
			var deferred = $q.defer();
			var _this = this;
			var Id = responseData.Id;
			this.isLoading = true;
			this.resource.get({id:Id}, function(dataItem) {
				if (dataItem) {
					var item = _this.newItem(_this);

					item = angular.extend(item, dataItem);

					//Iterate through all defined columns to check for dates that need converting
					if (_this.modelClass.dataColumns) {
						for (var j=0; j<_this.modelClass.dataColumns.length; j++) {
							var name = _this.modelClass.dataColumns[j];
							if (_this.isDateColumnName(name)) {
								item[name] = _this.dateFromResponse(dataItem[name]);
							}
						}
					}

					item.isItemDirty = false;
					if (item.onLoadedItem) {
						item.onLoadedItem();
					}
					if (_this.onLoadedItem) {
						_this.onLoadedItem(item);
					}
					_this.isLoading = false;
					console.log('refreshed item ' + _this.constructor.name);
					_this.refreshModelProgress();
					common.hideWorking();
					deferred.resolve(item);
				}
				else {
					throw new Error(_this.constructor.name + ' does not exist with Id ' + Id);
				}
			}, function(err) {
				Exception.handleError(err, deferred, 'Failed to refresh item');
			});
			return deferred.promise;
		};

		Model.prototype.saveItem = function(data) {
			console.log('saving item ' + this.constructor.name);
			common.showWorking();
			var deferred = $q.defer();
			var requestData = {
				Id: data.Id
			};
			if (this.modelClass) {
				for (var i = 0; i < this.modelClass.dataColumns.length; i++) {
					var name = this.modelClass.dataColumns[i];
					var value = data[name];
					if (this.isDateColumnName(name)) {
						requestData[name] = this.dateForRequest(value);
					}
					else {
						requestData[name] = value;
					}
				}
			}
			else {
				requestData = data;
			}
			var _this = this;
			this.resource.save(requestData, function(responseData) {
				console.log("Saved item: " + responseData.Id);
				//Refresh the single item saved (.refreshItem will create an ItemBase object with the data) as opposed to refreshing all items
				_this.refreshItem(responseData).then(function(savedItem) {
					if (savedItem) {
						common.hideWorking();
						deferred.resolve(savedItem);
					}
					else {
						common.hideWorking();
						deferred.resolve(responseData);
					}
				}, function(err) {
					Exception.handleError(err, deferred, 'Failed to refresh saved data');
				});
			}, function(err) {
				Exception.handleError(err, deferred, 'Failed to save data');
			});
			return deferred.promise;
		};

		Model.prototype.deleteItem = function(item) {
			console.log('deleting item ' + this.constructor.name);
			common.showWorking();
			var deferred = $q.defer();
			var _this = this;
			var data = {
				id:item.Id
			};
			this.resource.delete(data, function(responseData) {
				console.log("Deleted item:" + data.Id);
				_this.refreshModelProgress();
				common.hideWorking();
				deferred.resolve(responseData);
			}, function(err) {
				Exception.handleError(err, deferred, 'Failed to delete item');
			});
			return deferred.promise;
		};

		return Model;
	});

