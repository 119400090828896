angular.module('app')
	.controller( 'LoginCtrl', function ( $scope, authService, $location, qm, common, Constants, $rootScope, $timeout, localStorageService, Model, Configuration, $uibModal) {
		$scope.qm = qm;
		$scope.auth = authService;
		$scope.Constants = Constants;
		$scope.Environment = ENVIRONMENT;
		// $scope.isNavCollapsed = true;
		// $scope.accountStorageModel = new Model(function AccountStorageModel() {}, Configuration.API + '/account_storage/:id');
	    $scope.accountSubscription = new Model(function AccountSubscriptionModel() {}, Configuration.API + '/account_subscription/:id');
		$scope.showingSubscribeModal = false;
		$scope.version = VERSION;

	    $scope.navigateTo = function(path) {
        	// console.log('old path = ' + $location.path());
        	if ($location.path() !== path) {
        		//Only show spinner, if new path is different (re-clicking same navigation won't do anything)
		        common.showWorking();
        	}
        	else {
        		return;
	        }
			$timeout(function() {
				$rootScope.$apply(function() {
					$scope.isNavCollapsed = true;
				});
			}, 1);
			$timeout(function() {
				if (path) {
					console.log('navigateTo:'+path);
					$location.path(path);
					var hash = path;
					if (hash) {
						var components = hash.split('/');
						hash = '';
						for (var i=0; i<components.length; i++) {
							//Only add non-number components
							if (isNaN(components[i])) {
								hash += '/' + components[i];
							}
						}
						// common.trackEvent('Interaction', 'navigateTo', hash);
					}
				}
			}, 2);
		};

		//Make sure $rootScope has this method too, so it can be used from other places
		$rootScope.navigateTo = $scope.navigateTo;

		$scope.navigateToBookmark = function(hash) {
			$timeout(function() {
				$rootScope.$apply(function() {
					$scope.isNavCollapsed = true;
				});
			}, 1);
			$timeout(function() {
				if (hash) {
					console.log('navigateToBookmark:'+hash);
					$location.hash(hash);
					// common.trackEvent('Interaction', 'navigateToBookmark', hash);
				}
				else {
					$location.hash('');
				}
			}, 2);
		};

		$scope.login = function() {
			$scope.isNavCollapsed = true;
			// common.trackEvent('Funnel', 'login');
			// authService.login();
			$scope.showLoginModal('login');
		};

		$scope.signup = function() {
			$scope.isNavCollapsed = true;
			common.trackEvent('Funnel', 'ClickSignup');
			// authService.signup();
			$scope.showLoginModal('signup');
		};

		$scope.logout = function() {
			$scope.isNavCollapsed = true;
			// common.trackEvent('Funnel', 'logout');
			authService.logout();
			$scope.navigateTo('/');
		};

	    $scope.resetpassword = function(token) {
		    $scope.isNavCollapsed = true;
		    // common.trackEvent('Funnel', 'resetpassword');
		    // authService.signup();
		    // $scope.resetpasswordtoken = token;
		    $scope.showLoginModal('resetpassword', token);
	    };

	    $scope.showLoginModal = function (mode, resetpasswordtoken) {
		    $scope.showingLoginModal = false;
		    if (!$scope.showingLoginModal) {
			    $scope.showingLoginModal = true;
			    // common.trackEvent('Funnel','ShowLoginModal');
			    var modalInstance = $uibModal.open({
				    templateUrl: 'components/login/modal_login.html',
				    controller: 'ModalLoginController',
				    size: 'sm',
				    resolve: {
					    title: function () {
						    return 'Login or Signup';
					    },
					    mode: function () {
						    return mode;
					    },
					    resetpasswordtoken: function () {
						    return resetpasswordtoken;
					    }
				    }
			    });

			    modalInstance.result.then(function (result) {
				    // $scope.navigateTo('/account');
				    $scope.showingLoginModal = false;
				    // common.trackEvent('Funnel','Login');
			    }, function () {
				    console.log('Login Modal dismissed at: ' + new Date());
				    $scope.showingLoginModal = false;
				    // common.trackEvent('Funnel','DismissLoginModal');
			    });
		    }
	    };

		$scope.showFeedback = function() {
			$scope.isNavCollapsed = true;
			try {
				throw new Error('User feedback');
			}
			catch (err) {
	            Sentry.captureException(err);
	            Sentry.showReportDialog();
			}
		};

		$scope.$watch('qm.selectedVessel', function () {
			if (qm.selectedVessel && $rootScope.isAuthenticated) {
				$scope.checkAccount();
			}
		});

	    $scope.$on('$routeChangeSuccess', function(e, nextRoute){
			if (nextRoute) {
				if (nextRoute.$$route && (nextRoute.$$route.pageTitle != 'Account')) {
					if (qm.selectedVessel && $rootScope.isAuthenticated) {
						$scope.checkAccount();
					}
				}
			}
		});

	    $scope.$on('userProfileSet', function(newVal, oldVal){
	    	// $scope.$apply();
		    if ($rootScope.isAuthenticated) {
			    // $scope.checkAccount();
			    if ($location.$$path === Configuration.HomePath) {
			    	console.log('User on home path, after logged in.  Taking user to dashboard');
				    $location.path(Configuration.LoggedInPath);
			    }
		    }
	    });

	    $scope.accountSubscription.isValidItem = function(item) {
			return true;
		};

		$scope.checkAccount = function() {
			$scope.accountSubscription.getItem(qm.selectedVessel.Id).then(function(accountStorage) {
				var needToUpdateAccount = true;
				if (accountStorage.subscription) {
					if (accountStorage.subscription.status === 'active') {
						needToUpdateAccount = false;
					}
				}
				if (accountStorage.account) {
					var today = new Date();
					var created = new Date(accountStorage.account.Created);
					var timeDiff = today.getTime() - created.getTime();
					var daysSinceStart = Math.ceil(timeDiff / (1000 * 3600 * 24));
					if (daysSinceStart < 31) {
						needToUpdateAccount = false;
					}
				}
				if (needToUpdateAccount && !$location.$$path.endsWith('/account')) {
					$scope.showSubscribeModal(accountStorage);
				}
				if (accountStorage.iap) {
	                var subscriptionText = 'Unknown plan';
	                var isMonthly = false;
	                if (accountStorage.iap.productId.indexOf('_monthly') > 0) {
		                isMonthly = true;
	                }
	                if (accountStorage.iap.productId.indexOf('owner_') > 0) {
		                subscriptionText = 'Owner';
	                }
	                else if (accountStorage.iap.productId.indexOf('captain_') > 0) {
		                subscriptionText = 'Captain';
	                }
	                else if (accountStorage.iap.productId.indexOf('fleet_') > 0) {
		                subscriptionText = 'Fleet';
	                }
	                if (isMonthly) {
		                $scope.subscriptionText = subscriptionText + ' (monthly)';
	                }
	                else {
		                $scope.subscriptionText = subscriptionText + ' (yearly)';
	                }
				}
			}, function (error) {
				// Exception.errorFn(error);
			});
		};

		$scope.showSubscribeModal = function (accountStorage) {
			if (!$scope.showingSubscribeModal) {
				$scope.showingSubscribeModal = true;
				common.trackEvent('Funnel','ShowSubscribeModal', accountStorage.daysSinceStart);
				var modalInstance = $uibModal.open({
					templateUrl: 'components/login/modal_subscribe.html',
					controller: 'ModalController',
					// windowClass: 'vessel-photo-modal',
					size: 'crewphoto',
					resolve: {
						title: function () {
							return 'Your Free Trial has ended';
						},
						modalObject: function () {
							return {accountStorage:accountStorage};
						},
						model: function () {
							return $scope.accountSubscription;
						}
					}
				});

				modalInstance.result.then(function (result) {
					$scope.navigateTo('/account');
					$scope.showingSubscribeModal = false;
					common.trackEvent('Funnel','AcceptSubscribeModal', accountStorage.daysSinceStart);
				}, function () {
					console.log('Modal dismissed at: ' + new Date());
					$scope.showingSubscribeModal = false;
					common.trackEvent('Funnel','DismissSubscribeModal', accountStorage.daysSinceStart);
				});
			}
		};


		if (authService.isAuthenticated) {
			try {
				$scope.qm.loadVessels();
			}
			catch (err) {
				console.error(err);
			}
		}
	});
