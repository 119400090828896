angular.module('app')
	.controller('FleetController', function ($scope, FleetModel, FleetServiceScheduleModel, FleetTaskModel, $rootScope, $location , Exception, $q, uiGridConstants, qm, profilePhotoModel, $uibModal, Constants, $interval) {
		$rootScope.bodyClass = "vessels";
		$scope.qm = qm;
		$scope.model = new FleetModel();
		$scope.fleetServiceScheduleModel = new FleetServiceScheduleModel();
		$scope.fleetTaskModel = new FleetTaskModel();
		$scope.grid = {};
		$scope.grid.appScope = $scope;
		$scope.profilePhotoModel = profilePhotoModel;

		$scope.load = function() {
			var deferred = $q.defer();
			try {
				$scope.model.load().then(function (result) {
					try {
						console.log('Loaded vessel items:' + result.length);
						var vessel_id_list = '';
						for (var i = 0; i < $scope.model.items.length; i++) {
							var vessel = $scope.model.items[i];
							if (i === 0) {
								vessel_id_list = vessel.id;
							}
							else {
								vessel_id_list += ',' + vessel.id;
							}
						}

						//Service Schedule
						$scope.fleetServiceScheduleModel.load(vessel_id_list).then(function (serviceschedules) {
							try {
								console.log('Loaded vessel schedules:' + serviceschedules.length);
								for (var i = 0; i < $scope.model.items.length; i++) {
									var vessel = $scope.model.items[i];
									vessel.serviceschedules = [];
									for (var j = 0; j < serviceschedules.length; j++) {
										var serviceschedule = serviceschedules[j];
										if (serviceschedule.SystemSchedule_Vessel === vessel.id) {
											vessel.serviceschedules.push(serviceschedule);
										}
									}
								}
							}
							catch (error) {
								Exception.handleError(error, deferred);
							}
						}, function (error) {
							Exception.handleError(error, deferred);
						});

						//Tasks
						$scope.fleetTaskModel.load(vessel_id_list).then(function (tasks) {
							try {
								console.log('Loaded vessel tasks:' + tasks.length);
								for (var i = 0; i < $scope.model.items.length; i++) {
									var vessel = $scope.model.items[i];
									vessel.tasks = [];
									for (var j = 0; j < tasks.length; j++) {
										var task = tasks[j];
										if (task.Task_Vessel === vessel.id) {
											vessel.tasks.push(task);
										}
									}
								}
							}
							catch (error) {
								Exception.handleError(error, deferred);
							}
						}, function (error) {
							Exception.handleError(error, deferred);
						});

						deferred.resolve(result);
					}
					catch (error) {
						Exception.handleError(error, deferred);
					}
				}, function (error) {
					Exception.handleError(error, deferred);
					deferred.resolve(null);
				});
			}
			catch (error) {
				Exception.handleError(error, deferred);
			}
			return deferred.promise;
		};

		$scope.getScheduleUrl = function(vessel) {
			return '/vessels/'+vessel.Id+'/schedules';
		};

		$scope.load();

	});
